import styled from "styled-components";

export const Text = styled.p`
  font-family: "Inter";
  font-style: normal;
  width: max-content;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  padding: 0;
  color: #344054;
  outline: none;
  border: none;
`;
