import React from "react";

type Props = {
  selected?: boolean;
};

export const ShortList = (props: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="28"
      height="28"
      fill="none"
      viewBox="0 0 28 28"
    >
      <circle
        cx="14"
        cy="14"
        r="14"
        fill={props.selected ? "#FF0062" : "#E7EAEE"}
      ></circle>
      <path
        stroke={props.selected ? "#fff" : "#A0ABBB"}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M14.517 21.342c-.284.1-.75.1-1.034 0-2.417-.825-7.817-4.267-7.817-10.1 0-2.575 2.075-4.659 4.634-4.659 1.516 0 2.858.734 3.7 1.867a4.608 4.608 0 013.7-1.866c2.558 0 4.633 2.083 4.633 4.658 0 5.833-5.4 9.275-7.816 10.1z"
      ></path>
    </svg>
  );
};
