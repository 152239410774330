import axios from "axios";

//  export const config = {
//   url: {
//    API_URL: `${process.env.REACT_APP_API_ENDPOINT}`,
//    BASE_URL: process.env.REACT_APP_BASEURL
//  }};p

var authToken = localStorage.getItem("authToken");

const axiosOptions: any = {
  // withCredentials: false,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: {
      toString() {
        // return `Bearer `;
        return `Bearer ${authToken}`;
      },
    },
  },
};

export const ApiV1: any = axios.create({
  baseURL: `${process.env.REACT_APP_API_ENDPOINT}`,
  ...axiosOptions,
});

// export const SIGN_IN = `${config.url.API_URL}/hostLogin`
export const STUDENTS = `/students`;
export const LOGIN = `/auth/login`;
export const COURSE_LEVEL = `/courseLevels`;
export const DOCUMENTS = `/documents`;
export const APPLICATIONS = `/application`;
export const COURSES = `/courses`;
export const COURSES_DETAIL = `/courseDetails`;
export const SPONSOR_STUDENTS = `/sponsorStudents`;
export const NEWS = `/news`;
export const COMMENTS = `/comments`;
export const USERS = `/users`;
export const LEADS = `/leads`;
export const APPLIED_STUDENT = `/appliedStudent`;
export const UNIVERSITY_DETAIL = `/universityDetails`;
export const FORGOT_PASSWORD = `/auth/forgot-password`;
export const RESET_PASSWORD = `/auth/reset-password`;
