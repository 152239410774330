import * as React from "react";

interface IThirdBadgeProps {
  color: string;
  style: object;
}

export const ThirdBadge: React.FunctionComponent<IThirdBadgeProps> = (
  props
) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      fill="none"
      viewBox="0 0 30 30"
    >
      <path
        fill="#176CC7"
        d="M16.195.994c-.254.225-2.222 4.132-2.222 4.132l-1.465 5.909 5.7-.523s4.741-8.817 4.837-9.021c.171-.37.263-.523-.391-.523C22 .966 16.43.783 16.194.994z"
      ></path>
      <path
        fill="#F79429"
        d="M19.145 10.146c-.284-.153-8.637-.284-8.834 0-.178.257-.078 1.61-.01 1.771.122.282 2.82 1.507 2.82 1.507l-.052.558s.22.057 1.789.057c1.568 0 1.877-.08 1.877-.08l.005-.504s2.428-1.181 2.55-1.345c.103-.136.138-1.812-.145-1.964zm-2.487 2.137s.077-.345-.085-.424c-.161-.082-2.936-.045-3.157-.033-.22.012-.22.405-.22.405l-1.748-.9-.033-.335 6.633.047-.024.317-1.366.923z"
      ></path>
      <path
        fill="#FDFFFF"
        d="M13.89 12.162c-.22 0-.347.23-.347.626 0 .37.127.682.405.659.23-.02.31-.37.289-.682-.021-.373-.068-.603-.347-.603z"
      ></path>
      <path
        fill="#F79429"
        d="M6.871 21.583c0 5.616 5.088 7.953 8.466 7.852 3.935-.117 8.168-3.103 7.819-8.461-.329-5.027-4.561-7.334-8.26-7.308-4.298.033-8.025 3.012-8.025 7.917z"
      ></path>
      <path
        fill="#D25116"
        d="M15.156 28.586c-.059 0-.12 0-.179-.002a7.283 7.283 0 01-4.764-2.03 6.87 6.87 0 01-2.096-5.013c.021-4.676 4.109-6.844 6.881-6.844h.024c3.757.017 6.841 2.937 6.928 6.896.038 1.762-.525 3.515-1.94 4.907-1.322 1.294-3.235 2.086-4.854 2.086zm-.162-13.11c-2.487 0-6.164 2.034-6.174 6.065-.007 3.014 2.328 6.113 6.216 6.244 1.481.047 3.152-.502 4.383-1.711a6.104 6.104 0 001.828-4.47c-.056-3.405-2.84-6.114-6.22-6.129-.007-.004-.026 0-.033 0z"
      ></path>
      <path
        fill="#FEFFFA"
        d="M13.616 14.407c-.162-.222-1.819-.159-3.602 1.376-1.772 1.526-2.037 3.214-1.718 3.303.363.1.806-1.531 2.337-2.902 1.406-1.254 3.304-1.334 2.983-1.777zm7.123 6.134c-.565.08.02 1.772-1.29 3.666-1.136 1.643-2.425 2.238-2.275 2.599.201.483 2.266-.72 3.223-2.68.864-1.773.761-3.646.342-3.585z"
      ></path>
      <path
        fill="#2E9DF4"
        d="M5.98.872c-.148.136 5.498 10.191 5.498 10.191s.947.122 3.06.14c2.115.02 3.163-.121 3.163-.121S13.31.973 13.048.872C12.92.821 11.18.802 9.481.783 7.779.763 6.12.741 5.979.873z"
      ></path>
      <path
        fill="#D25116"
        d="M17.567 19.62c-.271-2.355-3.014-2.552-4.15-1.648-1.107.882-1.001 2.11-.225 2.253.611.112.77-.373.949-.596.403-.499 1.045-.546 1.465-.145.475.457.183 1.55-.532 1.594-.532.033-.802.005-.87.096-.096.129-.082 1.144.016 1.256.113.13.563.05.95.064.532.02 1.097.808.724 1.399-.387.611-1.416.337-1.786-.033-.49-.49-.98-.209-1.144-.017-.258.305-.532.999.42 1.674.949.675 3.83.804 4.41-1.287.507-1.826-.674-2.576-.674-2.576s.602-.69.447-2.034z"
      ></path>
    </svg>
  );
};
