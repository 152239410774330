import * as React from "react";

interface IUserIconProps {
  background: string;
}

export const UserIcon: React.FunctionComponent<IUserIconProps> = (props) => {
  return (
    <svg
      width={20}
      height={19}
      viewBox="0 0 20 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M14.6 9.4C14.006 9.4 13.438 9.518 12.92 9.734C12.256 8.972 11.426 8.36 10.486 7.954C11.29 7.222 11.8 6.17 11.8 5C11.8 2.794 10.006 1 7.8 1C5.594 1 3.8 2.794 3.8 5C3.8 6.17 4.308 7.222 5.112 7.954C2.696 8.998 1 11.404 1 14.2V15C1 15.662 1.538 16.2 2.2 16.2H10.914C11.7 17.402 13.058 18.2 14.6 18.2C17.026 18.2 19 16.226 19 13.8C19 11.374 17.026 9.4 14.6 9.4ZM4.6 5C4.6 3.236 6.036 1.8 7.8 1.8C9.564 1.8 11 3.236 11 5C11 6.764 9.564 8.2 7.8 8.2C6.036 8.2 4.6 6.764 4.6 5ZM10.502 15.4H2.2C1.978 15.4 1.8 15.22 1.8 15V14.2C1.8 11.558 3.514 9.312 5.89 8.514C6.458 8.824 7.108 9 7.8 9C8.49 9 9.142 8.824 9.71 8.514C10.668 8.836 11.518 9.394 12.192 10.118C10.994 10.906 10.2 12.262 10.2 13.8C10.2 14.364 10.308 14.904 10.502 15.4ZM14.6 17.4C12.614 17.4 11 15.784 11 13.8C11 11.814 12.614 10.2 14.6 10.2C16.584 10.2 18.2 11.814 18.2 13.8C18.2 15.784 16.584 17.4 14.6 17.4Z"
        fill={props.background}
        stroke={props.background}
        strokeWidth={0.25}
      />
      <path
        d="M14.5997 11.4004C14.3777 11.4004 14.1997 11.5784 14.1997 11.8004V14.2004C14.1997 14.4204 14.3777 14.6004 14.5997 14.6004C14.8197 14.6004 14.9997 14.4204 14.9997 14.2004V11.8004C14.9997 11.5784 14.8197 11.4004 14.5997 11.4004Z"
        fill={props.background}
        stroke={props.background}
        strokeWidth={0.25}
      />
      <path
        d="M14.5997 16.1994C14.8206 16.1994 14.9997 16.0203 14.9997 15.7994C14.9997 15.5785 14.8206 15.3994 14.5997 15.3994C14.3788 15.3994 14.1997 15.5785 14.1997 15.7994C14.1997 16.0203 14.3788 16.1994 14.5997 16.1994Z"
        fill={props.background}
        stroke={props.background}
        strokeWidth={0.25}
      />
    </svg>
  );
};
