import React, { useState } from "react";
import { Modal, Button, ListGroup, ListGroupItem } from "react-bootstrap";
// import { AiOutlineClose } from "react-icons/ai"; // Add a close icon for better visuals

type Props = {
  roles: any[];
  onClose: () => void;
  onSelectRole: (role: any) => void;
};

export const RoleRemovalModal = ({ roles, onClose, onSelectRole }: Props) => {
  const [selectedRole, setSelectedRole] = useState<any>(null);
  function capitalizeFirstLetter(string: any) {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  }
  return (
    <Modal show onHide={onClose} size="sm" centered>
      <Modal.Header closeButton>
        <Modal.Title style={{ fontSize: 18 }}>
          Select Role to Remove
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ListGroup variant="flush">
          {roles.map((role) => (
            <ListGroupItem
              key={role.id}
              action
              onClick={() => setSelectedRole(role)}
              style={{
                cursor: "pointer",
                borderRadius: "8px",
                marginBottom: "8px",
                backgroundColor:
                  selectedRole?.id === role.id ? "#f0f0f0" : "#fff",
                boxShadow:
                  selectedRole?.id === role.id
                    ? "0 4px 8px rgba(0,0,0,0.1)"
                    : "none",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <span>{capitalizeFirstLetter(role.role)}</span>
                {selectedRole?.id === role.id && (
                  <Button
                    variant="danger"
                    size="sm"
                    onClick={(e) => {
                      e.stopPropagation(); // Prevents the click from triggering the onClick of ListGroupItem
                      onSelectRole(role);
                    }}
                    style={{
                      borderRadius: "20px",
                      padding: "5px 10px",
                      fontSize: "0.8rem",
                    }}
                  >
                    {/* <AiOutlineClose style={{ marginRight: 5 }} /> */}
                    Remove
                  </Button>
                )}
              </div>
            </ListGroupItem>
          ))}
        </ListGroup>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="outline-secondary"
          onClick={onClose}
          style={{ borderRadius: "20px" }}
        >
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
