import React from "react";
import styled from "styled-components";
import { Badge, Button } from "react-bootstrap";

const NotificationWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 1rem;
  border-bottom: 1px solid #eaeaea;
  background: #fff;
`;

const Info = styled.div`
  margin-left: 1rem;
  flex-grow: 1;
  .university,
  .course {
    display: flex;
    align-items: center;
  }
`;

type NotificationProps = {
  type: string;
  applicationId: string;
  counselorName: string;
  studentName: string;
  university: string;
  course: string;
  date: string;
};

export const NotificationItem: React.FC<NotificationProps> = ({
  type,
  applicationId,
  counselorName,
  studentName,
  university,
  course,
  date,
}) => {
  const getVariant = () => {
    switch (type) {
      case "warning":
        return "danger";
      case "message":
        return "primary";
      default:
        return "secondary";
    }
  };

  return (
    <NotificationWrapper>
      <Badge bg={getVariant()}>
        {type === "warning" ? "!" : type === "message" ? "✉️" : "ℹ️"}
      </Badge>
      <Info>
        <div>{applicationId}</div>
        <div>
          {counselorName} | {studentName}
        </div>
        <div className="university">
          <span>🎓</span>
          <span>{university}</span>
        </div>
        <div className="course">
          <span>🎓</span>
          <span>{course}</span>
        </div>
        <div>{date}</div>
      </Info>
      <Button variant="light" size="sm">
        View
      </Button>
      <Button variant="light" size="sm">
        Delete
      </Button>
    </NotificationWrapper>
  );
};
