import React from "react";
import Lottie from "react-lottie";
import animationData from "../Lottie/Cat-Animation.json";

interface ICatAnimationProps {
  width?: string;
  height?: string;
}

export const CatAnimation: React.FunctionComponent<ICatAnimationProps> = (
  props
) => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <div>
      <Lottie
        options={defaultOptions}
        height={props.height ? props.height : 100}
        width={props.width ? props.width : 100}
      />
    </div>
  );
};
