import * as React from "react";

interface ISecondBadgeProps {
  color: string;
  style: object;
}

export const SecondBadge: React.FunctionComponent<ISecondBadgeProps> = (
  props
) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      fill="none"
      viewBox="0 0 30 30"
    >
      <path
        fill="#176CC7"
        d="M16.195.994c-.254.225-2.222 4.132-2.222 4.132l-1.465 5.909 5.7-.523s4.741-8.817 4.837-9.021c.171-.37.263-.523-.391-.523C22 .966 16.43.783 16.194.994z"
      ></path>
      <path
        fill="#CECDD2"
        d="M19.145 10.146c-.284-.153-8.637-.284-8.834 0-.178.257-.078 1.61-.01 1.771.122.282 2.82 1.507 2.82 1.507l-.052.558s.22.057 1.789.057c1.568 0 1.877-.08 1.877-.08l.005-.504s2.428-1.181 2.55-1.345c.103-.136.138-1.812-.145-1.964zm-2.487 2.137s.077-.345-.085-.424c-.161-.082-2.936-.045-3.157-.033-.22.012-.22.405-.22.405l-1.781-1.061v-.174l6.633.047.011.197-1.401 1.043z"
      ></path>
      <path
        fill="#FDFFFF"
        d="M13.89 11.992c-.22 0-.347.23-.347.626 0 .37.127.682.405.659.23-.02.31-.37.289-.682-.021-.37-.068-.603-.347-.603z"
      ></path>
      <path
        fill="#CECDD2"
        d="M6.79 21.537c0 5.615 5.167 7.999 8.544 7.898 3.936-.117 8.089-3.103 7.82-8.461-.256-5.076-4.562-7.334-8.26-7.308-4.299.033-8.105 2.965-8.105 7.87z"
      ></path>
      <path
        fill="#9B9B9D"
        d="M15.124 28.39c-.058 0-.12 0-.178-.003a7.283 7.283 0 01-4.765-2.03 6.87 6.87 0 01-2.095-5.013c.021-4.676 4.109-6.844 6.881-6.844h.024c3.757.016 6.841 2.937 6.928 6.895.037 1.763-.684 3.612-2.1 5.004-1.322 1.296-3.075 1.99-4.695 1.99zm-.161-13.112c-2.487 0-6.164 2.034-6.174 6.066-.007 3.014 2.328 6.112 6.216 6.243 1.481.047 3.007-.52 4.24-1.732 1.28-1.258 1.999-2.88 1.973-4.45-.06-3.406-2.845-6.115-6.225-6.132-.005 0-.023.005-.03.005z"
      ></path>
      <path
        fill="#FEFFFA"
        d="M13.616 14.407c-.162-.222-1.819-.159-3.602 1.376-1.772 1.526-2.037 3.214-1.718 3.303.363.1.806-1.531 2.337-2.902 1.406-1.254 3.304-1.334 2.983-1.777zm6.982 6.153c-.565.08.021 1.772-1.289 3.665-1.137 1.643-2.426 2.239-2.276 2.6.202.482 2.267-.72 3.223-2.68.865-1.771.762-3.644.342-3.585z"
      ></path>
      <path
        fill="#2E9DF4"
        d="M5.98.872c-.148.136 5.498 10.191 5.498 10.191s.947.122 3.06.14c2.115.02 3.163-.121 3.163-.121S13.31.973 13.048.872C12.92.821 11.18.802 9.481.783 7.779.763 6.12.741 5.979.873z"
      ></path>
      <path
        fill="#9B9B9D"
        d="M13.323 20.238c.244.003.462-.328.663-.53.43-.43.88-.773 1.392-.309 1.261 1.146-1.27 2.309-2.23 3.898-.718 1.186-.863 2.098-.664 2.341.2.244 5.048.232 5.114.11.066-.121.134-1.745.021-1.823-.112-.077-2.496-.033-2.496-.033s.2-.464 1.017-1.171c.898-.776 1.617-1.843 1.425-3.104-.398-2.606-3.023-2.702-4.16-1.795-1.109.884-.87 2.407-.082 2.416z"
      ></path>
    </svg>
  );
};
