import * as React from "react";

interface IUapplyLogoProps {
  style?: object;
}

export const UapplyLogo: React.FunctionComponent<IUapplyLogoProps> = (
  props
) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="28"
      height="28"
      fill="none"
      viewBox="0 0 28 28"
      style={props.style}
    >
      <rect
        width="28"
        height="28"
        fill="#299CF7"
        rx="14"
        transform="matrix(-1 0 0 1 28 0)"
      ></rect>
      <mask
        id="mask0_2238_59643"
        style={{ maskType: "alpha" }}
        width="28"
        height="28"
        x="0"
        y="0"
        maskUnits="userSpaceOnUse"
      >
        <rect
          width="28"
          height="28"
          fill="#1E2432"
          rx="14"
          transform="matrix(-1 0 0 1 28 0)"
        ></rect>
      </mask>
      <g mask="url(#mask0_2238_59643)">
        <path
          fill="#FD3E60"
          fillRule="evenodd"
          d="M-2.623 5.264C-10.5 15.576-7.71 28.522 2.162 26.834c.13-.017.264-.049.38-.064 12.542-2.455 10.065 18.504 18.566 5.662 8.5-12.843 9.83-.45 16.418-13.097 6.59-12.66 16.245-20.577 5.787-33.714-9.526-11.96-2.37-22.798-13.547-18.881-2.083.744-4.15 1.257-6.172 1.531-3.343.436-9.692 3.191-14.865 16.6C5.989-7.937 2.119-.985-2.623 5.263z"
          clipRule="evenodd"
        ></path>
        <path
          fill="#FDBC3E"
          fillRule="evenodd"
          d="M-30.371 44.585c.302-4.272-1.864-1.238 1.761-4.698 2.655-2.51-4.003-14.185.465-14.713 5.815-.683 13.012.776 17.896-1.817 2.61-1.381 3.982-3.669 3.678-6.136-.79-6.438-9.287-9.087-16.999-10.13-9.048-1.234-18.796-1.164-25.395-7.134-3.903-3.528-5.377-8.824-10.829-11.526-7.984-3.965-14.735 2.374-17.713 6.592-2.68 3.826-5.102 7.644-6.695 11.854-.842 2.186.913 4.208 1.76 6.528 2.07 5.7 8.047 6.21 13.733 9.165 2.988 1.569 5.211 3.799 6.266 6.286 2.022 4.538 1.631 9.28 1.663 13.83.022 4.344 18.164 11.985 25.412 10.614 3.316-.622 13.143 26.14 9.839 9.683 5.417 11.651 3.19-9.035-4.842-18.398z"
          clipRule="evenodd"
        ></path>
        <path
          fill="#fff"
          d="M17.776 7.4l.057.058a1.497 1.497 0 002.117 0 1.495 1.495 0 000-2.116l-.057-.056A1.495 1.495 0 1017.776 7.4zM7.86 7.904h2.548L9.135 4.848 7.859 7.904zM7.606 10.281l.011 6.618c0 1.758.612 3.248 1.836 4.47 1.238 1.223 2.752 1.834 4.542 1.834 1.804 0 3.318-.611 4.541-1.834 1.24-1.222 1.858-2.712 1.858-4.47l-.01-6.618h-3.051V16.9c0 .978-.303 1.666-.93 2.338-.627.658-1.43.986-2.408.986-.98 0-1.782-.328-2.41-.986-.61-.672-.928-1.345-.928-2.338v-6.618h-3.05z"
        ></path>
      </g>
    </svg>
  );
};
