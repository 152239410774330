import React from "react";
import { Col } from "react-bootstrap";
import { Label } from "../DocumentPage/style";
import { Required } from "../AboutPage/styled";
import { DropdownComponent } from "../DropdownComponent";
import { FormInput } from "../FormInput";
import { DateComponent } from "../DateComponent";

type Props = {
  settestCat: Function;
  testCat: string;
  issueDate: Date;
  setissueDate: Function;
  setvalidDate: Function;
};

export const ETestForm = (props: Props) => {
  return (
    <div>
      <Col md={6} style={{ marginBottom: 20 }}>
        <Label>
          <Required>*</Required> Test
        </Label>
        <DropdownComponent
          children={[
            "IELTS UKVI Academic",
            "IELTS Academic",
            "TOEFL",
            "Pearson PTE",
            "Duolingo",
            "Other",
          ]}
          onClick={(a: string) => {
            props.settestCat(a);
          }}
          className={""}
          style={undefined}
          strArr
          selected={props.testCat}
          placeholder="Entrance Test"
        />
      </Col>
      {props.testCat === "Other" ? (
        <Col md={6} style={{ marginBottom: 20 }}>
          <Label>
            <Required>*</Required> Test Name
          </Label>
          <FormInput
            id="testName"
            style={{ borderRadius: 3, marginBottom: 0 }}
            name="testName"
            placeholder="Test Name"
            type="text"
          />
        </Col>
      ) : null}
      <Col md={6} style={{ marginBottom: 20 }}>
        <Label>
          <Required>*</Required> Score
        </Label>
        <FormInput
          id="score"
          style={{ borderRadius: 3, marginBottom: 0 }}
          name="score"
          placeholder="Score"
          type="number"
        />
      </Col>
      <Col md={6} style={{ marginBottom: 20 }}>
        <DateComponent
          monthLabel={""}
          yearLabel={"Date of Issue"}
          dateFromChild={(data: React.SetStateAction<string>) => {
            props.setissueDate(data);
          }}
          label={""}
        />
      </Col>
      <Col md={6} style={{ marginBottom: 20 }}>
        <DateComponent
          monthLabel={""}
          yearLabel={"Valid Till"}
          dateFromChild={(data: React.SetStateAction<string>) => {
            props.setvalidDate(data);
          }}
          label={""}
        />
      </Col>
    </div>
  );
};
