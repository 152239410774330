import styled from "styled-components";

export type InputComponentProps = {
  arabic?: boolean;
};
export const InputComponent = styled.input<InputComponentProps>(
  ({ arabic }) => `
  text-align:${arabic ? "right" : "left"};
  direction: ${arabic ? "rtl" : "ltr"};
  font-family: ${arabic ? "Inter" : "Inter"};
  width:100% !important;
  ::placeholder {
    font-family: ${arabic ? "Inter" : "Inter"};
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    direction:rtl;
    color: #99a5b4;
  }  `
);

export const Con = styled.a`
  padding: 24px;
  text-decoration: none;
`;
export const BorderBottom = styled.div`
  border-bottom: 1px solid #eaecf0;
`;

export const HeaderText = styled.div`
  font-family: "Inter";
  color: #101828;
  padding-bottom: 8px;
  padding-top: 16px;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
`;
export const SubText = styled.div`
  font-family: "Inter";
  color: #667085;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
`;
