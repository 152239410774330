import React from "react";

type Props = {};

export const SourceIcon = (props: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="31"
      height="31"
      fill="none"
      viewBox="0 0 31 31"
    >
      <path
        fill="#61D3A4"
        fillRule="evenodd"
        d="M30.336 15.5c0 8.284-6.716 15-15 15-8.284 0-15-6.716-15-15 0-8.284 6.716-15 15-15 8.284 0 15 6.716 15 15z"
        clipRule="evenodd"
        opacity="0.21"
      ></path>
      <path
        fill="#61D3A4"
        d="M20.828 24.3a4.035 4.035 0 01-2.264-.666 4.472 4.472 0 01-1.554-1.714.357.357 0 01-.025-.313.398.398 0 01.205-.235.39.39 0 01.304.001.472.472 0 01.242.21c.285.567.7 1.026 1.246 1.378a3.34 3.34 0 001.849.529c.973 0 1.799-.34 2.478-1.022a3.368 3.368 0 001.019-2.469c0-.964-.34-1.787-1.022-2.468a3.363 3.363 0 00-2.469-1.022 3.38 3.38 0 00-1.608.408c-.51.272-.952.63-1.325 1.072h1.33a.392.392 0 01.404.405.398.398 0 01-.114.29.387.387 0 01-.29.116h-1.957a.717.717 0 01-.528-.212.716.716 0 01-.213-.527v-1.957a.394.394 0 01.404-.406.396.396 0 01.407.405v1.29a5.212 5.212 0 011.567-1.225 4.15 4.15 0 011.923-.47c1.19 0 2.204.42 3.044 1.258.839.838 1.258 1.853 1.257 3.043 0 1.19-.42 2.205-1.26 3.044-.84.838-1.856 1.257-3.05 1.257zm-9.157-12.797h7.333c.13 0 .239-.044.326-.132a.444.444 0 00.132-.328.441.441 0 00-.132-.326.445.445 0 00-.326-.13H11.67a.45.45 0 00-.458.46.443.443 0 00.458.457M9.485 21.83c-.407 0-.756-.145-1.046-.436a1.429 1.429 0 01-.435-1.044V8.646c0-.407.145-.755.435-1.045a1.43 1.43 0 011.045-.435H21.19c.407 0 .755.145 1.045.435.29.29.435.639.436 1.045v3.712a.65.65 0 01-.253.529.663.663 0 01-.572.144 4.722 4.722 0 00-.492-.064 7.214 7.214 0 00-2.514.265 6.99 6.99 0 00-1.767.809h-5.402a.45.45 0 00-.458.46.447.447 0 00.458.457h4.266a7.697 7.697 0 00-.984 1.17c-.289.423-.526.879-.71 1.368H11.67a.441.441 0 00-.327.132.444.444 0 00-.132.327c0 .13.044.24.132.327a.445.445 0 00.327.13h2.302c-.066.26-.113.52-.143.783a7.26 7.26 0 00-.025 1.321c.013.17.034.334.064.492a.69.69 0 01-.145.58.652.652 0 01-.528.245h-3.71z"
      ></path>
    </svg>
  );
};
