import React from "react";
import styled from "styled-components";
import { Button } from "react-bootstrap";
import { Card } from "./Card";

const SectionWrapper = styled.div`
  margin-bottom: 2rem;
  background: #f9f9f9;
  padding: 1rem;
  border-radius: 8px;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
`;

const Title = styled.h6`
  margin: 0;
`;

type SectionProps = {
  title: string;
  cards: any;
};

export const Section: React.FC<SectionProps> = ({ title, cards }) => {
  return (
    <SectionWrapper>
      <Header>
        <Title>{title}</Title>
        <Button variant="outline-primary" size="sm">
          Add Card
        </Button>
      </Header>
      {cards.map((card: any, index: any) => (
        <Card
          key={index}
          applicationId={card.applicationId}
          date={card.date}
          status={card.status}
        />
      ))}
    </SectionWrapper>
  );
};
