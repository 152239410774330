import * as React from "react";

interface IMenuIconProps {
  style?: object;
}

export const MenuIcon: React.FunctionComponent<IMenuIconProps> = (props) => {
  return (
    <svg
      width={28}
      height={28}
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect x={0.5} y={0.5} width={27} height={27} rx={13.5} fill="#FAFBFC" />
      <path
        d="M14 11C13.1716 11 12.5 10.3284 12.5 9.5C12.5 8.67157 13.1716 8 14 8C14.8284 8 15.5 8.67157 15.5 9.5C15.5 10.3284 14.8284 11 14 11Z"
        fill="#505F79"
      />
      <path
        d="M14 15.5C13.1716 15.5 12.5 14.8284 12.5 14C12.5 13.1716 13.1716 12.5 14 12.5C14.8284 12.5 15.5 13.1716 15.5 14C15.5 14.8284 14.8284 15.5 14 15.5Z"
        fill="#505F79"
      />
      <path
        d="M14 20C13.1716 20 12.5 19.3284 12.5 18.5C12.5 17.6716 13.1716 17 14 17C14.8284 17 15.5 17.6716 15.5 18.5C15.5 19.3284 14.8284 20 14 20Z"
        fill="#505F79"
      />
      <rect x={0.5} y={0.5} width={27} height={27} rx={13.5} stroke="#B3D4FF" />
    </svg>
  );
};
