import * as React from "react";

interface IMessageIconProps {
  style: object;
}

export const MessageIcon: React.FunctionComponent<IMessageIconProps> = (
  props
) => {
  return (
    <svg
      width={30}
      height={30}
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect width={30} height={30} rx={15} fill="#EBF3FF" />
      <g clipPath="url(#clip0_32_14941)">
        <path
          d="M8.95364 13.6426H7.11764C6.52117 13.6426 6.03764 14.0914 6.03764 14.6451V20.9334V21.9746C6.03764 22.1961 6.23106 22.3757 6.46964 22.3757C6.57523 22.3757 6.67715 22.3398 6.75617 22.2748L8.20267 21.0848C8.3212 20.9872 8.47409 20.9334 8.63246 20.9334H15.3256C15.9221 20.9334 16.4056 20.4846 16.4056 19.9309V18.2824H9.81764C9.34047 18.2824 8.95364 17.9233 8.95364 17.4804V13.6426Z"
          fill="#4C9AFF"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.70964 7.92822C9.29212 7.92822 8.95364 8.24242 8.95364 8.62999V17.5192C8.95364 17.9068 9.29212 18.221 9.70964 18.221H20.2432C20.4826 18.221 20.7136 18.303 20.8919 18.4513L23.4616 20.5889C23.4814 20.6054 23.507 20.6145 23.5336 20.6145C23.5933 20.6145 23.6416 20.5696 23.6416 20.5143V8.62999C23.6416 8.24242 23.3032 7.92822 22.8856 7.92822H9.70964Z"
          stroke="#0052CC"
          strokeWidth={0.75}
        />
        <path
          d="M13.2736 13.6428C13.6912 13.6428 14.0296 13.3286 14.0296 12.941C14.0296 12.5535 13.6912 12.2393 13.2736 12.2393C12.8561 12.2393 12.5176 12.5535 12.5176 12.941C12.5176 13.3286 12.8561 13.6428 13.2736 13.6428Z"
          fill="#0052CC"
        />
        <path
          d="M16.0816 13.6428C16.4992 13.6428 16.8376 13.3286 16.8376 12.941C16.8376 12.5535 16.4992 12.2393 16.0816 12.2393C15.6641 12.2393 15.3256 12.5535 15.3256 12.941C15.3256 13.3286 15.6641 13.6428 16.0816 13.6428Z"
          fill="#0052CC"
        />
        <path
          d="M18.8896 13.6428C19.3072 13.6428 19.6456 13.3286 19.6456 12.941C19.6456 12.5535 19.3072 12.2393 18.8896 12.2393C18.4721 12.2393 18.1336 12.5535 18.1336 12.941C18.1336 13.3286 18.4721 13.6428 18.8896 13.6428Z"
          fill="#0052CC"
        />
      </g>
      <defs>
        <clipPath id="clip0_32_14941">
          <rect
            width={18}
            height={15}
            fill="white"
            transform="translate(6 7.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
