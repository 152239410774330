/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
import { useSelector } from "react-redux";
import { getUserById, updateUser } from "../Services/Function";

import { Navigate, useParams } from "react-router-dom";
import { Container } from "./styled/students";
import { Col, Row, Tab, Tabs } from "react-bootstrap";
import {
  ButtonComponent,
  FormInput,
  Loader,
  PasswordInput,
} from "../Components";
import { Form, Formik, FormikHelpers } from "formik";

interface IProfileProps {}

export const Profile: React.FunctionComponent<IProfileProps> = (props) => {
  const auth = useSelector((state: any) => state.auth);
  var authToken = localStorage.getItem("authToken");

  const [user, setuser] = React.useState<any>({});

  const { id } = useParams<{ id: string }>();
  const [loading, setloading] = React.useState(false);

  React.useEffect(() => {
    async function fetchData() {
      const user = await getUserById(id);
      setuser(user);
    }
    fetchData();
  }, []);

  if (!auth.isLoggedIn && !authToken) {
    return <Navigate to="/" />;
  }

  return (
    <Container>
      <Row className="justify-content-center">
        <Col md={5}>
          {Object.keys(user).length ? (
            <div style={{ padding: "20px 0" }}>
              <Formik
                initialValues={{
                  name: user.name || "", // Provide default values
                  email: user.email || "",
                  password: user.password || "",
                  slackMemberId: user.slackMemberId,
                }}
                onSubmit={async (
                  values: any,
                  { setSubmitting }: FormikHelpers<any>
                ) => {
                  setloading(true);
                  let updateData;
                  if (values.password) {
                    updateData = {
                      name: values.name,
                      email: values.email,
                      password: values.password,
                      slackMemberId: values.slackMemberId,
                    };
                  } else {
                    updateData = {
                      name: values.name,
                      email: values.email,
                      slackMemberId: values.slackMemberId,
                    };
                  }
                  await updateUser(user.id, updateData);
                  const data = await getUserById(id);
                  setuser(data);
                  setloading(false);
                }}
              >
                <Form className="d-flex flex-column">
                  <FormInput
                    id="name"
                    name="name"
                    placeholder=""
                    type="text"
                    label="Name"
                  />
                  <FormInput
                    id="email"
                    name="email"
                    placeholder="aamish@try.city"
                    type="email"
                    label="Email"
                  />
                  {/* <PasswordInput
                    id="password"
                    label="Password"
                    name="password"
                  /> */}

                  <FormInput
                    id="slackMemberId"
                    name="slackMemberId"
                    placeholder=""
                    type="text"
                    label="Slack Member Id"
                  />
                  <ButtonComponent
                    btnType="dark"
                    loading={loading}
                    label="Submit"
                    disabled={loading}
                  />
                </Form>
              </Formik>
            </div>
          ) : (
            <Loader />
          )}
        </Col>
      </Row>
    </Container>
  );
};
