import React, { useState } from "react";
import styled, { css } from "styled-components";
import { ViewMore } from "./ViewMore";

const Container = styled.div`
  border-radius: 10px;
  padding: 16px;
  margin: 0 auto;
  background: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

const Th = styled.th<{ isSorted: boolean }>`
  text-align: center;
  padding: 8px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 150%;
  letter-spacing: -0.02em;
  color: #1e2432;
  cursor: pointer;
  ${(props) =>
    props.isSorted &&
    css`
      background-color: rgba(190, 161, 255, 0.1);
    `}
  border-radius: 16px;
  &:first-child {
    text-align: left;
  }
`;

const Td = styled.td`
  padding: 8px;
  text-align: center;
  font-family: "Roboto";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  letter-spacing: -0.02em;
  color: rgba(30, 36, 50, 0.8);
  white-space: nowrap;
  &:first-child {
    text-align: left;
  }
`;

const SchoolRow = styled.tr`
  &:not(:last-child) {
    // border-bottom: 1px solid #ddd;
  }
`;

type TopSchoolTableProps = {
  data: any;
  school?: any;
  topTypes?: any;
  cities?: any;
};

type SortConfig = {
  key: string;
  direction: "ascending" | "descending";
};

export const TopSchoolTable: React.FC<TopSchoolTableProps> = ({
  data,
  school,
  topTypes,
  cities,
}) => {
  const [sortConfig, setSortConfig] = useState<SortConfig>({
    key: "totalWeeks",
    direction: "descending",
  });
  const [modalShow, setmodalShow] = useState(false);

  const sortedData = React.useMemo(() => {
    let sortableData = [...data];
    if (sortConfig !== null) {
      sortableData.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === "ascending" ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === "ascending" ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableData;
  }, [data, sortConfig]);

  const requestSort = (key: string) => {
    let direction: "ascending" | "descending" = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key, direction });
  };

  const renderHeader = () => {
    if (school) {
      return (
        <>
          <Th
            isSorted={sortConfig.key === "topSchool"}
            onClick={() => requestSort("topSchool")}
            style={{ textAlign: "left" }}
          >
            Top School
          </Th>
          <Th
            isSorted={sortConfig.key === "totalStudents"}
            onClick={() => requestSort("totalStudents")}
          >
            N° of Students
          </Th>
          <Th
            isSorted={sortConfig.key === "totalWeeks"}
            onClick={() => requestSort("totalWeeks")}
          >
            N° of Weeks
          </Th>
        </>
      );
    } else if (topTypes) {
      return (
        <>
          <Th
            isSorted={sortConfig.key === "topTypes"}
            onClick={() => requestSort("topTypes")}
            style={{ textAlign: "left" }}
          >
            Top Types of Office Fees
          </Th>
          <Th
            style={{ textAlign: "right" }}
            isSorted={sortConfig.key === "count"}
            onClick={() => requestSort("count")}
          >
            N° of Booking
          </Th>
        </>
      );
    } else if (cities) {
      return (
        <>
          <Th
            isSorted={sortConfig.key === "topCities"}
            onClick={() => requestSort("topCities")}
            style={{ textAlign: "left" }}
          >
            Top Cities
          </Th>
          <Th
            isSorted={sortConfig.key === "numberOfStudents"}
            onClick={() => requestSort("numberOfStudents")}
          >
            N° of Students
          </Th>
          <Th
            isSorted={sortConfig.key === "totalWeeks"}
            onClick={() => requestSort("totalWeeks")}
          >
            N° of Weeks
          </Th>
        </>
      );
    }
  };

  const renderRow = (item: any, index: number) => {
    if (school) {
      return (
        <SchoolRow key={index}>
          <Td>
            <span
              style={{
                fontSize: 14,
                fontWeight: 700,
                color: "#7F56D9",
                paddingRight: 15,
              }}
            >
              0{index + 1}
            </span>{" "}
            {item.school}
          </Td>
          <Td>{item.totalStudents}</Td>
          <Td>{item.totalWeeks}</Td>
        </SchoolRow>
      );
    } else if (topTypes) {
      return (
        <SchoolRow key={index}>
          <Td>
            <span
              style={{
                fontSize: 14,
                fontWeight: 700,
                color: "#7F56D9",
                paddingRight: 15,
              }}
            >
              0{index + 1}
            </span>{" "}
            {item._id}
          </Td>
          <Td style={{ textAlign: "right" }}>{item.count}</Td>
        </SchoolRow>
      );
    } else if (cities) {
      return (
        <SchoolRow key={index}>
          <Td>
            <span
              style={{
                fontSize: 14,
                fontWeight: 700,
                color: "#7F56D9",
                paddingRight: 15,
              }}
            >
              0{index + 1}
            </span>{" "}
            {item._id}
          </Td>
          <Td>{item.numberOfStudents}</Td>
          <Td>{item.totalWeeks}</Td>
        </SchoolRow>
      );
    }
  };

  return (
    <Container>
      <Table style={{ position: "relative" }}>
        <thead>
          <tr>{renderHeader()}</tr>
        </thead>
        <tbody>
          {sortedData
            .slice(0, 3)
            .map((item: any, index: number) => renderRow(item, index))}
        </tbody>
        <div
          onClick={() => setmodalShow(true)}
          style={{
            position: "absolute",
            bottom: "-40px",
            left: "50%",
            transform: "translate(-50%, -50%)",
            cursor: "pointer",
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="22"
            height="20"
            viewBox="0 0 11 10"
            fill="none"
          >
            <path
              d="M9.35892 2.26811L5.5 6.29356L1.64108 2.26811C1.33679 1.91808 1.0325 1.91078 0.728216 2.24624C0.423928 2.58169 0.423928 2.90985 0.728216 3.23072L5.04357 7.78123C5.15422 7.92708 5.30636 8 5.5 8C5.69364 8 5.84578 7.92708 5.95643 7.78123L10.2718 3.23072C10.5761 2.90985 10.5761 2.58169 10.2718 2.24624C9.9675 1.91078 9.66321 1.91808 9.35892 2.26811Z"
              fill="#7F56D9"
            />
          </svg>
        </div>
      </Table>

      <ViewMore
        onHide={() => setmodalShow(false)}
        show={modalShow}
        data={data}
        school={school}
        topTypes={topTypes}
        cities={cities}
      />
    </Container>
  );
};
