import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import styled from "styled-components";
import {
  addDays,
  format,
  getMonth,
  getYear,
  startOfMonth,
  endOfMonth,
  subMonths,
} from "date-fns";
import Dropdown from "react-bootstrap/Dropdown";
import "bootstrap/dist/css/bootstrap.min.css";

const DateFilterContainer = styled.div``;

const DatePickersContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const CustomDatePicker = styled(DatePicker)`
  margin: 10px;
  padding: 10px;
  width: 300px;
  border: 1px solid red;
  border-radius: 5px;
  background-color: #fff;
  padding: 20px !important;
  .react-datepicker {
    border: none !important;
  }
`;

const CustomDropdownMenu = styled(Dropdown.Menu)`
  width: max-content;
  padding: 20px !important;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

// Utility function to reset time to midnight
const resetTime = (date: Date) => {
  const newDate = new Date(date);
  newDate.setHours(0, 0, 0, 0);
  return newDate;
};

type DateFilterProps = {
  startDate: Date;
  setStartDate: any;
  endDate: Date;
  setEndDate: any;
  reload: any;
};

export const DateFilter: React.FC<DateFilterProps> = ({
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  reload,
}) => {
  const [dropdownTitle, setDropdownTitle] = useState("Date Filter");

  const handleDateRangeClick = (days: number) => {
    const today = resetTime(new Date());
    const start = resetTime(addDays(today, -days));
    setStartDate(start);
    setEndDate(today);
    reload(start, today);
    setDropdownTitle(
      `${format(start, "MMM dd yyyy")} - ${format(today, "MMM dd yyyy")}`
    );
  };

  const handleYesterdayClick = () => {
    const yesterday = resetTime(addDays(new Date(), -1));
    setStartDate(yesterday);
    setEndDate(yesterday);
    reload(yesterday, yesterday);
    setDropdownTitle(format(yesterday, "MMM dd yyyy"));
  };

  const handleTodayClick = () => {
    const today = resetTime(new Date());
    setStartDate(today);
    setEndDate(today);
    reload(today, today);
    setDropdownTitle(format(today, "MMM dd yyyy"));
  };

  const handleThisMonthClick = () => {
    const start = resetTime(startOfMonth(new Date()));
    const end = resetTime(endOfMonth(new Date()));
    setStartDate(start);
    setEndDate(end);
    reload(start, end);
    setDropdownTitle(
      `${format(start, "MMM dd yyyy")} - ${format(end, "MMM dd yyyy")}`
    );
  };

  const handleLastMonthClick = () => {
    const start = resetTime(startOfMonth(subMonths(new Date(), 1)));
    const end = resetTime(endOfMonth(subMonths(new Date(), 1)));
    setStartDate(start);
    setEndDate(end);
    reload(start, end);
    setDropdownTitle(
      `${format(start, "MMM dd yyyy")} - ${format(end, "MMM dd yyyy")}`
    );
  };

  const handleDateChange = (dates: [Date, Date]) => {
    const [start, end] = dates;
    const resetStart = start ? resetTime(start) : null;
    const resetEnd = end ? resetTime(end) : null;
    setStartDate(resetStart);
    setEndDate(resetEnd);
    if (resetStart && resetEnd) {
      reload(resetStart, resetEnd);
      setDropdownTitle(
        `${format(resetStart, "MMM dd yyyy")} - ${format(resetEnd, "MMM dd yyyy")}`
      );
    }
  };

  const handleRemoveFilter = () => {
    const start = resetTime(startOfMonth(new Date()));
    const end = resetTime(endOfMonth(new Date()));
    setStartDate(start);
    setEndDate(end);
    reload(start, end);
    setDropdownTitle(`This Month`);
  };

  const isDateInCurrentMonth = (date: Date) => {
    const currentMonth = getMonth(new Date());
    const currentYear = getYear(new Date());
    return getMonth(date) === currentMonth && getYear(date) === currentYear;
  };

  const isDateInNextMonth = (date: Date) => {
    const nextMonth = (getMonth(new Date()) + 1) % 12;
    const currentYear = getYear(new Date());
    const nextMonthYear = nextMonth === 0 ? currentYear + 1 : currentYear;
    return getMonth(date) === nextMonth && getYear(date) === nextMonthYear;
  };

  return (
    <DateFilterContainer>
      <Dropdown>
        <Dropdown.Toggle
          className="filter-dd"
          variant="success"
          id="dropdown-basic"
        >
          {dropdownTitle}
        </Dropdown.Toggle>

        <CustomDropdownMenu>
          <DatePickersContainer>
            <div>
              <Dropdown.Item onClick={handleTodayClick}>Today</Dropdown.Item>
              <Dropdown.Item onClick={handleYesterdayClick}>
                Yesterday
              </Dropdown.Item>
              <Dropdown.Item onClick={() => handleDateRangeClick(7)}>
                Last 7 days
              </Dropdown.Item>
              <Dropdown.Item onClick={() => handleDateRangeClick(30)}>
                Last 30 days
              </Dropdown.Item>
              <Dropdown.Item onClick={() => handleDateRangeClick(90)}>
                Last 90 days
              </Dropdown.Item>
              <Dropdown.Item onClick={handleThisMonthClick}>
                This Month
              </Dropdown.Item>
              <Dropdown.Item onClick={handleLastMonthClick}>
                Last Month
              </Dropdown.Item>
              <Dropdown.Item onClick={() => handleDateRangeClick(365)}>
                Last year
              </Dropdown.Item>
              <Dropdown.Item
                onClick={handleRemoveFilter}
                className="remove-filter"
              >
                Remove Filter
              </Dropdown.Item>
            </div>
            <div className="d-flex flex-row">
              <CustomDatePicker
                selected={startDate}
                onChange={handleDateChange}
                startDate={startDate}
                endDate={endDate}
                selectsRange
                inline
                showMonthDropdown
                showYearDropdown
                renderDayContents={(day: any, date: any) => (
                  <div
                    className={
                      isDateInCurrentMonth(date) || isDateInNextMonth(date)
                        ? ""
                        : "text-muted"
                    }
                  >
                    {day}
                  </div>
                )}
              />
            </div>
          </DatePickersContainer>
        </CustomDropdownMenu>
      </Dropdown>
    </DateFilterContainer>
  );
};
