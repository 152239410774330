import React, { useState } from "react";
import { Button, Modal, Form } from "react-bootstrap";
import { Formik } from "formik";
import { createAppliedStudent } from "../../Services/Function";
import ReactDatePicker from "react-datepicker";

export const NumberInputModal = (props: {
  show: boolean;
  onHide: () => void;
  userDetails: { id: string };
  reloadData: any;
}) => {
  const { show, onHide, userDetails, reloadData } = props;
  const [number, setNumber] = useState(0);
  const [loading, setLoading] = useState(false);
  const [createdDate, setCreatedDate] = useState(new Date());

  const handleIncrement = () => setNumber((prevNumber) => prevNumber + 1);
  const handleDecrement = () => setNumber((prevNumber) => prevNumber - 1);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (value === "" || value === "-") {
      setNumber(0);
    } else {
      const parsedValue = parseInt(value, 10);
      if (!isNaN(parsedValue)) {
        setNumber(parsedValue);
      }
    }
  };

  const handleSubmit = async () => {
    setLoading(true);
    await createAppliedStudent({
      number,
      createdDate: createdDate,
      userId: userDetails.id,
    });
    setLoading(false);
    reloadData();
    setNumber(0);
    setCreatedDate(new Date());
    onHide();
  };

  return (
    <Modal centered show={show} onHide={onHide} size="sm">
      <Modal.Header closeButton>
        <Modal.Title className="modal-title">Add </Modal.Title>
      </Modal.Header>
      <Formik
        initialValues={{}}
        onSubmit={(values, { setSubmitting }) => {
          handleSubmit();
        }}
      >
        {(formik) => (
          <Form onSubmit={formik.handleSubmit} className="d-flex flex-column">
            <Modal.Body>
              <div
                className="d-flex w-100 align-items-center justify-content-start"
                style={{ marginBottom: 20 }}
              >
                <Button
                  variant="outline-secondary"
                  type="button"
                  onClick={handleDecrement}
                >
                  -
                </Button>
                <Form.Control
                  type="number"
                  value={number.toString()}
                  onChange={handleChange}
                  className="mx-2"
                  style={{ width: "80px", textAlign: "center" }}
                />
                <Button
                  variant="outline-secondary"
                  type="button"
                  onClick={handleIncrement}
                >
                  +
                </Button>
              </div>
              <ReactDatePicker
                selected={createdDate}
                className="form-input"
                onChange={(date: any) => setCreatedDate(date)}
              />
            </Modal.Body>
            <Modal.Footer className="w-100">
              <Button type="submit" variant="dark" disabled={loading}>
                {loading ? "Saving..." : "Save"}
              </Button>
            </Modal.Footer>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};
