/* eslint-disable import/no-anonymous-default-export */
import {
  Action,
  WA_KEY,
  UPLOADED,
  SET_UPLOAD_PROGRESS,
  SET_UPLOADING,
} from "../actions";

const INTIAL_STATE = {
  waKey: "",
  uploaded: "",
  progress: 0,
  uploading: false,
};

export default (state = INTIAL_STATE, action: any) => {
  switch (action.type) {
    case WA_KEY:
      return {
        ...state,
        waKey: action.payload,
      };

    case UPLOADED:
      return {
        ...state,
        uploaded: action.payload,
      };
    case SET_UPLOAD_PROGRESS:
      return {
        ...state,
        progress: action.payload,
      };
    case SET_UPLOADING:
      return {
        ...state,
        uploading: action.payload,
      };
    default:
      return state;
  }
};
