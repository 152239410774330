import * as React from "react";

interface ILogoProps {
  color?: string;
}

export const Logo: React.FunctionComponent<ILogoProps> = (props) => {
  const { color } = props;
  return (
    <svg
      width={110}
      height={30}
      viewBox="0 0 123 39"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M0 9.19824L0.0184403 19.631C0.0184403 22.5669 1.04168 25.0559 3.08815 27.0982C5.16021 29.1406 7.69272 30.1617 10.6857 30.1617C13.7042 30.1617 16.2367 29.1406 18.2832 27.0982C20.3553 25.0559 21.3913 22.5669 21.3913 19.631L21.3729 9.19824H18.8212H16.2695L16.2694 19.631C16.2694 21.2649 15.7635 22.2892 14.7147 23.4124C13.6659 24.5102 12.3229 25.1836 10.6857 25.1836C9.04851 25.1836 7.70551 24.5102 6.65669 23.4124C5.63345 22.2892 5.10338 21.2904 5.10338 19.631L5.10339 9.19824H2.5517H0Z"
        fill={color ? color : "#fff"}
      />
      <path
        d="M0.427856 5.13391H4.70612L2.569 0L0.427856 5.13391Z"
        fill={color ? color : "#fff"}
      />
      <path
        d="M16.9953 4.30058L17.0908 4.39605C18.0746 5.37986 19.6697 5.37987 20.6535 4.39606C21.6373 3.41224 21.6373 1.81717 20.6535 0.833354L20.558 0.737859C19.5742 -0.245955 17.9791 -0.245952 16.9953 0.737863C16.0115 1.72168 16.0115 3.31677 16.9953 4.30058Z"
        fill={color ? color : "#fff"}
      />
      <path
        d="M34.2457 8.61557C30.4093 8.61557 27.2976 10.1111 24.7826 13.1448L28.4911 16.264C30.0257 14.213 32.0291 13.1021 34.1605 13.1021C36.5902 13.1021 38.1248 14.5121 38.1248 16.8195V17.4177H32.8817C30.4946 17.4177 28.7043 17.7168 27.2976 18.3577C25.2515 19.2978 24.1432 21.1778 24.1432 23.6561C24.1432 27.6726 27.2976 30.5355 31.7307 30.5355C34.5015 30.5355 36.8886 29.339 38.1248 27.3735L38.551 30.1082H43.24V16.8195C43.24 11.7348 39.7872 8.61557 34.2457 8.61557ZM32.5407 26.1771C30.4946 26.1771 29.2584 25.2371 29.2584 23.7416C29.2584 22.9725 29.5568 22.3742 30.1109 21.9897C30.793 21.4769 31.816 21.2633 33.5637 21.2633H38.1248C38.1248 23.9979 35.6098 26.1771 32.5407 26.1771Z"
        fill={color ? color : "#fff"}
      />
      <path
        d="M58.5566 8.61557C56.2974 8.61557 53.9529 9.34196 52.5036 11.1366V9.04285H47.3884V38.8248H52.5036V27.9717C53.8251 29.5527 56.1695 30.5355 58.5566 30.5355C63.7145 30.5355 67.423 26.4762 67.423 19.5969C67.423 13.273 64.2686 8.61557 58.5566 8.61557ZM57.2778 26.0489C53.6119 26.0489 52.0347 23.2288 52.0347 19.5541C52.0347 15.794 53.6119 13.1021 57.3205 13.1021C60.7306 13.1021 62.3078 15.4094 62.3078 19.5541C62.3078 23.6561 60.688 26.0489 57.2778 26.0489Z"
        fill={color ? color : "#fff"}
      />
      <path
        d="M81.7133 8.61557C79.4541 8.61557 77.1096 9.34196 75.6603 11.1366V9.04285H70.5451V38.8248H75.6603V27.9717C76.9817 29.5527 79.3262 30.5355 81.7133 30.5355C86.8711 30.5355 90.5796 26.4762 90.5796 19.5969C90.5796 13.273 87.4253 8.61557 81.7133 8.61557ZM80.4345 26.0489C76.7686 26.0489 75.1914 23.2288 75.1914 19.5541C75.1914 15.794 76.7686 13.1021 80.4771 13.1021C83.8872 13.1021 85.4644 15.4094 85.4644 19.5541C85.4644 23.6561 83.8446 26.0489 80.4345 26.0489Z"
        fill={color ? color : "#fff"}
      />
      <path
        d="M98.3907 30.5355C99.3711 30.5355 100.522 30.3218 101.673 29.8945V25.7926C101.076 25.9635 100.522 26.0489 100.053 26.0489C99.1153 26.0489 98.5612 25.6644 98.5612 24.7243V0.326172H93.446V25.0662C93.446 28.8263 95.5347 30.5355 98.3907 30.5355Z"
        fill={color ? color : "#fff"}
      />
      <path
        d="M105.523 38.8248H111.064L123 9.04285H117.373L112.002 23.6561L106.674 9.04285H101.047L109.274 29.5527L105.523 38.8248Z"
        fill={color ? color : "#fff"}
      />
    </svg>
  );
};
