import React from "react";
import styled from "styled-components";
import { Container, Row, Col, Tabs, Tab } from "react-bootstrap";
import { NotificationItem, Section } from "../Components";

const DashboardWrapper = styled.div`
  padding: 2rem;
  background: #f1f1f1;
`;
const TabContentWrapper = styled.div`
  background: #fff;
  border: 1px solid #eaeaea;
  border-radius: 8px;
  padding: 1rem;
`;
const notifications = [
  {
    type: "message",
    applicationId: "A-130224-0001-01",
    counselorName: "[CounselorName]",
    studentName: "[StudentName]",
    university: "Queen Mary University",
    course: "MSc Management",
    date: "May 20, 2024",
  },
  {
    type: "warning",
    applicationId: "A-130224-0001-02",
    counselorName: "[CounselorName]",
    studentName: "[StudentName]",
    university: "Queen Mary University",
    course: "MSc Management",
    date: "May 21, 2024",
  },
  // Add more notifications as needed
];

const sections = [
  {
    title: "Backlog",
    cards: [
      {
        applicationId: "A-130224-0001-03",
        date: "16 de June",
        status: "submitted",
      },
    ],
  },
  {
    title: "Submitted & Filled",
    cards: [
      {
        applicationId: "A-130224-0001-03",
        date: "16 de June",
        status: "submitted",
      },
    ],
  },
  {
    title: "Conditional offer",
    cards: [
      {
        applicationId: "A-130224-0001-01",
        date: "20 de June",
        status: "conditional",
      },
    ],
  },
  {
    title: "Done",
    cards: [
      { applicationId: "A-130224-0001-01", date: "09 de June", status: "done" },
    ],
  },
  // Add more sections as needed
];

export const Notification: React.FC = () => {
  return (
    <DashboardWrapper>
      <Container fluid>
        <Row>
          <Col md={8}>
            <Tabs
              id="controlled-tab-example"
              defaultActiveKey={"all"}
              // onSelect={(k) => setKey(k)}
              className="mb-3"
            >
              <Tab eventKey="all" title="All (06)">
                <TabContentWrapper>
                  {notifications.map((notification, index) => (
                    <NotificationItem key={index} {...notification} />
                  ))}
                </TabContentWrapper>
              </Tab>
              <Tab eventKey="priority" title="Priority (01)">
                <TabContentWrapper>
                  {notifications.map((notification, index) => (
                    <NotificationItem key={index} {...notification} />
                  ))}
                </TabContentWrapper>
              </Tab>
              <Tab eventKey="new" title="New (04)">
                <TabContentWrapper>
                  {notifications.map((notification, index) => (
                    <NotificationItem key={index} {...notification} />
                  ))}
                </TabContentWrapper>
              </Tab>
              <Tab eventKey="reminder" title="Reminder (01)">
                <TabContentWrapper>
                  Reminder notifications content goes here
                </TabContentWrapper>
              </Tab>
            </Tabs>
          </Col>
          <Col md={4}>
            <Section title="Backlog" cards={sections[0].cards} />
          </Col>
        </Row>
        <Row>
          <Col md={4}>
            <Section title="Submitted & Filled" cards={sections[1].cards} />
          </Col>
          <Col md={4}>
            <Section title="Conditional offer" cards={sections[2].cards} />
          </Col>
          <Col md={4}>
            <Section title="Done" cards={sections[3].cards} />
          </Col>
        </Row>
      </Container>
    </DashboardWrapper>
  );
};
