import React from "react";
import { Modal } from "react-bootstrap";
import { ButtonComponent } from "../ButtonComponent";

type Props = {
  show: any;
  handleClose: any;
};

function ApplicationModal({ show, handleClose }: Props) {
  return (
    <div>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        // id="modal-right"
        className="modal fade"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title className="modal-title">
            Application Documents
          </Modal.Title>
        </Modal.Header>
        <Modal.Body></Modal.Body>
        <Modal.Footer>
          {/* <ButtonComponent
            onClick={() => {
           
              props.handleClose();
            }}
            btnType="light"
            loading={false}
            label="Close"
          /> */}
          <ButtonComponent
            onClick={() => {
              console.log("first");
            }}
            btnType="dark"
            loading={false}
            label="Apply"
          />
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default ApplicationModal;
