import React from "react";
import styled from "styled-components";
import { CatAnimation } from "./Animations/CatAnimation";

const Container = styled.div`
  background-color: #fff;
  padding: 16px 24px;
  border-radius: 10px;
  margin: 0 auto;
`;

const MessageContainer = styled.div`
  display: flex;
  align-items: center;
`;

const MessageBubble = styled.div`
  background-color: #f0f8ff;
  padding: 8px 15px;
  border-radius: 10px;
  position: relative;
  margin-left: 24px;
  flex: 1;

  &:after {
    content: "";
    position: absolute;
    left: -10px;
    top: 50%;
    transform: translateY(-50%);
    border-width: 10px;
    border-style: solid;
    border-color: transparent #f0f8ff transparent transparent;
  }
`;

const Message = styled.div`
  color: #1e2432;
  font-family: "Roboto";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 1.5;
`;

type Props = {
  dailyQuote: string;
};

export const QuotesComponent = (props: Props) => {
  return (
    <Container>
      <MessageContainer>
        <CatAnimation width="64px" height="64px" />
        <MessageBubble>
          <Message>{props.dailyQuote}</Message>
          <div style={{ position: "absolute", left: -10, top: 20 }}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="21"
              height="21"
              viewBox="0 0 21 21"
              fill="none"
            >
              <path
                d="M0 20.8731C0 20.8731 15.8814 22.2253 19.2043 16.1021C21.7448 11.4207 18.0404 0 18.0404 0C18.0404 0 12.8902 7.49647 9.89312 11.3311C6.57638 15.5748 0 20.8731 0 20.8731Z"
                fill="#F2F6FB"
              />
            </svg>
          </div>
        </MessageBubble>
      </MessageContainer>
    </Container>
  );
};
