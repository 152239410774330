import React, { useRef, useEffect } from "react";
import { Col, Form, ProgressBar, Row } from "react-bootstrap";
import styled from "styled-components";
import { Label } from "./FormInput/styled";
import { Bar } from "react-chartjs-2";
import { SalesComponent } from "./SalesComponent/SalesComponent";
import { AddBtn } from "./DocumentPage/style";
import {
  getApplicationPerMonth,
  getEnrolledApplicationPerMonth,
} from "../Services/Function";

type Props = {
  achivedText: any;
  studentApplied: any;
  progressData: any;
  chartHeight: any;
  dataStudent: any;
  optionsStudent: any;
  chartContainer: any;
  top5AppliedNationality: any;
  users: any;
  startDate: any;
  endDate?: any;
  phaseCount: any;
  topUniversities: any;
  topUniversitiesEnrolled: any;
  studentsByStatus: any;
  enrolledApplicationByMonth: any;
  applicationByMonth: any;
  setShow: any;
  setintakeYear: any;
  intakeYear: any;
  setintakeMonth: any;
  intakeMonth: any;
  setapplicationByMonth: any;
  intakeYearEnrolled: any;
  intakeMonthEnrolled: any;
  setintakeMonthEnrolled: any;
  setintakeYearEnrolled: any;
  setenrolledApplicationByMonth: any;
  dashboardDataStudent: any;
};

export const StudentComponent = (props: Props) => {
  const chartRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (chartRef.current) {
      const chartHeight = chartRef.current.offsetHeight;
      document.querySelectorAll(".chart-container").forEach((el) => {
        (el as HTMLElement).style.height = `${chartHeight}px`;
      });
    }
  }, []);

  const enrolledData = {
    labels: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sept",
      "Oct",
      "Nov",
      "Dec",
    ],
    datasets: [
      {
        label: "2024",
        data: props.enrolledApplicationByMonth,
        backgroundColor: "rgba(104, 88, 244, 0.8)",
        barThickness: 20,
        maxBarThickness: 20,
      },
    ],
  };

  const data = {
    labels: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sept",
      "Oct",
      "Nov",
      "Dec",
    ],
    datasets: [
      {
        label: "2024",
        data: props.applicationByMonth,
        backgroundColor: "rgba(104, 88, 244, 0.8)",
        barThickness: 20,
        maxBarThickness: 20,
      },
    ],
  };

  function getCountryEmoji(
    countryCode: string | undefined
  ): string | undefined {
    const OFFSET = 127397;
    const codePoints: number[] | undefined = countryCode
      ?.toUpperCase()
      .split("")
      .map((char: string) => char.charCodeAt(0) + OFFSET);
    return codePoints ? String.fromCodePoint(...codePoints) : undefined;
  }

  const phaseColors: { [key: string]: string } = {
    Initiated: "#61D3A4",
    Submitted: "#FDBC3E",
    "Conditional offer": "#F32D2D",
    "Unconditional offer": "#BEA1FF",
    Confirmation: "#299CF7",
    "FG/BS": "#FFB7C4",
    "CAS Received": "#532D9E",
    Done: "#22C55E",
  };

  const totalApplications = props.phaseCount.reduce(
    (sum: any, phase: any) => sum + phase?.count,
    0
  );

  return (
    <div>
      <Row style={{ marginBottom: 20 }}>
        <Col md={4}>
          <ChartCard ref={chartRef}>
            <div className="d-flex justify-content-between">
              <Title>Student applied</Title>
              <AddBtn onClick={() => props.setShow(true)}>+</AddBtn>
            </div>
            <Label style={{ margin: 0, padding: "16px 0 12px 0" }}>
              {props.achivedText}
            </Label>
            <div style={{ marginBottom: 16 }}>
              <ProgressBar animated label={""} now={props.progressData} />
            </div>
            <ChartContainer className="chart-container">
              <Bar data={props.dataStudent} options={props.optionsStudent} />
            </ChartContainer>
          </ChartCard>
        </Col>
        <Col md={4}>
          <ChartCard ref={chartRef}>
            <div
              style={{ borderBottom: "1px solid #ebebeb" }}
              className="d-flex justify-content-between"
            >
              <Title style={{ border: "none" }}>
                No. of Applications Submitted
              </Title>

              <div className="d-flex flex-row">
                <Form.Control
                  as="select"
                  style={{
                    marginRight: 5,
                    width: "max-content",
                    height: 36,
                    padding: 5,
                    borderRadius: 4,
                  }}
                  value={props.intakeYear || ""}
                  onChange={async (e: any) => {
                    props.setintakeYear(e.target.value);

                    const applicationByMonth = await getApplicationPerMonth({
                      intakeYear: e.target.value,
                      intakeMonth: props.intakeMonth,
                    });
                    props.setapplicationByMonth(applicationByMonth);
                  }}
                >
                  <option value="">Year</option>
                  {[...Array(10)].map((_, i) => {
                    const year = new Date().getFullYear() + i;
                    return (
                      <option key={year} value={year}>
                        {year}
                      </option>
                    );
                  })}
                </Form.Control>
                <Form.Control
                  as="select"
                  value={props.intakeMonth || ""}
                  onChange={async (e: any) => {
                    props.setintakeMonth(e.target.value);
                    const applicationByMonth = await getApplicationPerMonth({
                      intakeYear: props.intakeYear,
                      intakeMonth: e.target.value,
                    });

                    props.setapplicationByMonth(applicationByMonth);
                  }}
                  style={{
                    width: "max-content",
                    borderRadius: 4,
                    height: 36,
                    padding: 5,
                  }}
                >
                  <option value="">Month</option>
                  {[...Array(12)].map((_, i) => {
                    const month = new Date(0, i).toLocaleString("default", {
                      month: "long",
                    });
                    return (
                      <option key={month} value={month}>
                        {month}
                      </option>
                    );
                  })}
                </Form.Control>
              </div>
            </div>
            <Label style={{ margin: 0, padding: "16px 0 12px 0" }}>
              {`Achieved 0 of 1200 in ${new Date().toLocaleString("default", {
                month: "long",
              })}`}
            </Label>
            <div style={{ marginBottom: 16 }}>
              <ProgressBar />
            </div>
            <ChartContainer className="chart-container">
              <Bar data={data} options={props.optionsStudent} />
            </ChartContainer>
          </ChartCard>
        </Col>
        <Col md={4}>
          <ChartCard ref={chartRef}>
            <div
              style={{ borderBottom: "1px solid #ebebeb" }}
              className="d-flex justify-content-between"
            >
              <Title style={{ border: "none" }}>No. of Enrolled Students</Title>

              <div className="d-flex flex-row">
                <Form.Control
                  as="select"
                  style={{
                    marginRight: 5,
                    width: "max-content",
                    height: 36,
                    padding: 5,
                    borderRadius: 4,
                  }}
                  value={props.intakeYearEnrolled || ""}
                  onChange={async (e: any) => {
                    const selectedYear = e.target.value;
                    props.setintakeYearEnrolled(selectedYear);

                    const applicationByMonth =
                      await getEnrolledApplicationPerMonth({
                        intakeYear: selectedYear,
                        intakeMonth: props.intakeMonthEnrolled,
                      });
                    props.setenrolledApplicationByMonth(applicationByMonth);
                  }}
                >
                  <option value="">Year</option>
                  {[...Array(10)].map((_, i) => {
                    const year = new Date().getFullYear() + i;
                    return (
                      <option key={year} value={year}>
                        {year}
                      </option>
                    );
                  })}
                </Form.Control>

                <Form.Control
                  as="select"
                  value={props.intakeMonthEnrolled || ""}
                  onChange={async (e: any) => {
                    const selectedMonth = e.target.value;
                    props.setintakeMonthEnrolled(selectedMonth);

                    const applicationByMonth =
                      await getEnrolledApplicationPerMonth({
                        intakeYear: props.intakeYearEnrolled,
                        intakeMonth: selectedMonth,
                      });

                    props.setenrolledApplicationByMonth(applicationByMonth);
                  }}
                  style={{
                    width: "max-content",
                    borderRadius: 4,
                    height: 36,
                    padding: 5,
                  }}
                >
                  <option value="">Month</option>
                  {[...Array(12)].map((_, i) => {
                    const month = new Date(0, i).toLocaleString("default", {
                      month: "long",
                    });
                    return (
                      <option key={month} value={month}>
                        {month}
                      </option>
                    );
                  })}
                </Form.Control>
              </div>
            </div>
            <Label style={{ margin: 0, padding: "16px 0 12px 0" }}>
              {`Achieved 0 of 1200 in ${new Date().toLocaleString("default", {
                month: "long",
              })}`}
            </Label>
            <div style={{ marginBottom: 16 }}>
              <ProgressBar />
            </div>
            <ChartContainer className="chart-container">
              <Bar data={enrolledData} options={props.optionsStudent} />
            </ChartContainer>
          </ChartCard>
        </Col>
      </Row>
      <Row style={{ marginBottom: 20 }}>
        <Col md={4}>
          <Card>
            <SubSection>
              <SubTitle>Students Applied Nationality</SubTitle>
              <NationalityList>
                {props.dashboardDataStudent?.topNationalities.map(
                  (top: any, key: any) => (
                    <Nationality key={key}>
                      <Flag>{getCountryEmoji(top.countryCode)}</Flag>
                      <CountryName>{top._id}</CountryName>
                      <Change>{top.count}</Change>
                    </Nationality>
                  )
                )}
              </NationalityList>
            </SubSection>
            <SubTitle>Students Applied Intake</SubTitle>
            {props.dashboardDataStudent?.topIntake.map((top: any, key: any) => (
              <Nationality key={key}>
                <CountryName>{`${top._id.month} '${top._id.year}`}</CountryName>
                <Change>{top.count}</Change>
              </Nationality>
            ))}
          </Card>
        </Col>
        <Col md={4}>
          <Card>
            <SubSection>
              <SubTitle>Status</SubTitle>
              <StatusBar>
                {props.phaseCount
                  .filter((phase: any) => phase.count > 0)
                  .map((phase: any, index: any) => (
                    <StatusSegment
                      key={index}
                      color={phaseColors[phase.status]}
                      width={(phase.count / totalApplications) * 100}
                    />
                  ))}
              </StatusBar>
              <NationalityList>
                {props.phaseCount.map((a: any, key: any) => (
                  <Nationality style={{ marginBottom: 15 }} key={key}>
                    <ColorIndicator color={phaseColors[a.status]} />
                    <CountryName>{a.status}</CountryName>
                    <Change>{a.count}</Change>
                  </Nationality>
                ))}
              </NationalityList>
            </SubSection>
            <SubTitle>Top Universities Applications</SubTitle>
            {props.topUniversities?.slice(0, 3).map((a: any, key: any) => (
              <Nationality key={key}>
                <img
                  width="36px"
                  height="36px"
                  src={`https://storage.googleapis.com/uapply/${a._id.logoUrl}`}
                  alt="icon"
                />
                <CountryName>{a._id.name}</CountryName>
                <Change>{a.count}</Change>
              </Nationality>
            ))}
          </Card>
        </Col>
        <Col md={4}>
          <Card>
            <SubSection>
              <SubTitle>Top 10 Enrolled Universities</SubTitle>
              <NationalityList>
                {props.topUniversitiesEnrolled.map((a: any, key: any) => (
                  <Nationality key={key}>
                    <img
                      width="36px"
                      height="36px"
                      src={`https://storage.googleapis.com/uapply/${a.institute.logoUrl}`}
                      alt="icon"
                    />
                    <CountryName>{a.institute.name}</CountryName>
                    <Change>{a.count}</Change>
                  </Nationality>
                ))}
              </NationalityList>
            </SubSection>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col md={4}>
          <SalesComponent
            th={["Name", "N° A", "N° E", "C. Rate"]}
            header="Sales"
            icon={"💸"}
            data={props.studentsByStatus.filter((a: any) => a.role === "sales")}
            users={props.users}
            studentsTab={true}
          />
        </Col>
        <Col md={4}>
          <SalesComponent
            th={["Name", "N° A", "N° E", "C. Rate"]}
            header="Account Manager"
            icon={"🧑🏻‍💻"}
            data={props.studentsByStatus.filter(
              (a: any) => a.role === "account manager"
            )}
            users={props.users}
            studentsTab={true}
          />
        </Col>
        <Col md={4}>
          <SalesComponent
            th={["Name", "N° A", "N° E", "C. Rate"]}
            studentsTab={true}
            header="Operations"
            icon={"⚙️"}
            data={props.studentsByStatus.filter(
              (a: any) => a.role === "operations"
            )}
            users={props.users}
          />
        </Col>
      </Row>
      <br />
      <br />
      <br />
    </div>
  );
};

const ChartCard = styled.div`
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  display: flex;
  flex-direction: column;
  height: 496px;
`;

const ChartContainer = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ColorIndicator = styled.div<{ color: string }>`
  background: ${(props) => props.color};
  width: 10px;
  height: 10px;
  border-radius: 50%;
`;

const Title = styled.h2`
  font-size: 14px;
  font-style: normal;
  margin: 0;
  font-weight: 700;
  color: #303030;
  padding: 8px 0 16px 0;
  border-bottom: 1px solid #ebebeb;
`;

const Card = styled.div`
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
  border: 1px solid #ddd;
  display: flex;
  flex-direction: column;
  height: 100%;

  @media (max-width: 1024px) {
    max-width: 100%;
  }
`;

const SubSection = styled.div`
  // margin-top: 24px;
`;

const SubTitle = styled.h3`
  font-family: "Roboto";
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  color: #303030;
  margin-bottom: 24px;
  padding: 8px 0 16px 0;
  border-bottom: 1px solid #f2f6fb;
`;

const NationalityList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 10px 0 0;
`;

const Nationality = styled.li`
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
  align-items: center;
`;

const Flag = styled.span`
  font-size: 18px;
`;

const CountryName = styled.span`
  font-family: "Roboto";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  color: rgba(30, 36, 50, 0.8);
  margin-left: 10px;
  flex: 1;
`;

const StatusBar = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  width: 100%;
  border-bottom-right-radius: 48px;
`;

const StatusSegment = styled.div<{ color: string; width: number }>`
  background: ${(props) => props.color};
  width: ${(props) => props.width}%;
  height: 16px;

  &:first-child {
    border-top-left-radius: 48px;
    border-bottom-left-radius: 48px;
  }

  &:last-child {
    border-top-right-radius: 48px;
    border-bottom-right-radius: 48px;
  }
`;

const Change = styled.span`
  font-family: "Roboto";
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  text-align: right;
  color: #1e2432;
`;
