// #region Global Imports
import styled from "styled-components";
// #endregion Global Imports

export const LabelText = styled.span`
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: #1d2939;
`;

export const SubText = styled.span`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  text-transform: capitalize;
  font-size: 14px;
  line-height: 20px;
  color: #1d2939;
`;

export const UniversityCon = styled.ul`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  text-transform: capitalize;
  font-size: 14px;
  line-height: 20px;
  color: #1d2939;
`;
