import React from "react";

type Props = {};

export const CreateStudentIcon1 = (props: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="48"
      height="48"
      fill="none"
      viewBox="0 0 48 48"
    >
      <rect width="48" height="48" fill="#F9F5FF" rx="8"></rect>
      <path
        stroke="#9E77ED"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M24 26l9-5-9-5-9 5 9 5zm0 0l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0024 32.055a11.952 11.952 0 00-6.824-2.998 12.084 12.084 0 01.665-6.479L24 26zm-4 6v-7.5l4-2.222"
      ></path>
    </svg>
  );
};
