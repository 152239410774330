import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import { Label, Required } from "./DocumentPage/style";
import moment from "moment";

type Props = {
  noDate?: boolean;
  onMonthFromChild?: any;
  onYearFromChild?: any;
  date?: any;
  label: string;
  leavingDateFromChild?: any;
  joiningDateFromChild?: any;
  issueDateFromChild?: any;
  dateFromChild?: any;
  monthLabel: string;
  yearLabel: string;
};

export const DateComponent = (props: Props) => {
  const currentDate = props.date ? new Date(props.date) : null;

  const [myMonth, setMyMonth] = useState<Date | null>(currentDate || null);
  const [myYear, setMyYear] = useState<Date | null>(currentDate || null);
  const [myDay, setMyDay] = useState<Date | null>(currentDate || null);
  const [minDate, setMinDate] = useState<Date | null>(null);
  const [maxDate, setMaxDate] = useState<Date | null>(null);

  useEffect(() => {
    if (myYear && myMonth) {
      const newMinDate = new Date(myYear.getFullYear(), myMonth.getMonth(), 1);
      const newMaxDate = new Date(
        myYear.getFullYear(),
        myMonth.getMonth() + 1,
        0
      );
      setMinDate(newMinDate);
      setMaxDate(newMaxDate);
    }
  }, [myYear, myMonth]);

  const renderDayContents = (day: number, date?: Date) => {
    if (!date || !minDate || !maxDate || date < minDate || date > maxDate) {
      return <span className="text-muted"></span>;
    }
    return <span>{date.getDate()}</span>;
  };

  const handleYearChange = async (date: Date | null) => {
    if (date) {
      setMyYear(date);
      if (props.onYearFromChild) await props.onYearFromChild(date);
      if (myMonth) updateChildDates(date, myMonth, myDay);
    }
  };

  const handleMonthChange = async (date: Date | null) => {
    if (date) {
      setMyMonth(date);
      if (props.onMonthFromChild) await props.onMonthFromChild(date);
      if (myYear) updateChildDates(myYear, date, myDay);
    }
  };

  const handleDayChange = (date: Date | null) => {
    if (date) {
      setMyDay(date);
      if (myYear && myMonth) updateChildDates(myYear, myMonth, date);
    }
  };

  const updateChildDates = (year: Date, month: Date, day: Date | null) => {
    const dateString = `${moment(year).format("YYYY")}-${moment(month).format(
      "MM"
    )}-${day ? moment(day).format("DD") : "01"}`;
    if (props.leavingDateFromChild) props.leavingDateFromChild(dateString);
    if (props.joiningDateFromChild) props.joiningDateFromChild(dateString);
    if (props.dateFromChild) props.dateFromChild(dateString);
  };

  return (
    <div className="d-flex justify-content-between">
      <div style={{ paddingRight: 10 }} className="input-container">
        <div>
          {props.yearLabel ? <Required>*</Required> : ""}
          <Label>{props.yearLabel}</Label>
          <DatePicker
            selected={myYear}
            onChange={handleYearChange}
            showYearPicker
            placeholderText="Year"
            dateFormat="yyyy"
          />
        </div>
      </div>
      <div style={{ paddingRight: 10 }}>
        {props.monthLabel ? <Required>*</Required> : ""}
        <Label>{props.monthLabel}</Label>
        <DatePicker
          showMonthYearPicker
          dateFormat="MMMM"
          selected={myMonth}
          onChange={handleMonthChange}
          placeholderText="Month"
        />
      </div>
      {props.noDate ? null : (
        <div>
          <Label>{props.label}</Label>
          <DatePicker
            dateFormat="dd"
            selected={myDay}
            renderDayContents={renderDayContents}
            onChange={handleDayChange}
            placeholderText="Day"
            minDate={minDate}
            maxDate={maxDate}
          />
        </div>
      )}
    </div>
  );
};
