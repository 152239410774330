import * as React from "react";

interface IDocumentIconProps {
  background: any;
}

export const DocumentIcon: React.FunctionComponent<IDocumentIconProps> = (
  props
) => {
  return (
    <svg
      width={15}
      height={18}
      viewBox="0 0 15 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M14.493 6.75048L14.4935 7.25H14.5V14.25C14.5 16.0415 13.0415 17.5 11.25 17.5H3.75C1.95853 17.5 0.5 16.0415 0.5 14.25V3.75C0.5 1.95853 1.95853 0.5 3.75 0.5H7.75V0.506047H8.25C8.32649 0.506047 8.38631 0.532688 8.42617 0.572679L8.42674 0.573256L14.4267 6.57326C14.466 6.61249 14.4929 6.67239 14.493 6.75048ZM9.35355 2.20699L8.5 1.35344V2.56055V3.75C8.5 5.26688 9.73312 6.5 11.25 6.5H12.4395H13.6466L12.793 5.64645L9.35355 2.20699ZM8 1.5V1H7.5H3.75C2.23312 1 1 2.23312 1 3.75V14.25C1 15.7669 2.23312 17 3.75 17H11.25C12.7669 17 14 15.7669 14 14.25V7.5V7H13.5H11.25C9.45853 7 8 5.54147 8 3.75V1.5ZM10.75 13.5C10.75 13.6383 10.6382 13.75 10.5 13.75H4.5C4.36179 13.75 4.25 13.6383 4.25 13.5C4.25 13.3617 4.36179 13.25 4.5 13.25H10.5C10.6382 13.25 10.75 13.3617 10.75 13.5ZM10.75 10.5C10.75 10.6383 10.6382 10.75 10.5 10.75H4.5C4.36179 10.75 4.25 10.6383 4.25 10.5C4.25 10.3617 4.36179 10.25 4.5 10.25H10.5C10.6382 10.25 10.75 10.3617 10.75 10.5ZM4.25 7.5C4.25 7.36174 4.36179 7.25 4.5 7.25H6C6.13821 7.25 6.25 7.36174 6.25 7.5C6.25 7.63826 6.13821 7.75 6 7.75H4.5C4.36179 7.75 4.25 7.63826 4.25 7.5Z"
        fill={props.background}
        stroke={props.background}
      />
    </svg>
  );
};
