import * as React from "react";
import PaginationLeft from "../../Assets/Icons/PaginationLeft.svg";
import PaginationRight from "../../Assets/Icons/PaginationRight.svg";
import "./style.scss";

interface IPaginationComponentProps {
  onChangePrev: Function;
  totalPage: any;
  onPageClick: Function;
  page: number;
  onChange: Function;
}

export const PaginationComponent: React.FunctionComponent<
  IPaginationComponentProps
> = (props) => {
  const totalPage = new Array(props.totalPage);
  for (var i = 0; i < props.totalPage; i++) {
    totalPage[i] = (i + 1).toString();
  }

  return (
    <div className="w-100 d-flex justify-content-center">
      {/* <span className="next_container d-flex justify-content-center align-items-center" style={{marginRight:12.5}}>-</span> */}
      <img
        onClick={() => props.onChangePrev()}
        className="next_container"
        src={PaginationLeft}
        alt="icon"
        style={{ marginRight: 12.5, cursor: "pointer" }}
      />
      <div className="pagination_con d-flex justify-content-between align-items-center">
        {totalPage
          .filter((a: any) => a <= 10)
          .map((x: any, key: any) => (
            <span
              key={key}
              onClick={() => props.onPageClick(x)}
              className={
                // eslint-disable-next-line eqeqeq
                props.page == x || (props.page > 10 && x == 10)
                  ? "page_selected d-flex justify-content-center align-items-center"
                  : "pagination-page d-flex justify-content-center align-items-center"
              }
            >
              {props.page >= 10 && x >= 10 ? props.page : x}
            </span>
          ))}
      </div>

      <img
        onClick={() => {
          if (props.totalPage !== props.page) {
            props.onChange();
          }
        }}
        className="next_container"
        src={PaginationRight}
        alt="icon"
        style={{ marginLeft: 12.5, cursor: "pointer" }}
      />
      {/* <span className="next_container d-flex justify-content-center align-items-center" style={{marginLeft:12.5}}>+</span> */}
    </div>
  );
};
