import React from "react";

type Props = {};

export const CreateStudentIcon3 = (props: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="48"
      height="48"
      fill="none"
      viewBox="0 0 48 48"
    >
      <rect width="48" height="48" fill="#F9F5FF" rx="8"></rect>
      <path
        stroke="#9E77ED"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M24 16.354a4 4 0 110 5.292M27 33H15v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M25 19a4 4 0 11-8 0 4 4 0 018 0z"
      ></path>
    </svg>
  );
};
