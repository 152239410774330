import * as React from "react";

interface IStartIconProps {
  style?: object;
}

export const StarIcon: React.FunctionComponent<IStartIconProps> = (props) => {
  return (
    <svg
      width={13}
      height={12}
      viewBox="0 0 13 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10.4513 11.9658C9.98879 12.2851 7.0212 10.2612 6.45284 10.2568C5.88448 10.2524 2.88349 12.2297 2.42639 11.9033C1.96928 11.5768 3.04391 8.22379 2.87265 7.70004C2.7014 7.1763 -0.171872 5.02898 0.00811114 4.50796C0.188134 3.98694 3.81987 3.93855 4.28237 3.61927C4.74487 3.30003 5.97011 -0.00440549 6.53851 4.40988e-06C7.10683 0.00445266 8.27672 3.32757 8.73382 3.65401C9.19093 3.98042 12.8214 4.08549 12.9927 4.60924C13.164 5.13298 10.2553 7.2352 10.0753 7.75622C9.89527 8.27724 10.9138 11.6466 10.4513 11.9658Z"
        fill="url(#paint0_linear_261_22425)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_261_22425"
          x1={4.33744}
          y1={4.20379}
          x2={10.2637}
          y2={9.00821}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#00875A" />
          <stop offset={1} stopColor="#0CCB8B" />
        </linearGradient>
      </defs>
    </svg>
  );
};
