// #region Global Imports
import styled from "styled-components";
// #endregion Global Imports

export const DetailCard = styled.div`
  padding: 24px;
  background: #f9f5ff;
  margin-top: 12px;
`;

export const DetailText = styled.div`
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;

  color: #000000;
`;
export const DetailSubtext = styled.div`
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: #344563;
  margin: 12px 0;
`;

export const AddDocBtn = styled.a`
  width: 103px;
  text-decoration: none;
  height: 27px;
  background: #ffffff;
  border: 1px solid #b3d4ff;
  border-radius: 12px;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #0052cc;
  cursor: pointer;
`;

export const DocSection = styled.div`
  background: #fafbfc;
  border-bottom: 1px solid rgba(0, 0, 0, 0.16);
  padding: 9px 20px;
`;

export type SubtextProps = {
  fontWeight?: number;
  size?: string;
  color?: string;
};
export const Subtext = styled.span<SubtextProps>(
  ({ fontWeight, size, color }) => `
      font-family: "Open Sans";
      font-style: normal;
      font-weight: ${fontWeight};
      font-size: ${size ? size : "14px"};
      line-height: 19px;
      display: flex;
      align-items: center;
      color: ${color};
      `
);

export type MissingDocsProps = {
  color?: string;
};
export const MissingDocs = styled.div<MissingDocsProps>(
  ({ color }) => `
  color: ${color};
  position: absolute;
  top: -60px;
  right: 0;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0.75px;
      `
);
export const DocumentText = styled.span`
  font-family: "Open Sans";
  text-transform: capitalize;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #505f79;
`;

export const AnchorTag = styled.div`
  font-family: "Inter";
  font-style: normal;
  cursor: pointer;
  text-decoration: none;
  width: max-content;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #344054;
  margin-bottom: 10px;
  outline: none;
  border: none;
  &:hover {
    color: #7f56d9;
  }
`;
export type DocumentSubtextProps = {
  fontWeight?: number;
  size?: string;
};
export const DocumentSubtext = styled.span<DocumentSubtextProps>(
  ({ fontWeight, size }) => `
  font-family: "Open Sans";
  text-transform: capitalize;
  
  font-style: normal;
  font-weight: ${fontWeight};
  font-size: ${size ? size : "14px"};
  line-height: 19px;
  display: flex;
  align-items: center;
  color: #5e6c84;
  `
);
export const AttachmentCon = styled.div`
  border-top: 1px solid #ebecf0;
  border-bottom: 1px solid #ebecf0;
  margin-top: 12px;
  padding: 8px 0;
  width: 100%;
`;
export const MissingDocText = styled.div`
  color: #a5adba;
  text-align: right;
  margin-right: 24px;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;
