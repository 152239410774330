import * as React from "react";

interface IMailIconProps {}

export const MailIcon: React.FunctionComponent<IMailIconProps> = (props) => {
  return (
    <svg
      width={30}
      height={30}
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect width={30} height={30} rx={15} fill="#EBF3FF" />
      <path
        d="M21.375 21.375H8.625C8.22718 21.375 7.84564 21.217 7.56434 20.9357C7.28304 20.6544 7.125 20.2728 7.125 19.875V10.125C7.125 9.72718 7.28304 9.34564 7.56434 9.06434C7.84564 8.78304 8.22718 8.625 8.625 8.625H21.375C21.7728 8.625 22.1544 8.78304 22.4357 9.06434C22.717 9.34564 22.875 9.72718 22.875 10.125V19.875C22.875 20.2728 22.717 20.6544 22.4357 20.9357C22.1544 21.217 21.7728 21.375 21.375 21.375ZM8.625 9.375C8.42609 9.375 8.23532 9.45402 8.09467 9.59467C7.95402 9.73532 7.875 9.92609 7.875 10.125V19.875C7.875 20.0739 7.95402 20.2647 8.09467 20.4053C8.23532 20.546 8.42609 20.625 8.625 20.625H21.375C21.5739 20.625 21.7647 20.546 21.9053 20.4053C22.046 20.2647 22.125 20.0739 22.125 19.875V10.125C22.125 9.92609 22.046 9.73532 21.9053 9.59467C21.7647 9.45402 21.5739 9.375 21.375 9.375H8.625Z"
        fill="#0052CC"
      />
      <path
        d="M22.7288 10.7962L22.125 11.25L20.5613 12.4687L16.5713 15.585C16.1222 15.9347 15.5692 16.1246 15 16.1246C14.4308 16.1246 13.8779 15.9347 13.4288 15.585L7.27126 10.7962C7.19945 10.7333 7.15432 10.6454 7.14508 10.5504C7.13583 10.4554 7.16316 10.3605 7.2215 10.2849C7.27984 10.2094 7.36479 10.1589 7.45904 10.1438C7.5533 10.1287 7.64976 10.1501 7.72876 10.2037L13.875 15C14.1996 15.2434 14.5943 15.375 15 15.375C15.4057 15.375 15.8005 15.2434 16.125 15L20.8275 11.325L22.125 10.3162L22.2713 10.2037C22.3098 10.17 22.3548 10.1445 22.4035 10.1288C22.4523 10.1132 22.5037 10.1076 22.5546 10.1126C22.6056 10.1176 22.655 10.1329 22.6998 10.1577C22.7446 10.1825 22.7838 10.2161 22.8151 10.2567C22.8464 10.2972 22.8691 10.3437 22.8817 10.3933C22.8943 10.4429 22.8967 10.4946 22.8886 10.5451C22.8805 10.5957 22.8622 10.644 22.8347 10.6872C22.8072 10.7304 22.7711 10.7675 22.7288 10.7962Z"
        fill="#0052CC"
      />
      <path
        d="M7.85574 19.4998C7.78144 19.4996 7.70907 19.4786 7.64872 19.4399C7.58836 19.4011 7.54304 19.3465 7.51907 19.2836C7.49511 19.2207 7.49369 19.1527 7.51502 19.089C7.53635 19.0254 7.57937 18.9693 7.63806 18.9285L13.1442 15.0989C13.18 15.0653 13.2236 15.0391 13.2719 15.0221C13.3202 15.0051 13.3721 14.9978 13.4239 15.0006C13.4757 15.0034 13.5262 15.0162 13.5719 15.0383C13.6175 15.0603 13.6573 15.091 13.6884 15.1282C13.7194 15.1654 13.741 15.2082 13.7517 15.2536C13.7623 15.299 13.7618 15.346 13.7501 15.3912C13.7384 15.4364 13.7158 15.4788 13.6839 15.5154C13.6519 15.552 13.6115 15.5819 13.5653 15.6031L8.05914 19.4328C8.00075 19.4734 7.92967 19.4968 7.85574 19.4998ZM22.1297 19.4998C22.0508 19.4996 21.9743 19.476 21.912 19.4328L16.4201 15.6031C16.3798 15.5786 16.3455 15.5471 16.3193 15.5103C16.2932 15.4736 16.2757 15.4324 16.268 15.3894C16.2603 15.3464 16.2625 15.3024 16.2746 15.2602C16.2866 15.218 16.3081 15.1784 16.3379 15.1439C16.3677 15.1094 16.405 15.0808 16.4477 15.0597C16.4903 15.0386 16.5373 15.0255 16.5858 15.0213C16.6343 15.0171 16.6832 15.0218 16.7296 15.0351C16.776 15.0485 16.8188 15.0702 16.8554 15.0989L22.3616 18.9285C22.4214 18.9699 22.4649 19.0272 22.4859 19.0921C22.5069 19.157 22.5044 19.2263 22.4786 19.2898C22.4528 19.3533 22.4051 19.4079 22.3424 19.4456C22.2797 19.4834 22.2052 19.5023 22.1297 19.4998Z"
        fill="#0052CC"
      />
      <g
        style={{
          mixBlendMode: "multiply",
        }}
        opacity={0.5}
      >
        <path
          d="M20.3564 17.3082C19.8505 18.48 19.2494 19.4093 18.5876 20.0429C17.9258 20.6764 17.2164 21.0017 16.5 21H22.5V10.5L21.7418 11.865C21.4884 13.9225 21.0115 15.7962 20.3564 17.3082Z"
          fill="#4C9AFF"
        />
      </g>
    </svg>
  );
};
