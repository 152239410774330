import * as React from "react";

interface IInfoIconProps {
  style?: object;
}

export const InfoIcon: React.FunctionComponent<IInfoIconProps> = (props) => {
  return (
    <svg
      width={18}
      height={19}
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9 2C7.51664 2 6.0666 2.43987 4.83323 3.26398C3.59986 4.08809 2.63856 5.25943 2.07091 6.62987C1.50325 8.00032 1.35472 9.50832 1.64411 10.9632C1.9335 12.418 2.64781 13.7544 3.6967 14.8033C4.7456 15.8522 6.08197 16.5665 7.53683 16.8559C8.99168 17.1453 10.4997 16.9968 11.8701 16.4291C13.2406 15.8614 14.4119 14.9001 15.236 13.6668C16.0601 12.4334 16.5 10.9834 16.5 9.5C16.5 8.51509 16.306 7.53982 15.9291 6.62987C15.5522 5.71993 14.9997 4.89314 14.3033 4.1967C13.6069 3.50026 12.7801 2.94781 11.8701 2.5709C10.9602 2.19399 9.98492 2 9 2ZM9 15.5C7.81332 15.5 6.65328 15.1481 5.66658 14.4888C4.67989 13.8295 3.91085 12.8925 3.45673 11.7961C3.0026 10.6997 2.88378 9.49334 3.11529 8.32946C3.3468 7.16557 3.91825 6.09647 4.75736 5.25736C5.59648 4.41824 6.66558 3.8468 7.82946 3.61529C8.99335 3.38378 10.1997 3.5026 11.2961 3.95672C12.3925 4.41085 13.3295 5.17988 13.9888 6.16658C14.6481 7.15327 15 8.31331 15 9.5C15 11.0913 14.3679 12.6174 13.2426 13.7426C12.1174 14.8679 10.5913 15.5 9 15.5Z"
        fill="#97A0AF"
      />
      <path
        d="M9 7.25C9.41421 7.25 9.75 6.91421 9.75 6.5C9.75 6.08579 9.41421 5.75 9 5.75C8.58579 5.75 8.25 6.08579 8.25 6.5C8.25 6.91421 8.58579 7.25 9 7.25Z"
        fill="#97A0AF"
      />
      <path
        d="M9 8C8.80109 8 8.61032 8.07902 8.46967 8.21967C8.32902 8.36032 8.25 8.55109 8.25 8.75V12.5C8.25 12.6989 8.32902 12.8897 8.46967 13.0303C8.61032 13.171 8.80109 13.25 9 13.25C9.19891 13.25 9.38968 13.171 9.53033 13.0303C9.67098 12.8897 9.75 12.6989 9.75 12.5V8.75C9.75 8.55109 9.67098 8.36032 9.53033 8.21967C9.38968 8.07902 9.19891 8 9 8Z"
        fill="#97A0AF"
      />
    </svg>
  );
};
