// StatusCard.tsx
import React from "react";
import styled from "styled-components";

const Container = styled.div`
  background: #f7f7f7;
  border-radius: 8px;
  padding: 16px;
  width: 320px;
`;

const StatusBar = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;
`;

const StatusSegment = styled.div<{ color: string; width: number }>`
  background: ${(props) => props.color};
  width: ${(props) => props.width}px;
  height: 10px;
`;

const StatusLabel = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 0;
`;

const StatusItem = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 4px;
`;

const ColorIndicator = styled.div<{ color: string }>`
  background: ${(props) => props.color};
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-right: 8px;
`;

const TopUniversities = styled.div`
  margin-top: 16px;
`;

const UniversityItem = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
`;

const UniversityRank = styled.span`
  font-weight: bold;
  margin-right: 8px;
`;

const UniversityName = styled.span`
  margin-right: auto;
`;

const UniversityCount = styled.span`
  color: #888;
`;

const SeeMore = styled.a`
  color: #8e44ad;
  cursor: pointer;
  text-align: right;
  display: block;
  margin-top: 8px;
`;

export const StatusCard: React.FC = () => {
  return (
    <Container>
      <h3>Status</h3>
      <StatusBar>
        <StatusSegment color="#00b894" width={30} />
        <StatusSegment color="#fdcb6e" width={28} />
        <StatusSegment color="#d63031" width={26.5} />
        <StatusSegment color="#a29bfe" width={22} />
        <StatusSegment color="#0984e3" width={18} />
        <StatusSegment color="#fd79a8" width={17} />
        <StatusSegment color="#6c5ce7" width={16.5} />
        <StatusSegment color="#00b894" width={13.3} />
      </StatusBar>
      <StatusLabel>
        <ColorIndicator color="#00b894" />
        <span>Initiated</span>
        <span>300</span>
      </StatusLabel>
      <StatusItem>
        <ColorIndicator color="#fdcb6e" />
        <span>Submitted</span>
        <span>280</span>
      </StatusItem>
      <StatusItem>
        <ColorIndicator color="#d63031" />
        <span>Conditional Offer</span>
        <span>265</span>
      </StatusItem>
      <StatusItem>
        <ColorIndicator color="#a29bfe" />
        <span>Unconditional Offer</span>
        <span>220</span>
      </StatusItem>
      <StatusItem>
        <ColorIndicator color="#0984e3" />
        <span>Confirmation</span>
        <span>180</span>
      </StatusItem>
      <StatusItem>
        <ColorIndicator color="#fd79a8" />
        <span>FG/BS</span>
        <span>170</span>
      </StatusItem>
      <StatusItem>
        <ColorIndicator color="#6c5ce7" />
        <span>CAS Received</span>
        <span>165</span>
      </StatusItem>
      <StatusItem>
        <ColorIndicator color="#00b894" />
        <span>Done</span>
        <span>133</span>
      </StatusItem>

      <TopUniversities>
        <h4>Top Universities Applications</h4>
        <UniversityItem>
          <UniversityRank>01</UniversityRank>
          {/* <FaUniversity /> */}
          <UniversityName>University of Brighton</UniversityName>
          <UniversityCount>20</UniversityCount>
        </UniversityItem>
        <UniversityItem>
          <UniversityRank>02</UniversityRank>
          {/* <FaUniversity /> */}
          <UniversityName>University of Liverpool</UniversityName>
          <UniversityCount>11</UniversityCount>
        </UniversityItem>
        <UniversityItem>
          <UniversityRank>03</UniversityRank>
          {/* <FaUniversity /> */}
          <UniversityName>De Mont Fort University</UniversityName>
          <UniversityCount>8</UniversityCount>
        </UniversityItem>
        <UniversityItem>
          <UniversityRank>04</UniversityRank>
          {/* <FaUniversity /> */}
          <UniversityName>University of Salford</UniversityName>
          <UniversityCount>8</UniversityCount>
        </UniversityItem>
        <SeeMore>See More</SeeMore>
      </TopUniversities>
    </Container>
  );
};
