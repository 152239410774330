// #region Global Imports
import styled from "styled-components";
// #endregion Global Imports

export const Button = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 9px 17px;
  width: 135px;
  height: 38px;
  transition: all 0.3s linear;
  background: ${(props) =>
    props.color === "dark" ? ({ theme }) => theme.primaryColor : "#fff"};
  border: 1px solid
    ${(props) =>
      props.color === "dark" ? ({ theme }) => theme.primaryColor : "#D0D5DD"};
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: ${(props) =>
    props.color === "light" ? ({ theme }) => theme.text : "#fff"};
  &:hover {
    background: ${(props) =>
      props.color === "light" ? ({ theme }) => theme.primaryColor : "#fff"};
    color: ${(props) =>
      props.color === "dark" ? ({ theme }) => theme.text : "#fff"};
    border: 1px solid #d0d5dd;
  }
`;
