import styled from "styled-components";
// #endregion Global Imports

export const BadgeCon = styled.label`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 6px 12px;
  width: max-content;
  height: 31px;
  background: rgba(235, 236, 240, 0.6);
  border-radius: 8px;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 0.5px;
  color: #42526e;
`;
export const Required = styled.span`
  color: #ba1b23;
`;
export const NotAddedText = styled.span`
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #000000;
  padding: 16px 0;
`;
export const AddDocument = styled.a`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  text-decoration: none;
  font-size: 12px;
  line-height: 15px;
  color: #0065ff;
  display: flex;
  flex-direction: row;
  aign-items: center;
  cursor: pointer;
`;

export const BackBtn = styled.span`
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.75px;
  color: rgba(0, 0, 0, 0.42);
`;

export const AddBtn = styled.div`
  width: 18px;
  height: 18px;
  border-radius: 9px;
  background: #2684ff;
  color: #fff;
  display: flex;
  aign-items: center;
  justify-content: center;
  font-size: 16px;
  margin-right: 6px;
`;

export const Card = styled.a`
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-decoration: none;
  margin-top: 25px;
  padding-bottom: 16px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.16);
`;
export const ApplicationHeader = styled.a`
  font-family: "Open Sans";
  text-decoration: none;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  cursor: pointer;
  line-height: 25px;
  letter-spacing: 0.5px;
  color: #344563;
  width: 347px;
  margin-bottom: 12px;
`;

export type SubtextProps = {
  fontWeight?: number;
  size?: string;
  color?: string;
};
export const Subtext = styled.span<SubtextProps>(
  ({ fontWeight, size, color }) => `
    font-family: "Open Sans";
    font-style: normal;
    font-weight: ${fontWeight};
    font-size: ${size ? size : "14px"};
    line-height: 19px;
    display: flex;
    align-items: center;
    color: ${color};
    `
);

export const Pointer = styled.span`
  background: rgba(0, 0, 0, 0.24);
  width: 4px;
  height: 4px;
  border-radius: 2px;
  margin: 0 12px;
`;

export type ShowBtnProps = {
  color?: string;
  background?: string;
};
export const ShowBtn = styled.div<ShowBtnProps>(
  ({ color, background }) => `
    display: flex;
    cursor:pointer;
    flex-direction: row;
    align-items: center;
    padding: 2px 12px;
    width: max-content;
    height: 28px;
    background: ${background};
    border-radius: 20px;
    font-family: 'Open Sans';
    justify-content:center;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 200%;
    text-align: center;
    letter-spacing: 0.04em;
    color: ${color};
`
);
