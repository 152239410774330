import * as React from "react";
import { useSelector } from "react-redux";
import { Navigate, useLocation, useParams } from "react-router-dom";
import {
  ApplicationIcon,
  CallIcon,
  DocumentIcon,
  MailIcon,
  MenuIcon,
  MessageIcon,
  ShortlistIcon,
  UserIcon,
} from "../Assets";
import {
  AboutPage,
  ApplicationDetails,
  ApplicationPage,
  AssignRoleModal,
  DocumentPage,
  Loader,
  RoleRemovalModal,
  Shortlists,
} from "../Components";
import {
  getAmbassadors,
  getCourseLevel,
  getStudentById,
  getUsers,
  updateStudent,
} from "../Services/Function";
import {
  AssignText,
  Container,
  ContentBody,
  HeaderText,
  Link,
  LinkBorder,
  LinkText,
  Pointer,
  SideBar,
  UserLabelText,
  UserText,
} from "./styled/studentPage";
import { Dropdown } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

interface IStudentPageProps {}

export const StudentPage: React.FunctionComponent<IStudentPageProps> = (
  props
) => {
  let { id } = useParams();
  const location = useLocation();
  const auth = useSelector((state: any) => state.auth);
  var authToken = localStorage.getItem("authToken");
  const [student, setStudent] = React.useState<any>({});
  const [courseLevels, setCourseLevels] = React.useState([]);
  const [users, setUsers] = React.useState([]);
  const [modalShow, setModalShow] = React.useState(false);
  const [selectedUser, setSelectedUser] = React.useState<any>({});
  const [roleSelectionModalShow, setRoleSelectionModalShow] =
    React.useState(false);
  const [rolesToRemove, setRolesToRemove] = React.useState<any[]>([]);
  const [ambassadors, setambassadors] = React.useState([]);
  const [selectedAmbassador, setselectedAmbassador] = React.useState({});

  React.useEffect(() => {
    async function fetchData() {
      try {
        const student = await getStudentById(id);
        const user = await getUsers();
        const ambsd = await getAmbassadors();

        setambassadors(ambsd.results);
        const cl = await getCourseLevel({
          limit: 10,
          page: 1,
        });
        setUsers(user.results);
        setCourseLevels(cl.results);
        setStudent(student);
      } catch (error) {
        alert(error);
      }
    }
    fetchData();
  }, [id]);

  const navigate = useNavigate();

  const reloadStudents = async () => {
    const student = await getStudentById(id);
    setStudent(student);
  };

  if (!auth.isLoggedIn && !authToken) {
    return <Navigate to="/" />;
  }

  return (
    <div>
      {Object.keys(student).length ? (
        <>
          <Container>
            <div className="d-flex justify-content-between">
              <div className="d-flex flex-row align-items-center">
                <HeaderText>
                  {student.firstName} {student.middleName} {student.lastName}
                </HeaderText>
                <Pointer />
                <a
                  target="_blank"
                  href={`https://wa.me/${student.phoneNo}?text=Hello%20${student.firstName}%20I%20would%20like%20more%20information%20`}
                  rel="noreferrer"
                >
                  <MessageIcon style={{ marginRight: 8 }} />
                </a>
                <CallIcon style={{ marginRight: 8 }} />
                <a
                  target="_blank"
                  href={`mailto:${student.email}`}
                  rel="noreferrer"
                >
                  <MailIcon />
                </a>
              </div>
              <div className="d-flex flex-row align-items-center">
                <Dropdown>
                  <Dropdown.Toggle
                    className="dd-custom-toggle"
                    id="dropdown-custom-components"
                  >
                    <AssignText>+ Assign</AssignText>
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="dd-custom-menu">
                    {users.map((a: any) => {
                      const assignedUser = student.assignedTo.find(
                        (user: any) => user.user === a.id
                      );
                      const assigned = assignedUser !== undefined;
                      return (
                        <Dropdown.Item
                          key={a.id}
                          style={{
                            background: assigned ? "#7F56D9" : "#fff",
                            color: assigned
                              ? "#fff !important"
                              : "#000 !important",
                          }}
                          className="dd-custom-items"
                          onClick={async () => {
                            if (assigned) {
                              let item = student.assignedTo.find(
                                (user: any) => user.user === a.id
                              );
                              if (student.assignedTo.length > 1) {
                                // Prepare roles to remove and show modal
                                setRolesToRemove(
                                  student.assignedTo.filter(
                                    (user: any) => user.user === a.id
                                  )
                                );
                                setRoleSelectionModalShow(true);
                              } else {
                                // Remove the assignment directly
                                const removeAssign = student.assignedTo.filter(
                                  (user: any) => user !== item
                                );
                                await updateStudent(id, {
                                  assignedTo: removeAssign,
                                });
                                reloadStudents();
                              }
                            } else {
                              setSelectedUser(a);
                              setModalShow(true);
                            }
                          }}
                        >
                          {a.name}{" "}
                          {assigned
                            ? `(${
                                student.assignedTo.filter(
                                  (user: any) => user.user === a.id
                                ).length
                              } roles)`
                            : ""}
                        </Dropdown.Item>
                      );
                    })}
                  </Dropdown.Menu>
                </Dropdown>

                <MenuIcon style={{ marginLeft: 16 }} />
              </div>
            </div>
            <div className="d-flex flex-row align-items-center">
              <UserLabelText>Ref. No:&nbsp; </UserLabelText>{" "}
              <UserText>{student.refrenceNo}</UserText>
              <Pointer />
              <UserLabelText>Stage:&nbsp; </UserLabelText>{" "}
              <UserText>{student.stage}</UserText>
              <Pointer />
              <UserLabelText>Ambassador:&nbsp; </UserLabelText>
              <Dropdown>
                <Dropdown.Toggle
                  className="dd-custom-toggle"
                  id="dropdown-custom-components"
                >
                  {student.ambassadorName ? student.ambassadorName : "Select"}
                </Dropdown.Toggle>
                <Dropdown.Menu className="dd-custom-menu">
                  {ambassadors.map((a: any) => {
                    return (
                      <Dropdown.Item
                        key={a.id}
                        className="dd-custom-items"
                        onClick={async () => {
                          setselectedAmbassador(a);
                          await updateStudent(id, {
                            ambassadorName: a.name,
                            ambassador: a.id,
                          });
                          reloadStudents();
                        }}
                      >
                        <UserText>{a.name}</UserText>
                      </Dropdown.Item>
                    );
                  })}
                </Dropdown.Menu>
              </Dropdown>{" "}
              <Pointer />
              <UserLabelText>Source:&nbsp; </UserLabelText>{" "}
              <UserText>{student.source}</UserText>
            </div>
          </Container>

          <div>
            <SideBar>
              <Link
                onClick={() => navigate(`/student/about/${id}`)}
                color={
                  location.pathname.includes("/about") ? "#F9F5FF" : "#fff"
                }
              >
                {location.pathname.includes("/about") ? <LinkBorder /> : null}
                <UserIcon
                  background={
                    location.pathname.includes("/about") ? "#7f56d9" : "#1D2939"
                  }
                />
                <LinkText
                  color={
                    location.pathname.includes("/about") ? "#7f56d9" : "#1D2939"
                  }
                >
                  About
                </LinkText>
              </Link>

              <Link
                onClick={() => navigate(`/student/documents/${id}`)}
                color={
                  location.pathname.includes("/documents") ? "#F9F5FF" : "#fff"
                }
              >
                {location.pathname.includes("/documents") ? (
                  <LinkBorder />
                ) : null}
                <DocumentIcon
                  background={
                    location.pathname.includes("/documents")
                      ? "#7f56d9"
                      : "#1D2939"
                  }
                />
                <LinkText
                  color={
                    location.pathname.includes("/documents")
                      ? "#7f56d9"
                      : "#1D2939"
                  }
                >
                  Documents
                </LinkText>
              </Link>
              <Link
                onClick={() => navigate(`/student/shortlists/${id}`)}
                color={
                  location.pathname.includes("/shortlists") ? "#F9F5FF" : "#fff"
                }
              >
                {location.pathname.includes("/shortlists") ? (
                  <LinkBorder />
                ) : null}

                <ShortlistIcon
                  background={
                    location.pathname.includes("/shortlists")
                      ? "#7f56d9"
                      : "#1D2939"
                  }
                />
                <LinkText
                  color={
                    location.pathname.includes("/shortlists")
                      ? "#7f56d9"
                      : "#1D2939"
                  }
                >
                  Shortlists
                </LinkText>
              </Link>
              <Link
                onClick={() => navigate(`/student/applications/${id}`)}
                color={
                  location.pathname.includes("/applications") ||
                  location.pathname.includes("/applicationDetails")
                    ? "#F9F5FF"
                    : "#fff"
                }
              >
                {location.pathname.includes("/applications") ||
                location.pathname.includes("/applicationDetails") ? (
                  <LinkBorder />
                ) : null}
                <ApplicationIcon
                  background={
                    location.pathname.includes("/applications") ||
                    location.pathname.includes("/applicationDetails")
                      ? "#7f56d9"
                      : "#1D2939"
                  }
                />
                <LinkText
                  color={
                    location.pathname.includes("/applications")
                      ? "#7f56d9"
                      : "#1D2939"
                  }
                >
                  Applications
                </LinkText>
              </Link>
            </SideBar>
            {Object.keys(student).length ? (
              <ContentBody>
                {location.pathname.includes("/about") ? (
                  <AboutPage
                    student={student}
                    courseLevels={courseLevels}
                    reloadStudents={() => reloadStudents()}
                  />
                ) : null}
                {location.pathname.includes("/documents") ? (
                  <DocumentPage courseLevels={courseLevels} />
                ) : null}
                {location.pathname.includes("/applications") ? (
                  <ApplicationPage
                    student={student}
                    reloadStudents={() => reloadStudents()}
                  />
                ) : null}

                {location.pathname.includes("/shortlists") ? (
                  <Shortlists student={student} />
                ) : null}
                {location.pathname.includes("/applicationDetails") ? (
                  <ApplicationDetails
                    users={users}
                    student={student}
                    reloadStudents={() => reloadStudents()}
                  />
                ) : null}
              </ContentBody>
            ) : (
              <div className="d-flex justify-content-center align-items-center">
                Loading...
              </div>
            )}
          </div>
        </>
      ) : (
        <div
          className="d-flex align-items-center justify-content-center"
          style={{ width: "100%", height: "90vh" }}
        >
          <Loader width="200px" height="200px" />
        </div>
      )}
      {modalShow && (
        <AssignRoleModal
          student={student}
          id={id}
          selectedUser={selectedUser}
          onClick={async () => {}}
          onHide={() => setModalShow(false)}
          show={modalShow}
          reloadStudents={reloadStudents}
        />
      )}
      {roleSelectionModalShow && (
        <RoleRemovalModal
          roles={rolesToRemove}
          onSelectRole={async (roleToRemove: any) => {
            const removeAssign = student.assignedTo.filter(
              (role: any) => role !== roleToRemove
            );
            await updateStudent(id, { assignedTo: removeAssign });
            reloadStudents();
            setRoleSelectionModalShow(false);
          }}
          onClose={() => setRoleSelectionModalShow(false)}
        />
      )}
    </div>
  );
};
