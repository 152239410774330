import * as React from "react";

interface IUlearnIconProps {
  style?: object;
}

export const UlearnIcon: React.FunctionComponent<IUlearnIconProps> = (
  props
) => {
  return (
    <svg
      style={props.style}
      xmlns="http://www.w3.org/2000/svg"
      width="28"
      height="28"
      fill="none"
      viewBox="0 0 28 28"
    >
      <rect
        width="28"
        height="28"
        fill="#299CF7"
        rx="14"
        transform="matrix(-1 0 0 1 28 0)"
      ></rect>
      <mask
        id="mask0_2238_59655"
        style={{ maskType: "alpha" }}
        width="28"
        height="28"
        x="0"
        y="0"
        maskUnits="userSpaceOnUse"
      >
        <rect
          width="28"
          height="28"
          fill="#1E2432"
          rx="14"
          transform="matrix(-1 0 0 1 28 0)"
        ></rect>
      </mask>
      <g mask="url(#mask0_2238_59655)">
        <path
          fill="#299CF7"
          fillRule="evenodd"
          d="M1.524 47.182C-6.352 57.494-3.562 70.44 6.31 68.752c.13-.017.263-.049.38-.064 12.542-2.455 10.065 18.504 18.566 5.662 8.5-12.843 9.83-.45 16.417-13.097 6.59-12.66 16.245-20.577 5.788-33.713-9.526-11.961-2.37-22.8-13.547-18.882-2.084.744-4.15 1.257-6.173 1.531-3.343.436-9.692 3.191-14.865 16.6-2.74 7.193-6.61 14.145-11.352 20.393z"
          clipRule="evenodd"
        ></path>
        <path
          fill="#299CF7"
          fillRule="evenodd"
          d="M-5.644 54.612c.303-4.272-1.864-1.237 1.762-4.698 2.655-2.51-4.003-14.184.465-14.712 5.814-.683 13.011.776 17.896-1.817 2.61-1.382 3.982-3.67 3.677-6.136-.79-6.439-9.287-9.087-16.998-10.13-9.049-1.235-18.797-1.165-25.395-7.135-3.903-3.527-5.377-8.824-10.83-11.525C-43.05-5.507-49.8.832-52.778 5.05c-2.681 3.826-5.102 7.644-6.696 11.854-.842 2.186.913 4.209 1.76 6.528 2.07 5.7 8.047 6.21 13.734 9.166 2.988 1.568 5.21 3.798 6.266 6.286 2.021 4.537 1.631 9.279 1.663 13.83.022 4.343 18.163 11.984 25.412 10.614 3.315-.623 13.143 26.14 9.838 9.683 5.417 11.65 3.19-9.036-4.842-18.399z"
          clipRule="evenodd"
        ></path>
        <path
          fill="#fff"
          d="M17.776 7.4l.057.058a1.497 1.497 0 002.117 0 1.495 1.495 0 000-2.116l-.057-.056A1.495 1.495 0 1017.776 7.4zM7.858 7.904h2.55L9.133 4.848 7.858 7.904zM7.605 10.281l.011 6.618c0 1.758.612 3.248 1.836 4.47 1.238 1.223 2.752 1.834 4.541 1.834 1.805 0 3.32-.611 4.542-1.834 1.24-1.222 1.858-2.712 1.858-4.47l-.01-6.618h-3.051V16.9c0 .978-.303 1.666-.93 2.338-.627.658-1.43.986-2.409.986-.978 0-1.781-.328-2.408-.986-.612-.672-.929-1.345-.929-2.338v-6.618h-3.05z"
        ></path>
      </g>
    </svg>
  );
};
