/* eslint-disable array-callback-return */
import * as React from "react";
import { useNavigate } from "react-router-dom";
import { DotIcon } from "../../Assets";
import { deleteUser, forgotPassword, getRoles } from "../../Services/Function";
// import { DetailModal } from "../DetailModal";
import { Loader } from "../Loader/Loader";
import Dropdown from "react-bootstrap/Dropdown";
import "./style.scss";
import {
  Table,
  TBody,
  Td,
  TdEmail,
  TdText,
  Th,
  THead,
  Tr,
  UserSection,
  TdAnchor,
} from "./styled";
import { DeleteModal } from "../DeleteModal";
import { useSelector } from "react-redux";

// import { Form, FormCheck } from "react-bootstrap";

interface IUsersViewProps {
  users?: any;
  reloadUsers?: any;
  page: number;
}

export const UsersView: React.FunctionComponent<IUsersViewProps> = (props) => {
  const navigate = useNavigate();
  const auth = useSelector((state: any) => state.auth);
  const [loading, setloading] = React.useState(false);
  const [deleteModal, setdeleteModal] = React.useState(false);
  const [selectedUsers, setselectedUsers] = React.useState<any>({});
  const [roles, setroles] = React.useState([]);

  // const { selectedStudentArr, setselectedStudentArr } = props;

  React.useEffect(() => {
    async function fetchData() {
      const res: any = await getRoles();
      setroles(res.results);
    }
    fetchData();
  }, []);

  return (
    <div style={{ height: "100vh" }} className="table-responsive">
      <Table className="table table-scroll ">
        <THead>
          <Tr>
            {/* <Th scope="col">Select</Th> */}
            <Th scope="col">Name</Th>
            <Th scope="col">Email</Th>
            <Th scope="col">Role</Th>
            <Th></Th>
          </Tr>
        </THead>
        <React.Suspense fallback={<Loader />}>
          {loading ? (
            <div
              style={{ height: "60vh", position: "absolute" }}
              className="d-flex align-items-center w-100 justify-content-center"
            >
              <Loader width="200px" height="200px" />
            </div>
          ) : (
            <TBody>
              {props.users?.map((z: any, key: any) => {
                const filteredRoles: any = roles.filter(
                  (a: any) => z.role === a.id
                );
                return (
                  <Tr key={key}>
                    <Td>
                      <UserSection>
                        {/* <div>
                        <AvatarCon src={z.profilePhotoUrl} alt="icon" />
                      </div> */}
                        <div
                          className="d-flex flex-column"
                          // style={{ marginLeft: 16 }}
                        >
                          <TdText
                            style={{ cursor: "pointer" }}
                            onClick={() => navigate(`/student/about/${z.id}`)}
                          >
                            {`${z.name} ` || "N/A"}
                          </TdText>
                        </div>
                      </UserSection>
                    </Td>
                    <Td>
                      <TdEmail>{z.email}</TdEmail>
                    </Td>

                    <Td>
                      <div className="d-flex flex-column">
                        <TdAnchor onClick={async () => {}}>
                          {filteredRoles[0]?.name}
                        </TdAnchor>
                      </div>
                    </Td>

                    <Td
                      style={{ position: "relative" }}
                      // onClick={() => setshowMenu(showMenu ? !z.id : z.id)}
                    >
                      <Dropdown>
                        <Dropdown.Toggle
                          className="dd-custom-toggle"
                          id="dropdown-custom-components"
                        >
                          <DotIcon style={{ cursor: "pointer" }} />
                        </Dropdown.Toggle>

                        {/* {showMenu === z.id ? ( */}
                        <Dropdown.Menu className="dd-custom-menu">
                          {auth.userDetails.name === z.name ? (
                            <Dropdown.Item className="dd-custom-items">
                              Can't Delete
                            </Dropdown.Item>
                          ) : (
                            <Dropdown.Item
                              className="dd-custom-items"
                              onClick={async () => {
                                setdeleteModal(true);
                                setselectedUsers(z);
                              }}
                            >
                              Delete
                            </Dropdown.Item>
                          )}
                          <Dropdown.Item
                            className="dd-custom-items"
                            onClick={async () => {
                              await forgotPassword(z.email);
                              alert(`Reset email sent to ${z.email}`);
                            }}
                          >
                            Forgot Password
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </Td>
                  </Tr>
                );
              })}
            </TBody>
          )}
        </React.Suspense>
      </Table>

      <DeleteModal
        onClick={async () => {
          try {
            setloading(true);
            await deleteUser(selectedUsers.id);
            props.reloadUsers();
            setloading(false);
            setselectedUsers({});
            setdeleteModal(false);
          } catch (error: any) {
            alert(error);
            setselectedUsers({});
            setloading(false);
          }
        }}
        onHide={() => setdeleteModal(false)}
        show={deleteModal}
      />
    </div>
  );
};
