import * as React from "react";
import Dropdown from "react-bootstrap/Dropdown";
import "./style.scss";

interface IDropdownComponentProps {
  children?: any;
  onClick?: any;
  className?: string;
  style?: object;
  placeholder: string;
  selected?: string;
  strArr?: boolean;
  displayName?: any;
}

export const DropdownComponent: React.FunctionComponent<
  IDropdownComponentProps
> = (props) => {
  return (
    <Dropdown style={props.style}>
      <Dropdown.Toggle id="dropdown-custom-components">
        {props.selected ? props.selected : props.placeholder}
      </Dropdown.Toggle>

      <Dropdown.Menu>
        {props.children.map((a: any, key: any) => (
          <Dropdown.Item
            key={key}
            onClick={() => props.onClick(a)}
            eventKey="1"
          >
            {props.strArr ? a : props.displayName ? a.name : a.en_name}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};
