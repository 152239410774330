import React from "react";

type Props = { selected: any; style: any };

export const DashboardTab = (props: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      fill="none"
      style={props.style}
      viewBox="0 0 30 30"
    >
      {props.selected ? (
        <>
          <path
            fill="#5C51E1"
            fillRule="evenodd"
            d="M15 30c8.284 0 15-6.716 15-15 0-8.284-6.716-15-15-15C6.716 0 0 6.716 0 15c0 8.284 6.716 15 15 15z"
            clipRule="evenodd"
            opacity="0.21"
          ></path>
          <g fill="#7F56D9" clipPath="url(#clip0_4559_61782)">
            <path d="M13.577 9.754h-3.454a.373.373 0 00-.373.373v3.454c0 .206.167.373.373.373h3.454a.373.373 0 00.373-.373v-3.454a.373.373 0 00-.373-.373zM13.577 15.979h-3.454a.373.373 0 00-.373.372v3.455c0 .206.167.372.373.372h3.454a.373.373 0 00.373-.372V16.35a.373.373 0 00-.373-.372z"></path>
            <path
              fillRule="evenodd"
              d="M19.5 15.977h-3.75a.75.75 0 100 1.5h3.75a.75.75 0 000-1.5zM19.5 12.375h-3.75a.75.75 0 100 1.5h3.75a.75.75 0 100-1.5zM15.75 11.25h3.75a.75.75 0 100-1.5h-3.75a.75.75 0 100 1.5zM19.5 18.75h-3.75a.75.75 0 100 1.5h3.75a.75.75 0 100-1.5z"
              clipRule="evenodd"
            ></path>
          </g>
          <defs>
            <clipPath id="clip0_4559_61782">
              <path
                fill="#fff"
                d="M0 0H10.5V10.5H0z"
                transform="translate(9.75 9.75)"
              ></path>
            </clipPath>
          </defs>
        </>
      ) : (
        <>
          <g fill="#1E2432" clipPath="url(#clip0_4559_61782)">
            <path d="M13.577 9.754h-3.454a.373.373 0 00-.373.373v3.454c0 .206.167.373.373.373h3.454a.373.373 0 00.373-.373v-3.454a.373.373 0 00-.373-.373zM13.577 15.979h-3.454a.373.373 0 00-.373.372v3.455c0 .206.167.372.373.372h3.454a.373.373 0 00.373-.372V16.35a.373.373 0 00-.373-.372z"></path>
            <path
              fillRule="evenodd"
              d="M19.5 15.977h-3.75a.75.75 0 100 1.5h3.75a.75.75 0 000-1.5zM19.5 12.375h-3.75a.75.75 0 100 1.5h3.75a.75.75 0 100-1.5zM15.75 11.25h3.75a.75.75 0 100-1.5h-3.75a.75.75 0 100 1.5zM19.5 18.75h-3.75a.75.75 0 100 1.5h3.75a.75.75 0 100-1.5z"
              clipRule="evenodd"
            ></path>
          </g>
        </>
      )}
    </svg>
  );
};
