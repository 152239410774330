// utilities/uploadUtils.ts

export const saveUploadState = (fileName: string, progress: number) => {
  localStorage.setItem(fileName, JSON.stringify({ progress }));
};

export const getUploadState = (fileName: string) => {
  const state = localStorage.getItem(fileName);
  return state ? JSON.parse(state) : null;
};

export const clearUploadState = (fileName: string) => {
  localStorage.removeItem(fileName);
};
