import { LOGGED_IN, USER_DETAILS } from "./types";
import { ApiV1, LOGIN } from "../../API";
import { Dispatch } from "redux";

export const login = (email: string, password: string) => {
  return async (dispatch: Dispatch) => {
    try {
      const res = await ApiV1.post(LOGIN, {
        email,
        password,
      });

      localStorage.setItem("authToken", res.data.tokens.access.token);
      dispatch({
        type: LOGGED_IN,
        payload: true,
      });
      dispatch({
        type: USER_DETAILS,
        payload: res.data.user,
      });
    } catch (error: any) {
      console.log(error);
      alert(`ERROR: ${error.response?.data.message}`);
      return error.response;
    }
  };
};

export const logout = () => {
  return (dispatch: Dispatch) => {
    // localStorage.setItem("")
    localStorage.removeItem("authToken");
    localStorage.clear();

    dispatch({
      type: LOGGED_IN,
      payload: false,
    });
  };
};
