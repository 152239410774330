import React from "react";

type Props = { selected: any; style: any };

export const DashboardTab1 = (props: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      fill="none"
      style={props.style}
      viewBox="0 0 30 30"
    >
      {props.selected ? (
        <>
          <path
            fill="#5C51E1"
            fillRule="evenodd"
            d="M15 30c8.284 0 15-6.716 15-15 0-8.284-6.716-15-15-15C6.716 0 0 6.716 0 15c0 8.284 6.716 15 15 15z"
            clipRule="evenodd"
            opacity="0.21"
          ></path>
          <path
            fill={"#7F56D9"}
            fillOpacity="0.8"
            fillRule="evenodd"
            d="M10.332 11.667a2.667 2.667 0 105.333 0 2.667 2.667 0 00-5.333 0zM17 14.332a2 2 0 104 0 2 2 0 00-4 0z"
            clipRule="evenodd"
            opacity="0.588"
          ></path>
          <path
            fill={"#7F56D9"}
            fillOpacity="0.8"
            fillRule="evenodd"
            d="M12.985 15.668c-3.148 0-5.73 1.618-5.988 4.8-.015.173.317.533.484.533h11.013c.5 0 .509-.403.5-.533-.195-3.271-2.817-4.8-6.009-4.8zm9.655 5.336h-2.576V21c0-1.5-.495-2.883-1.33-3.997 2.272.024 4.128 1.172 4.268 3.6.005.098 0 .4-.362.4z"
            clipRule="evenodd"
          ></path>
          <defs>
            <clipPath id="clip0_4559_61782">
              <path
                fill="#fff"
                d="M0 0H10.5V10.5H0z"
                transform="translate(9.75 9.75)"
              ></path>
            </clipPath>
          </defs>
        </>
      ) : (
        <>
          <path
            fill="#1E2432"
            fillOpacity="0.8"
            fillRule="evenodd"
            d="M10.332 11.667a2.667 2.667 0 105.333 0 2.667 2.667 0 00-5.333 0zM17 14.332a2 2 0 104 0 2 2 0 00-4 0z"
            clipRule="evenodd"
            opacity="0.588"
          ></path>
          <path
            fill="#1E2432"
            fillOpacity="0.8"
            fillRule="evenodd"
            d="M12.985 15.668c-3.148 0-5.73 1.618-5.988 4.8-.015.173.317.533.484.533h11.013c.5 0 .509-.403.5-.533-.195-3.271-2.817-4.8-6.009-4.8zm9.655 5.336h-2.576V21c0-1.5-.495-2.883-1.33-3.997 2.272.024 4.128 1.172 4.268 3.6.005.098 0 .4-.362.4z"
            clipRule="evenodd"
          ></path>
        </>
      )}
    </svg>
  );
};
