/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";

import { ApplicatinCard } from "./ApplicationCard";
import { AddBtn, AddDocument, NotAddedText } from "./styled";
import { getApplicationsById } from "../../Services/Function";
import { useParams } from "react-router-dom";
import { Loader } from "../Loader/Loader";

interface IApplicationPageProps {
  student: any;
  reloadStudents: any;
}

export const ApplicationPage: React.FunctionComponent<IApplicationPageProps> = (
  props
) => {
  const [noApplication, setnoApplication] = React.useState(false);
  const [applications, setapplications] = React.useState([]);
  const [loading, setloading] = React.useState(false);

  const { id } = useParams<{ id: string }>();

  React.useEffect(() => {
    async function fetchData() {
      setloading(true);
      const res = await getApplicationsById(id);
      setapplications(res.data);

      setnoApplication(!res.data.length ? true : false);
      setloading(false);
    }
    fetchData();
  }, []);

  const reloadApplication = async () => {
    setloading(true);
    const res = await getApplicationsById(id);
    setapplications(res.data);

    setnoApplication(!res.data.length ? true : false);
    setloading(false);
  };
  return (
    <div style={{ height: "100%", position: "relative" }}>
      <div
        className="d-flex justify-content-between"
        style={{ marginBottom: 16 }}
      >
        <>Applications</>
        {/* <div className="d-flex flex-row">
          <ButtonComponent
            style={{ borderRadius: 20, width: "max-content" }}
            btnType="dark"
            loading={false}
            label="SAVE"
          />
          <ButtonComponent
            style={{ borderRadius: 20, width: "max-content", marginLeft: 12 }}
            btnType="light"
            loading={false}
            label="EXIT"
          />
        </div> */}
      </div>

      {loading ? (
        <Loader />
      ) : (
        <div>
          {noApplication ? (
            <div className="d-flex justify-content-center align-items-center flex-column h-100">
              <div
                style={{
                  backgroundImage: `url(${"https://storage.googleapis.com/uapply/no-document.png"})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  width: 230,
                  height: 205,
                  borderRadius: 8,
                }}
              />
              <NotAddedText>Applications aren’t added yet</NotAddedText>
              <AddDocument href="/courses">
                <AddBtn>+</AddBtn> Start applying for courses
              </AddDocument>
            </div>
          ) : null}

          {applications.map((a: any, key: any) => (
            <ApplicatinCard
              key={key}
              reloadApplication={reloadApplication}
              applications={a}
              student={props.student}
              reloadStudents={props.reloadStudents}
            />
          ))}
        </div>
      )}
    </div>
  );
};
