import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { ButtonComponent } from "../ButtonComponent";
import { Text } from "./styled";
import { ToggleButton } from "../ToggleButton";
import { Form, Formik, FormikValues } from "formik";
import axios from "axios";
import { TemplateMessage } from "../TemplateMessage";
import QueryString from "qs";
import { TextArea } from "../TextArea";

type Props = {
  show: boolean;
  key: any;
  selectedStudentArr: any;
  handleClose: any;
  reload: Function;
};

export const SendWA = (props: Props) => {
  const [templateMsg, settemplateMsg] = useState(false);
  const [loading, setloading] = useState(false);
  const [messageLog, setmessageLog] = useState<any>([]);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [btnType, setbtnType] = useState<string[]>([]);

  const { show, selectedStudentArr, reload } = props;

  const addCountryCode = (phoneNumber: any) => {
    return `965${phoneNumber}`;
  };

  return (
    <div>
      <Modal
        show={props.show}
        onHide={props.handleClose}
        backdrop="static"
        keyboard={false}
        centered
        className="modal fade"
      >
        <Modal.Header closeButton>Send Bulk Message</Modal.Header>
        <Modal.Body className="d-flex flex-column justify-content-center align-items-center">
          <div className="d-flex flex-column">
            <div style={{ marginTop: 20 }}>
              <Text>Template Message</Text>
              <ToggleButton
                label={"Template Message"}
                onChange={() => settemplateMsg(!templateMsg)}
                checked={templateMsg}
                style={{ marginLeft: 0 }}
              />
            </div>
            <Formik
              initialValues={{
                message: "",
                phoneNumbers: "",
                replyButtonTitle: "",
                urlButtonTitle: "",
                urlButtonPayload: "",
                callButtonPayload: "",
                callButtonTitle: "",
                templateTitle: "",
                templateFooter: "",
              }}
              onSubmit={async (values: FormikValues) => {
                try {
                  setloading(true);
                  let phoneNumbers = values.phoneNumbers
                    .replace(/\s/g, "")
                    .split(/,|\n/)
                    .filter((number: any) => number !== "");

                  // phoneNumbers = phoneNumbers.map(addCountryCode);

                  const sendMessages = async () => {
                    for (let index = 0; index < phoneNumbers.length; index++) {
                      const element = phoneNumbers[index];

                      var data = QueryString.stringify({
                        id: element,
                        message: values.message,
                      });

                      let formData = new FormData();
                      if (selectedFile) {
                        formData.append("file", selectedFile);
                        formData.append("id", element);
                        formData.append("caption", values.message);
                      } else {
                        formData.append("id", element);
                        formData.append("message", values.message);
                      }

                      let config: any;
                      if (templateMsg) {
                        config = {
                          method: "post",
                          url: `https://wa-api-node.onrender.com/message/button?key=${show}`,
                          headers: {
                            "Content-Type": "application/json",
                          },
                          data: data,
                        };
                      } else if (selectedFile) {
                        config = {
                          maxBodyLength: Infinity,
                          method: "post",
                          url: `https://wa-api-node.onrender.com/message/image?key=${show}`,
                          headers: {},
                          data: formData,
                        };
                      } else {
                        config = {
                          maxBodyLength: Infinity,
                          method: "post",
                          url: `https://wa-api-node.onrender.com/message/text?key=${show}`,
                          headers: {},
                          data: data,
                        };
                      }

                      try {
                        const res = await axios(config);
                        setmessageLog((oldMessages: any) => [
                          ...oldMessages,
                          `Sent message to ${element}`,
                        ]);
                      } catch (error: any) {
                        setmessageLog((oldMessages: any) => [
                          ...oldMessages,
                          <p style={{ color: "red" }}>
                            {error.response.data.message} {element}
                          </p>,
                        ]);
                      }
                      // Wait for 1-2 minutes before sending the next message
                      await new Promise((resolve) =>
                        setTimeout(
                          resolve,
                          Math.random() * (120000 - 60000) + 60000
                        )
                      );
                    }
                  };

                  await sendMessages();
                  setloading(false);
                  values.phoneNumbers = "";
                  values.message = "";
                  values.callButtonPayload = "";
                  values.callButtonTitle = "";
                  values.urlButtonTitle = "";
                  values.urlButtonPayload = "";
                  values.templateTitle = "";
                  values.templateFooter = "";
                  setSelectedFile(null);
                  setbtnType([]);
                  reload();
                } catch (error) {
                  setloading(false);
                  console.log(error);
                }
              }}
            >
              {({ values }) => (
                <Form className="d-flex flex-column" style={{ marginTop: 20 }}>
                  {templateMsg ? (
                    <TemplateMessage
                      btnType={btnType}
                      setbtnType={setbtnType}
                    />
                  ) : (
                    <div>
                      <TextArea
                        id={""}
                        name={"phoneNumbers"}
                        placeholder={
                          "Phone Numbers (comma or newline separated)"
                        }
                        type={"text"}
                        rows="2"
                      />
                      <TextArea
                        id={""}
                        name={"message"}
                        placeholder={"Message"}
                        type={"text"}
                        rows="4"
                      />
                      <input
                        style={{ marginBottom: 30 }}
                        type="file"
                        onChange={(e) =>
                          setSelectedFile(
                            e.target.files ? e.target.files[0] : null
                          )
                        }
                      />
                    </div>
                  )}
                  <ButtonComponent
                    label={"Send Message"}
                    loading={loading}
                    disabled={loading}
                    btnType={"dark"}
                  />
                </Form>
              )}
            </Formik>
          </div>
          <div style={{ marginTop: 10 }}>
            {messageLog.length ? (
              <ButtonComponent
                label={"Clear"}
                style={{ width: "45px", height: 30, marginBottom: 10 }}
                loading={loading}
                onClick={() => setmessageLog([])}
                btnType={"dark"}
              />
            ) : null}
            {messageLog.map((a: any, key: any) => (
              <p key={key} style={{ fontSize: 12, color: "#7F56D9" }}>
                {a}
              </p>
            ))}
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};
