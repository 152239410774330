/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { useSelector } from "react-redux";
import { getFees, getUsers, searchFees } from "../Services/Function";
import {
  AddFeesModal,
  ButtonComponent,
  FeesView,
  FormInput,
  PaginationComponent,
} from "../Components";
import { Navigate } from "react-router-dom";
import {
  Container,
  PaginationSection,
  PaginationSectionText,
  SearchBar,
} from "./styled/students";
import { Col, Dropdown, Row, Tab, Tabs } from "react-bootstrap";
import { AssignText } from "./styled/studentPage";
import Select from "react-select";
import { debounce } from "debounce";

interface IFeesProps {}

export const Fees: React.FunctionComponent<IFeesProps> = (props) => {
  const auth = useSelector((state: any) => state.auth);
  var authToken = localStorage.getItem("authToken");

  const [ofcFees, setofcFees] = React.useState([]);
  const [studentVisa, setstudentVisa] = React.useState([]);
  const [ieltsBookings, setieltsBookings] = React.useState([]);
  const [modalShow, setmodalShow] = React.useState("");
  const [page, setpage] = React.useState<any>(1);
  const [totalPage, settotalPage] = React.useState(0);
  const [feeType, setfeeType] = React.useState<any>("office-fees");
  const [searchText, setsearchText] = React.useState("");
  const [totalFees, settotalFees] = React.useState(0);
  const [users, setusers] = React.useState([]);
  const [selectedOption, setSelectedOption] = React.useState<any>([]);
  const [englishSelfFunded, setenglishSelfFunded] = React.useState<any>([]);

  React.useEffect(() => {
    async function fetchData() {
      const user = await getUsers();
      setusers(user.results);
      if (feeType === "ielts-booking") {
        const fees = await getFees({
          sortBy: "createdDate:desc",
          limit: 10,
          page: page,
          feeType: "ielts-booking",
        });
        setieltsBookings(fees.results);
        settotalPage(fees.totalPages);
        settotalFees(fees.totalResults);
      }
      if (feeType === "student-visa") {
        const fees = await getFees({
          sortBy: "createdDate:desc",
          limit: 10,
          page: page,
          feeType: "student-visa",
        });
        setstudentVisa(fees.results);
        settotalPage(fees.totalPages);
        settotalFees(fees.totalResults);
      }
      if (feeType === "office-fees") {
        const fees = await getFees({
          sortBy: "createdDate:desc",
          limit: 10,
          page: page,
          feeType: "office-fees",
        });
        setofcFees(fees.results);
        settotalPage(fees.totalPages);
        settotalFees(fees.totalResults);
      }
      if (feeType === "english-self-funded") {
        const fees = await getFees({
          sortBy: "createdDate:desc",
          limit: 10,
          page: page,
          feeType: "english-self-funded",
        });
        setenglishSelfFunded(fees.results);
        settotalPage(fees.totalPages);
        settotalFees(fees.totalResults);
      }
    }
    fetchData();
  }, []);
  const handleChange = async (selectedItems: any) => {
    setSelectedOption(selectedItems);

    const fees = await getFees({
      sortBy: "createdDate:desc",
      limit: 10,
      page: 1,
      feeType: "office-fees",
      user: selectedItems.map((option: any) => option.value),
    });
    setofcFees(fees.results);
    settotalPage(fees.totalPages);
    settotalFees(fees.totalResults);
  };
  const reloadData = async (feeType: string, tabChanged?: boolean) => {
    if (feeType === "ielts-booking") {
      const fees = await getFees({
        sortBy: "createdDate:desc",
        limit: 10,
        page: tabChanged ? 1 : page,
        feeType: "ielts-booking",
      });
      setieltsBookings(fees.results);
      settotalPage(fees.totalPages);
      settotalFees(fees.totalResults);
    }
    if (feeType === "student-visa") {
      const fees = await getFees({
        sortBy: "createdDate:desc",
        limit: 10,
        page: tabChanged ? 1 : page,
        feeType: "student-visa",
      });
      setstudentVisa(fees.results);
      settotalPage(fees.totalPages);
      settotalFees(fees.totalResults);
    }
    if (feeType === "office-fees") {
      const fees = await getFees({
        sortBy: "createdDate:desc",
        limit: 10,
        page: tabChanged ? 1 : page,
        feeType: "office-fees",
        user: selectedOption?.map((option: any) => option.value),
      });
      setofcFees(fees.results);
      settotalPage(fees.totalPages);
      settotalFees(fees.totalResults);
    }
    if (feeType === "english-self-funded") {
      const fees = await getFees({
        sortBy: "createdDate:desc",
        limit: 10,
        page: tabChanged ? 1 : page,
        feeType: "english-self-funded",
      });
      setenglishSelfFunded(fees.results);
      settotalPage(fees.totalPages);
      settotalFees(fees.totalResults);
    }
  };

  const options: any = users.map((a: any) => ({ value: a.id, label: a.name }));

  if (!auth.isLoggedIn && !authToken) {
    return <Navigate to="/" />;
  }

  return (
    <Container className="">
      <Row className="justify-content-center ">
        <Col md={12}>
          <div style={{ padding: "20px 0" }}>
            <div className="d-flex justify-content-between">
              <Col md={3}>
                <ButtonComponent
                  onClick={() => setmodalShow("add")}
                  label={"Add Fees"}
                  loading={false}
                  btnType={"dark"}
                />
              </Col>
              {feeType === "office-fees" ? (
                <Row>
                  <Col md={"6"}>
                    <SearchBar
                      style={{
                        width: "100%",
                        marginRight: 20,
                        borderColor: "#d7dfe9",
                        borderRadius: 4,
                      }}
                      onChange={debounce(async (e: any) => {
                        setsearchText(e.target.value);
                        if (e.target.value === "") {
                          const fees = await getFees({
                            sortBy: "createdDate:desc",
                            limit: 10,
                            page: page,
                            feeType: "office-fees",
                            user: selectedOption?.map(
                              (option: any) => option.value
                            ),
                          });
                          setofcFees(fees.results);
                          settotalPage(fees.totalPages);
                          settotalFees(fees.totalResults);
                        } else {
                          const data = await searchFees(
                            { text: e.target.value },
                            {
                              sortBy: "createdDate:desc",
                              limit: 10,
                              page: 1,
                            }
                          );
                          setpage(1);

                          setofcFees(data.results);
                          settotalPage(data.totalPages);
                          settotalFees(data.totalResults);
                        }
                      }, 500)}
                      placeholder="Search..."
                    />
                  </Col>
                  <Col md={"6"}>
                    <Select
                      isMulti
                      styles={{
                        control: (baseStyles, state) => ({
                          ...baseStyles,
                          borderColor: state.isFocused ? "#7F56D9" : "#d7dfe9",
                          boxShadow: "none",
                        }),
                      }}
                      placeholder="Filter Sales Person..."
                      value={selectedOption}
                      onChange={handleChange}
                      options={options}
                    />
                  </Col>
                </Row>
              ) : null}
            </div>

            <Tabs
              id="controlled-tab-example"
              activeKey={feeType}
              onSelect={async (k: any) => {
                setpage(1);
                await reloadData(k, true);

                setfeeType(k);
              }} // Update active tab on select
            >
              <Tab eventKey="office-fees" title="Office Fees">
                <FeesView
                  reloadFees={() => reloadData("office-fees")}
                  page={page}
                  fees={ofcFees}
                  feeType={"office-fees"}
                />
                <PaginationSection>
                  <PaginationSectionText>
                    {`Showing ${(page - 1) * 10 + 1} to ${
                      page * 10
                    } of ${totalFees} results`}
                  </PaginationSectionText>
                  <PaginationComponent
                    page={parseInt(page)}
                    totalPage={totalPage}
                    onPageClick={async (x: any) => {
                      setpage(parseInt(x));
                      setsearchText("");

                      const fees = await getFees({
                        sortBy: "createdDate:desc",
                        limit: 10,
                        page: x,
                        feeType: "office-fees",
                        user: selectedOption?.map(
                          (option: any) => option.value
                        ),
                      });
                      setofcFees(fees.results);
                      settotalFees(fees.totalResults);
                      settotalPage(fees.totalPages);
                    }}
                    onChange={async () => {
                      setpage(parseInt(page) + 1);
                      if (ofcFees?.length === 10) {
                        const fees = await getFees({
                          sortBy: "createdDate:desc",
                          limit: 10,
                          page: page + 1,
                          feeType: "office-fees",
                          user: selectedOption?.map(
                            (option: any) => option.value
                          ),
                        });
                        setofcFees(fees.results);
                        settotalFees(fees.totalResults);
                        settotalPage(fees.totalPages);
                      }
                    }}
                    onChangePrev={async () => {
                      setpage(parseInt(page) > 1 ? parseInt(page) - 1 : 1);
                      const fees = await getFees({
                        sortBy: "createdDate:desc",
                        limit: 10,
                        page: page - 1,
                        feeType: "office-fees",
                        user: selectedOption?.map(
                          (option: any) => option.value
                        ),
                      });
                      setofcFees(fees.results);
                      settotalFees(fees.totalResults);
                      settotalPage(fees.totalPages);
                    }}
                  />
                </PaginationSection>
              </Tab>
              <Tab eventKey="ielts-booking" title="Ielts Booking">
                <FeesView
                  reloadFees={() => reloadData("ielts-booking")}
                  page={1}
                  fees={ieltsBookings}
                  feeType={"ielts-booking"}
                />
                <PaginationSection>
                  <PaginationSectionText>
                    {`Showing ${(page - 1) * 10 + 1} to ${
                      page * 10
                    } of ${totalFees} results`}
                  </PaginationSectionText>
                  <PaginationComponent
                    page={parseInt(page)}
                    totalPage={totalPage}
                    onPageClick={async (x: any) => {
                      setpage(parseInt(x));
                      setsearchText("");

                      const fees = await getFees({
                        sortBy: "createdDate:desc",
                        limit: 10,
                        page: x,
                        feeType: "ielts-booking",
                      });
                      setieltsBookings(fees.results);
                      settotalFees(fees.totalResults);
                      settotalPage(fees.totalPages);
                    }}
                    onChange={async () => {
                      setpage(parseInt(page) + 1);
                      if (ieltsBookings?.length === 10) {
                        const fees = await getFees({
                          sortBy: "createdDate:desc",
                          limit: 10,
                          page: page + 1,
                          feeType: "ielts-booking",
                        });
                        setieltsBookings(fees.results);
                        settotalFees(fees.totalResults);
                        settotalPage(fees.totalPages);
                      }
                    }}
                    onChangePrev={async () => {
                      setpage(parseInt(page) > 1 ? parseInt(page) - 1 : 1);
                      const fees = await getFees({
                        sortBy: "createdDate:desc",
                        limit: 10,
                        page: page - 1,
                        feeType: "ielts-booking",
                      });
                      setieltsBookings(fees.results);
                      settotalFees(fees.totalResults);
                      settotalPage(fees.totalPages);
                    }}
                  />
                </PaginationSection>
              </Tab>
              <Tab eventKey="student-visa" title="Student Visa">
                <FeesView
                  reloadFees={() => reloadData("student-visa")}
                  page={1}
                  fees={studentVisa}
                  feeType={"student-visa"}
                />

                <PaginationSection>
                  <PaginationSectionText>
                    {`Showing ${(page - 1) * 10 + 1} to ${
                      page * 10
                    } of ${totalFees} results`}
                  </PaginationSectionText>
                  <PaginationComponent
                    page={parseInt(page)}
                    totalPage={totalPage}
                    onPageClick={async (x: any) => {
                      setpage(parseInt(x));
                      setsearchText("");

                      const fees = await getFees({
                        sortBy: "createdDate:desc",
                        limit: 10,
                        page: x,
                        feeType: "student-visa",
                      });
                      setstudentVisa(fees.results);
                      settotalFees(fees.totalResults);
                      settotalPage(fees.totalPages);
                    }}
                    onChange={async () => {
                      setpage(parseInt(page) + 1);
                      if (studentVisa?.length === 10) {
                        const fees = await getFees({
                          sortBy: "createdDate:desc",
                          limit: 10,
                          page: parseInt(page) + 1,
                          feeType: "student-visa",
                        });

                        setstudentVisa(fees.results);
                        settotalFees(fees.totalResults);
                        settotalPage(fees.totalPages);
                      }
                    }}
                    onChangePrev={async () => {
                      setpage(parseInt(page) > 1 ? parseInt(page) - 1 : 1);
                      const fees = await getFees({
                        sortBy: "createdDate:desc",
                        limit: 10,
                        page: parseInt(page) - 1,
                        feeType: "student-visa",
                      });
                      setstudentVisa(fees.results);
                      settotalFees(fees.totalResults);
                      settotalPage(fees.totalPages);
                    }}
                  />
                </PaginationSection>
              </Tab>
              <Tab eventKey="english-self-funded" title="English Self Funded">
                <FeesView
                  reloadFees={() => reloadData("english-self-funded")}
                  page={1}
                  fees={englishSelfFunded}
                  feeType={"english-self-funded"}
                />

                <PaginationSection>
                  <PaginationSectionText>
                    {`Showing ${(page - 1) * 10 + 1} to ${
                      page * 10
                    } of ${totalFees} results`}
                  </PaginationSectionText>
                  <PaginationComponent
                    page={parseInt(page)}
                    totalPage={totalPage}
                    onPageClick={async (x: any) => {
                      setpage(parseInt(x));
                      setsearchText("");

                      const fees = await getFees({
                        sortBy: "createdDate:desc",
                        limit: 10,
                        page: x,
                        feeType: "english-self-funded",
                      });
                      setenglishSelfFunded(fees.results);
                      settotalFees(fees.totalResults);
                      settotalPage(fees.totalPages);
                    }}
                    onChange={async () => {
                      setpage(parseInt(page) + 1);
                      if (ofcFees?.length === 10) {
                        const fees = await getFees({
                          sortBy: "createdDate:desc",
                          limit: 10,
                          page: page + 1,
                          feeType: "english-self-funded",
                        });
                        setenglishSelfFunded(fees.results);
                        settotalFees(fees.totalResults);
                        settotalPage(fees.totalPages);
                      }
                    }}
                    onChangePrev={async () => {
                      setpage(parseInt(page) > 1 ? parseInt(page) - 1 : 1);
                      const fees = await getFees({
                        sortBy: "createdDate:desc",
                        limit: 10,
                        page: page - 1,
                        feeType: "english-self-funded",
                      });
                      setenglishSelfFunded(fees.results);
                      settotalFees(fees.totalResults);
                      settotalPage(fees.totalPages);
                    }}
                  />
                </PaginationSection>
              </Tab>
            </Tabs>
          </div>
        </Col>
      </Row>
      {modalShow === "add" ? (
        <AddFeesModal
          show={modalShow === "add"}
          onHide={() => setmodalShow("")}
          reloadData={reloadData}
          feeType={feeType}
        />
      ) : null}
    </Container>
  );
};
