// #region Global Imports
import styled from "styled-components";
// #endregion Global Imports

export const NotAddedText = styled.span`
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #000000;
  padding: 16px 0;
`;
export const AddDocument = styled.span`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #0065ff;
  display: flex;
  flex-direction: row;
  aign-items: center;
  cursor: pointer;
`;

export const BackBtn = styled.span`
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.75px;
  color: rgba(0, 0, 0, 0.42);
`;

export const AddBtn = styled.div`
  width: 18px;
  height: 18px;
  border-radius: 9px;
  background: #7f56d9;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  margin-right: 6px;
  cursor: pointer;
`;

export const Label = styled.label`
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.25px;
  color: rgba(0, 0, 0, 0.87);
  margin-bottom: 2px;
`;
export const Required = styled.span`
  color: #ba1b23;
`;

export const Card = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  justify-content: space-between;
  margin-top: 25px;
`;
export const AttachmentCon = styled.div`
  border-top: 1px solid #ebecf0;
  border-bottom: 1px solid #ebecf0;
  padding: 12px 0;
  width: 100%;
`;
export const AddDocBtn = styled.div`
  width: 103px;
  height: 27px;
  background: #ffffff;
  border: 1px solid #b3d4ff;
  border-radius: 12px;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #0052cc;
  cursor: pointer;
`;
export const DocumentText = styled.span`
  font-family: "Open Sans";
  text-transform: capitalize;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #505f79;
`;

export const AnchorTag = styled.div`
  font-family: "Inter";
  font-style: normal;
  cursor: pointer;
  text-decoration: none;
  width: max-content;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #344054;
  margin-bottom: 10px;
  outline: none;
  border: none;
  &:hover {
    color: #7f56d9;
  }
`;
export type DocumentSubtextProps = {
  fontWeight?: number;
  size?: string;
};
export const DocumentSubtext = styled.span<DocumentSubtextProps>(
  ({ fontWeight, size }) => `
  font-family: "Open Sans";
  text-transform: capitalize;
  
  font-style: normal;
  font-weight: ${fontWeight};
  font-size: ${size ? size : "14px"};
  line-height: 19px;
  display: flex;
  align-items: center;
  color: #5e6c84;
  `
);

export const Pointer = styled.span`
  background: rgba(0, 0, 0, 0.24);
  width: 4px;
  height: 4px;
  border-radius: 2px;
  margin: 0 12px;
`;

export const UploadInput = styled.div`
  width: 100%;
  height: 120px;
  position: relative;
  background: #fafbfc;
  border: 1px dashed #c1c7d0;
  border-radius: 3px;
  display: flex !important;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;
`;

export const UploadPassport = styled.div`
  width: 100%;
  height: 40px;
  position: relative;
  background: #fff;
  border: 1px solid #c1c7d0;
  border-radius: 3px;
  display: flex !important;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;
`;

export const UploadText = styled.span`
  font-family: "Roboto";
  text-transform: capitalize;
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 26px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #7a869a;
`;
export const UploadSubtext = styled.span`
  font-family: "Roboto";
  text-transform: capitalize;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  text-align: center;
  color: #b3bac5;
`;
