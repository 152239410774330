// #region Global Imports
import styled from "styled-components";
// #endregion Global Imports

export const Container = styled.div`
  padding: 32px;
  width: 100%;
  background: #ffffff;
  margin-bottom: 50px;
  @media (max-width: 992px) {
    padding: 16px;
  }
`;

export const RowCol = styled.div`
  display: flex;
  flex-direction: row;
  @media (max-width: 992px) {
    flex-direction: column;
  }
`;

export const HeaderText = styled.span`
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 36px;
  flex: none;
  order: 0;
  flex-grow: 1;
  color: ${({ theme }) => theme.text};
`;

export const SearchBar = styled.input`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 6px 8px;
  gap: 4px;
  width: 320px;
  background: #ffffff;
  border: 1px solid #eaecf0;
  border-radius: 8px;
  padding-left: 25px;
  ::placeholder {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #667085;
  }
  &:focus {
    border: 1px solid red;
    outline: none;
  }
  @media (max-width: 992px) {
    width: 100%;
  }
`;

export type FilterBtnProps = {
  filterApplied?: boolean;
};
export const FilterBtn = styled.div<FilterBtnProps>(
  ({ filterApplied }) => `
  font-family: "Inter";
  font-style: normal;
  margin-left: 8px;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: ${filterApplied ? "#7F56D9" : "#344054"};
  padding: 9px 7px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  }
  
        `
);

export const ToggleText = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: ${({ theme }) => theme.primaryColor};
`;

export const PaginationSection = styled.div`
  padding: 14px 32px 32px;
  position: fixed;
  width: 100%;
  height: 71px;
  left: 0px;
  bottom: 0;
  background: #ffffff;
  display: flex;
  align-items: center;
`;

export const PaginationSectionText = styled.span`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #344054;
  width: max-content;
`;
