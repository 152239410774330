import * as React from "react";
import { Col } from "react-bootstrap";
import { Label, Required } from "../DocumentPage/style";
import { FormInput } from "../FormInput";
import { DateComponent } from "../DateComponent";
import { ToggleButton } from "../ToggleButton";

interface IExperienceFormProps {
  joiningDate: string;
  setjoiningDate: Function;
  leavingDate: string;
  setleavingDate: Function;
}

export const ExperienceForm: React.FunctionComponent<IExperienceFormProps> = (
  props
) => {
  const [currentComapany, setcurrentComapany] = React.useState(false);
  return (
    <div>
      <Col md={6} style={{ marginBottom: 20 }}>
        <Label>
          <Required>*</Required> Organisation Name
        </Label>
        <FormInput
          id="organisationName"
          style={{ borderRadius: 3, marginBottom: 0 }}
          name="organisationName"
          placeholder="Organisation Name"
          type="text"
        />
      </Col>
      <Col md={6} style={{ marginBottom: 20 }}>
        <Label>
          <Required>*</Required> Designation
        </Label>
        <FormInput
          id="jobTitle"
          style={{ borderRadius: 3, marginBottom: 0 }}
          name="jobTitle"
          placeholder=""
          type="text"
        />
      </Col>
      <Col md={6} style={{ marginBottom: 15 }}>
        <Label>
          <Required>*</Required> Current company
        </Label>
        <div>
          <ToggleButton
            label={"Current company"}
            onChange={() => setcurrentComapany(!currentComapany)}
            checked={currentComapany}
            style={{ margin: 0 }}
          />
        </div>
      </Col>

      <Col md={6} style={{ marginBottom: 20 }}>
        <DateComponent
          monthLabel={""}
          yearLabel={"Joining Date"}
          joiningDateFromChild={(data: React.SetStateAction<string>) =>
            props.setjoiningDate(data)
          }
          label={""}
        />
      </Col>

      {!currentComapany ? (
        <Col md={6} style={{ marginBottom: 20 }}>
          <DateComponent
            monthLabel={""}
            yearLabel={"Relieving Date"}
            leavingDateFromChild={(data: React.SetStateAction<string>) =>
              props.setleavingDate(data)
            }
            joiningDateFromChild={(data: React.SetStateAction<string>) =>
              props.setjoiningDate(data)
            }
            label={""}
          />
        </Col>
      ) : null}
    </div>
  );
};

export default ExperienceForm;
