import styled from "styled-components";

export const CourseName = styled.div`
  font-family: "Inter";
  color: #344563;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.5px;
  padding-bottom: 9px;
`;

export const University = styled.div`
  color: #505f79;
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.5px;
`;

export const Fees = styled.div`
  color: #42526e;
  font-family: Open Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.5px;
`;
