import React from "react";

type Props = {};

export const CreateStudentIcon2 = (props: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="48"
      height="48"
      fill="none"
      viewBox="0 0 48 48"
    >
      <rect width="48" height="48" fill="#F9F5FF" rx="8"></rect>
      <path
        stroke="#9E77ED"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M20 19v8a2 2 0 002 2h6m-8-10v-2a2 2 0 012-2h4.586a1 1 0 01.707.293l4.414 4.414a1 1 0 01.293.707V27a2 2 0 01-2 2h-2m-8-10h-2a2 2 0 00-2 2v10a2 2 0 002 2h8a2 2 0 002-2v-2"
      ></path>
    </svg>
  );
};
