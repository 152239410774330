import React from "react";
import { Dropdown } from "react-bootstrap";
// import { FormInput } from "../FormInput";
import { TextArea } from "../TextArea";

type Props = {
  btnType: Array<any>;
  setbtnType: Function;
};

export const TemplateMessage = (props: Props) => {
  const { setbtnType, btnType } = props;

  return (
    <div style={{ margin: "20px 0" }}>
      <TextArea
        id={"templateTitle"}
        name={"templateTitle"}
        placeholder={"Title"}
        type={""}
      />
      <TextArea
        id={"templateFooter"}
        name={"templateFooter"}
        placeholder={"Footer"}
        type={""}
      />
      <Dropdown>
        <Dropdown.Toggle
          className="dd-custom-toggle"
          id="dropdown-custom-components"
          style={{ width: "max-content", marginBottom: 20 }}
        >
          Select type of button
        </Dropdown.Toggle>
        <Dropdown.Menu className="dd-custom-menu">
          <Dropdown.Item
            target="_blank"
            className="dd-custom-items"
            onClick={() =>
              setbtnType((oldMessages: any) => [
                ...oldMessages,
                {
                  type: "callButton",
                },
              ])
            }
          >
            Call button
          </Dropdown.Item>

          <Dropdown.Item
            target="_blank"
            className="dd-custom-items"
            onClick={() =>
              setbtnType((oldMessages: any) => [
                ...oldMessages,
                {
                  type: "urlButton",
                },
              ])
            }
          >
            Click button
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>

      {btnType.map((a: any, key: any) => (
        <div key={key}>
          <p>{a.type}</p>
          <TextArea
            id={`${a.type}Title`}
            name={`${a.type}Title`}
            placeholder={a.type}
            type={"text"}
          />
          {a.type === "replyButton" ? null : (
            <TextArea
              id={`${a.type}Payload`}
              name={`${a.type}Payload`}
              placeholder={`${a.type} Payload`}
              type={"text"}
            />
          )}
        </div>
      ))}
    </div>
  );
};
