// #region Global Imports
import styled from "styled-components";
// #endregion Global Imports
export type CourseCardProps = {
  selected?: boolean;
};
export const CourseCard = styled.div<CourseCardProps>(
  ({ selected }) => `
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px;
  gap: 18px;
  min-height: 140px;
  background: #ffffff;
  border: ${selected ? "1px solid #6b7280" : "none"};
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06);
  border-radius: 8px;
  margin-bottom: 10px;
`
);

export const CourseHeader = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #111827;
`;

export const CourseText = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #6b7280;
`;

export const AppliedBadge = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  /* identical to box height, or 133% */

  text-align: center;

  /* green/800 */

  color: #065f46;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 2px 10px;

  width: 64px;
  height: 20px;

  /* green/100 */

  background: #d1fae5;
  border-radius: 10px;
`;

export const ImgCon = styled.div`
  height: 235px;
  left: 560px;
  top: 88px;
  border-radius: 16px;
`;

export const CourseName = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  color: #191d23;
`;

export const InstituteName = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  /* or 143% */

  /* gray/900 */

  color: #111827;
`;

export const BorderBottom = styled.div`
  width: 100%;
  border-bottom: 1px solid #e0e6ed;
`;

export const SubHeader = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  margin: 32px 0 20px 0;
  color: #111827;
`;

export const ReadMore = styled.div`
  font-family: "Inter";
  font-style: normal;
  cursor: pointer;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  text-decoration-line: underline;
  color: #191d23;
`;
