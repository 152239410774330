import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { ButtonComponent } from "../ButtonComponent";
import { Form, Formik, FormikHelpers } from "formik";
import { FormInput } from "../FormInput";
import { Label } from "./styled";
import { DropdownComponent } from "../DropdownComponent";
import { createUser, getRoles } from "../../Services/Function";
import { PasswordInput } from "../PasswordInput";

type Props = {
  show: any;
  handleClose: any;
  reloadUsers: any;
};

export function AddUserModal(props: Props) {
  const [loading, setLoading] = useState(false);
  const [roles, setRoles] = React.useState([]);
  const [selectedRole, setSelectedRole] = useState<any>("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  React.useEffect(() => {
    async function fetchData() {
      const res: any = await getRoles();
      setRoles(res.results);
    }
    fetchData();
  }, []);

  return (
    <div>
      <Modal
        show={props.show}
        onHide={props.handleClose}
        backdrop="static"
        keyboard={false}
        className="modal fade"
        centered
      >
        <Formik
          initialValues={{
            name: "",
            email: "",
            slackMemberId: "",
            confirmPassword: "",
            password: "",
          }}
          validate={(values) => {
            const errors: any = {};
            if (password.length < 8) {
              errors.password = "Password must be at least 8 characters";
            }
            if (!/\d/.test(password)) {
              errors.password = "Password must contain at least one digit";
            }
            if (!/[^A-Za-z0-9]/.test(password)) {
              errors.password =
                "Password must contain at least one special character";
            }
            if (password !== confirmPassword) {
              errors.confirmPassword = "Passwords do not match";
            }
            return errors;
          }}
          onSubmit={async (
            values: any,
            { setSubmitting }: FormikHelpers<any>
          ) => {
            setLoading(true);
            await createUser({
              name: values.name,
              email: values.email,
              role: selectedRole.id,
              slackMemberId: values.slackMemberId,
              password: password,
            });
            props.reloadUsers();
            setLoading(false);
            props.handleClose();
          }}
        >
          {({ values, handleChange, errors, touched }) => (
            <Form>
              <Modal.Header closeButton>
                <Modal.Title className="modal-title">Add User</Modal.Title>
              </Modal.Header>
              <Modal.Body
                className="h-100"
                style={{ padding: 15, overflow: "scroll" }}
              >
                <>
                  <FormInput
                    id="name"
                    name="name"
                    placeholder=""
                    type="text"
                    label="Name"
                  />

                  <FormInput
                    id="email"
                    name="email"
                    placeholder=""
                    type="email"
                    label="Email"
                  />

                  <PasswordInput
                    id="password"
                    name="password"
                    label="Password"
                    value={password}
                    onChange={(e: any) => setPassword(e.target.value)}
                    error={errors.password && touched.password}
                    errorMessage={errors.password}
                  />

                  <PasswordInput
                    id="confirmPassword"
                    name="confirmPassword"
                    label="Confirm Password"
                    value={confirmPassword}
                    onChange={(e: any) => setConfirmPassword(e.target.value)}
                    error={errors.confirmPassword && touched.confirmPassword}
                    errorMessage={errors.confirmPassword}
                  />

                  <FormInput
                    id={"slackMemberId"}
                    name={"slackMemberId"}
                    type={"text"}
                    label="Slack Member Id"
                  />
                  <div>
                    <Label>Assign Role</Label>
                    <DropdownComponent
                      children={roles}
                      onClick={(data: any) => setSelectedRole(data)}
                      className=""
                      selected={selectedRole.name}
                      placeholder={""}
                      displayName
                    />
                  </div>
                </>
                <div className="d-flex" style={{ marginTop: 50 }}>
                  <ButtonComponent
                    label={"Submit"}
                    loading={loading}
                    btnType={"dark"}
                    disabled={loading}
                  />
                  <ButtonComponent
                    label={"Close"}
                    loading={loading}
                    style={{ marginLeft: 20 }}
                    type="button"
                    onClick={() => props.handleClose()}
                    btnType={"light"}
                  />
                </div>
              </Modal.Body>
            </Form>
          )}
        </Formik>
      </Modal>
    </div>
  );
}
