import { DefaultTheme } from "styled-components";

declare module "styled-components" {
  export interface DefaultTheme {
    body: string;
    text: string;
    toggleBorder: string;
    background: string;
    primaryColor: string;
    headerText: string;
    thText: string;
    navText: string;
  }
}

export const lightTheme: DefaultTheme = {
  body: "#F5F5F5",
  text: "#1D2939",
  toggleBorder: "#FFF",
  background: "#363537",
  primaryColor: "#7F56D9",
  headerText: "#101828",
  thText: "#667085",
  navText: "#fff",
};
export const darkTheme: DefaultTheme = {
  body: "#363537",
  text: "#FAFAFA",
  toggleBorder: "#6B8096",
  background: "#999",
  primaryColor: "#fff",
  headerText: "#F5F5F5",
  thText: "#F5F5F5",
  navText: "#000",
};
