// #region Global Imports
import styled from "styled-components";
// #endregion Global Imports

export const Card = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 32px;
  gap: 34px;
  width: 100%;
  border-radius: 8px;
  left: 0px;
  top: 0px;
  background: #fff;
  width: 540px;
`;
