import * as React from "react";
import { Modal } from "react-bootstrap";
import styled, { css } from "styled-components";

interface IDeleteModalProps {
  onHide: () => void;
  show: boolean;
  data?: any[];
  school?: any[];
  topTypes?: any[];
  cities?: any[];
}

const Container = styled.div`
  border-radius: 10px;
  padding: 16px;
  margin: 0 auto;
  background: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

const Th = styled.th<{ isSorted: boolean }>`
  text-align: center;
  padding: 8px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 150%;
  letter-spacing: -0.02em;
  color: #1e2432;
  cursor: pointer;
  ${(props) =>
    props.isSorted &&
    css`
      background-color: rgba(190, 161, 255, 0.1);
    `}
  border-radius: 16px;
  &:first-child {
    text-align: left;
  }
`;

const Td = styled.td`
  padding: 8px;
  text-align: center;
  font-family: "Roboto";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  letter-spacing: -0.02em;
  color: rgba(30, 36, 50, 0.8);
  white-space: nowrap;
  &:first-child {
    text-align: left;
  }
`;

const SchoolRow = styled.tr`
  &:not(:last-child) {
    // border-bottom: 1px solid #ddd;
  }
`;

type SortConfig = {
  key: string;
  direction: "ascending" | "descending";
};

export const ViewMore: React.FunctionComponent<IDeleteModalProps> = (props) => {
  const [sortConfig, setSortConfig] = React.useState<SortConfig>({
    key: "totalWeeks",
    direction: "ascending",
  });

  const sortedData = React.useMemo(() => {
    const sortableItems = [...(props.data || [])];
    sortableItems.sort((a, b) => {
      const aValue = a[sortConfig.key] ?? 0;
      const bValue = b[sortConfig.key] ?? 0;

      if (aValue < bValue) {
        return sortConfig.direction === "ascending" ? -1 : 1;
      }
      if (aValue > bValue) {
        return sortConfig.direction === "ascending" ? 1 : -1;
      }
      return 0;
    });
    return sortableItems;
  }, [props.data, sortConfig]);

  const requestSort = (key: string) => {
    let direction: "ascending" | "descending" = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key, direction });
  };

  const renderHeader = () => {
    if (props.school) {
      return (
        <>
          <Th
            isSorted={sortConfig.key === "topSchool"}
            onClick={() => requestSort("topSchool")}
            style={{ textAlign: "left" }}
          >
            Top School
          </Th>
          <Th
            isSorted={sortConfig.key === "totalStudents"}
            onClick={() => requestSort("totalStudents")}
          >
            N° of Students
          </Th>
          <Th
            isSorted={sortConfig.key === "totalWeeks"}
            onClick={() => requestSort("totalWeeks")}
          >
            N° of Weeks
          </Th>
        </>
      );
    } else if (props.topTypes) {
      return (
        <>
          <Th
            isSorted={sortConfig.key === "topTypes"}
            onClick={() => requestSort("topTypes")}
            style={{ textAlign: "left" }}
          >
            Top Types of Office Fees
          </Th>
          <Th
            style={{ textAlign: "right" }}
            isSorted={sortConfig.key === "count"}
            onClick={() => requestSort("count")}
          >
            N° of Booking
          </Th>
        </>
      );
    } else if (props.cities) {
      return (
        <>
          <Th
            isSorted={sortConfig.key === "topCities"}
            onClick={() => requestSort("topCities")}
            style={{ textAlign: "left" }}
          >
            Top Cities
          </Th>
          <Th
            isSorted={sortConfig.key === "numberOfStudents"}
            onClick={() => requestSort("numberOfStudents")}
          >
            N° of Students
          </Th>
          <Th
            isSorted={sortConfig.key === "totalWeeks"}
            onClick={() => requestSort("totalWeeks")}
          >
            N° of Weeks
          </Th>
        </>
      );
    }
  };

  const renderRow = (item: any, index: number) => {
    if (props.school) {
      return (
        <SchoolRow key={index}>
          <Td>
            <span
              style={{
                fontSize: 14,
                fontWeight: 700,
                color: "#7F56D9",
                paddingRight: 15,
              }}
            >
              0{index + 1}
            </span>{" "}
            {item.school}
          </Td>
          <Td>{item.totalStudents}</Td>
          <Td>{item.totalWeeks}</Td>
        </SchoolRow>
      );
    } else if (props.topTypes) {
      return (
        <SchoolRow key={index}>
          <Td>
            <span
              style={{
                fontSize: 14,
                fontWeight: 700,
                color: "#7F56D9",
                paddingRight: 15,
              }}
            >
              0{index + 1}
            </span>{" "}
            {item._id}
          </Td>
          <Td style={{ textAlign: "right" }}>{item.count}</Td>
        </SchoolRow>
      );
    } else if (props.cities) {
      return (
        <SchoolRow key={index}>
          <Td>
            <span
              style={{
                fontSize: 14,
                fontWeight: 700,
                color: "#7F56D9",
                paddingRight: 15,
              }}
            >
              0{index + 1}
            </span>{" "}
            {item._id}
          </Td>
          <Td>{item.numberOfStudents}</Td>
          <Td>{item.totalWeeks}</Td>
        </SchoolRow>
      );
    }
  };
  return (
    <Modal
      id="myModal"
      show={props.show}
      onHide={props.onHide}
      size="lg"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title
          style={{ fontSize: 18 }}
          id="contained-modal-title-vcenter"
        ></Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container>
          <div className="d-flex flex-row">
            <Table style={{ position: "relative" }}>
              <thead>
                <tr>{renderHeader()}</tr>
              </thead>
              <tbody>
                {sortedData.map((item, index) => renderRow(item, index))}
              </tbody>
            </Table>
          </div>
        </Container>
      </Modal.Body>
    </Modal>
  );
};
