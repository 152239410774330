// #region Global Imports
import styled from "styled-components";
// #endregion Global Imports

export type ThProps = {
  info?: boolean;
  otherInfo?: boolean;
};
export const Th = styled.th<ThProps>(
  ({ info, otherInfo }) => `
  background-color: #f9fafb !important;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #667085;
  border-bottom: 1px solid #eaecf0;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  padding: ${
    info
      ? "12px 18px 12px 0 !important"
      : otherInfo
      ? "10px"
      : "12px 18px !important"
  };
  width: max-content;
  `
);

export const AvatarCon = styled.img`
  width: 30px;
  height: 30px;
  border-radius: 15px;
`;
export const Table = styled.table`
  border: 1px solid #eaecf0;
  border-6yradius: 8px;
  border-collapse: separate;
  border-spacing: 0;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
`;

export const Tr = styled.tr`
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  // cursor: pointer;
  :last-child {
    border-color: transparent;
  }
  :first-child td:first-child {
    border-top-left-radius: 8px;
  }
  :last-child td:first-child {
    border-bottom-left-radius: 8px;
  }
  :first-child td:last-child {
    border-top-right-radius: 8px;
  }
  :last-child td:last-child {
    border-bottom-right-radius: 8px;
  }
`;
export const THead = styled.thead``;
export const TBody = styled.tbody`
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
`;
export const TFoot = styled.tfoot``;

export type TdProps = {
  info?: boolean;
  otherInfo?: boolean;
};
export const Td = styled.td<TdProps>(
  ({ info, otherInfo }) => `
  vertical-align: middle;
  width: ${otherInfo ? "max-content" : "auto"};
  padding: ${
    info
      ? "12px 18px 12px 0 !important"
      : otherInfo
      ? "10px"
      : "12px 18px !important"
  };
  `
);

export const UserSection = styled.div`
  display: flex;
  flex-direction: row;
`;
export const HoverHeader = styled.span`
  color: #000;
  font-family: Inter;
  font-size: 25px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
`;

export const Avatar = styled.img`
  width: 40px;
  height: 40px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  border-radius: 20px;
  border: 1px solid #7f56d9;
`;

export type AssignToProps = {
  background?: string;
  color?: string;
};
export const AssignTo = styled.div<AssignToProps>(
  ({ background, color }) => `
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 2px 10px;
    height: 40px;
    cursor:pointer;
    width: 40px;
    background: ${background};
    border-radius: 20px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    text-align: center;
    color: ${color};
    -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  `
);

export const TdText = styled.span`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #1d2939;
  width: max-content;
`;

export const TdAnchor = styled.div`
  font-family: "Inter";
  text-decoration: none;
  text-decoration: underline;
  cursor: pointer;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #1d2939;
  width: max-content;
  &:hover {
    color: #7f56d9;
    font-weight: 700;
  }
`;

export const TextContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
`;

export const HoverText = styled.div`
  cursor: pointer;
  transition: opacity 6s ease;
  width: 100%;
  height: 32px;
  &:hover + div {
    display: block;
    opacity: 1;
    pointer-events: auto;
    backface-visibility: hidden;
  }
`;

export const HiddenDiv = styled.div`
  display: none;
  position: absolute;
  padding: 20px;
  width: max-content;
  height: max-content;
  top: 50%;
  z-index: 99;
  left: 0;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 8px;
`;

export const HoverSubText = styled.span`
  color: #667085;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  padding: 8px 0 21px 0;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
`;

export const TdEmail = styled.span`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #667085;
`;

export const QuestionIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  background: rgb(127, 86, 217);
  width: 10px;
  height: 10px;
  color: #1d2939;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
`;
export const MenuAnchor = styled.a`
  font-family: "Inter";
  font-style: normal;
  text-decoration: none;
  width: max-content;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #344054;
  margin-bottom: 10px;
  outline: none;
  border: none;
  &:hover {
    color: #fff;
  }
`;

export const MenuText = styled.div`
  font-family: "Inter";
  font-style: normal;
  width: max-content;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  padding: 0;
  color: #344054;
  outline: none;
  border: none;
`;
export const CrossIcon = styled.div`
  position: absolute;
  cursor: pointer;
  top: -1px;
  font-size: 13px;
  left: -8px;
  width: 15px;
  height: 15px;
  display: flex;
  color: rgb(255, 255, 255);
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  border-radius: 10px;
  background-color: rgb(255, 87, 106);
`;

export const MenuCon = styled.span`
  cursor: pointer;
  background-color: #fff;
  position: absolute;
  border: 1px solid #d7dfe9;
  box-sizing: border-box;
  border-radius: 4px;
  margin-top: 10px;
  zindex: 99;
  right: 43px;
  padding: 10px;
  z-index: 99;
`;

export type HoverDescTextProps = {
  fontWeight: number;
};
export const HoverDescText = styled.div<HoverDescTextProps>(
  ({ fontWeight }) =>
    `
color: #000;
font-family: Inter;
font-size: 12px;
font-style: normal;
font-weight: ${fontWeight};
line-height: 20px; /* 166.667% */
`
);
export type StatusProps = {
  background?: string;
  color: string;
};
export const Status = styled.div<StatusProps>(
  ({ background, color }) => `
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 2px 10px;
    height: 20px;
    cursor:pointer;
    width: max-content;
    background: ${background};
    border-radius: 10px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    color: ${color};
    -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  `
);
