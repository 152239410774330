import * as React from "react";

interface ICopyIconProps {
  style?: any;
}

export const CopyIcon: React.FunctionComponent<ICopyIconProps> = (props) => {
  return (
    <svg
      width={12}
      height={12}
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      style={props.style}
    >
      <g clipPath="url(#clip0_261_30728)">
        <path
          d="M8 0.5H2C1.4475 0.5 1 0.9475 1 1.5V8.5H2V1.5H8V0.5ZM9.5 2.5H4C3.4475 2.5 3 2.9475 3 3.5V10.5C3 11.0525 3.4475 11.5 4 11.5H9.5C10.0525 11.5 10.5 11.0525 10.5 10.5V3.5C10.5 2.9475 10.0525 2.5 9.5 2.5ZM9.5 10.5H4V3.5H9.5V10.5Z"
          fill="#0747A6"
        />
      </g>
      <defs>
        <clipPath id="clip0_261_30728">
          <rect width={12} height={12} fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
