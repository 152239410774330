import React, { useState } from "react";
import { Dropdown, Modal } from "react-bootstrap";
import { ButtonComponent } from "../ButtonComponent";
import { uploadNumbers } from "../../Services/Function";
import { Form, Formik, FormikHelpers } from "formik";
import { TextArea } from "../TextArea";

type Props = {
  show: any;
  handleClose: any;
  reload: any;
};

export const UploadModal = (props: Props) => {
  interface Values {
    numbers: string;
  }
  const [loading, setloading] = useState(false);
  const [selectedCountry, setselectedCountry] = useState<any>("");

  const countries = [
    { name: "Kuwait", code: "965" },
    { name: "UAE", code: "966" },
    { name: "Saudi", code: "971" },
    { name: "India", code: "91" },
  ];

  function addPrefixAndCommas(numbers: any[], countryCode: any) {
    const prefixedNumbers = numbers.map((num: any) => {
      return { phoneNumber: `${countryCode}${num}`, status: "not sent" };
    });
    return prefixedNumbers;
  }
  return (
    <div>
      <Modal
        show={props.show}
        onHide={props.handleClose}
        backdrop="static"
        keyboard={false}
        className="modal fade"
      >
        <Modal.Header closeButton>
          <Modal.Title className="modal-title">Upload Numbers</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formik
            initialValues={{
              numbers: "",
            }}
            onSubmit={async (
              values: Values,
              { setSubmitting }: FormikHelpers<Values>
            ) => {
              setloading(true);

              const formatedNumbers = addPrefixAndCommas(
                values.numbers.split("\n").map(Number),
                selectedCountry.code
              );

              for (let index = 0; index < formatedNumbers.length; index++) {
                const element = formatedNumbers[index];
                await uploadNumbers(element);
              }
              props.reload();
              props.handleClose();
              setloading(false);
            }}
          >
            <Form style={{ width: "70%" }} className="d-flex flex-column">
              <Dropdown style={{ marginBottom: 20 }}>
                <Dropdown.Toggle>
                  {selectedCountry?.name || "Select Country"}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  {countries.map((a: any, key: any) => (
                    <Dropdown.Item
                      key={key}
                      onClick={() => {
                        setselectedCountry(a);
                      }}
                      eventKey="1"
                    >
                      {a.name}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
              <TextArea
                id={"numbers"}
                name={"numbers"}
                placeholder={""}
                type={""}
              />
              <ButtonComponent
                btnType="dark"
                loading={loading}
                disabled={loading}
                label="Submit"
              />
            </Form>
          </Formik>
        </Modal.Body>
      </Modal>
    </div>
  );
};
