import React from "react";

type Props = { selected: any; style: any };

export const DashboardTab2 = (props: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      fill="none"
      style={props.style}
      viewBox="0 0 30 30"
    >
      {props.selected ? (
        <>
          <path
            fill="#5C51E1"
            fillRule="evenodd"
            d="M15 30c8.284 0 15-6.716 15-15 0-8.284-6.716-15-15-15C6.716 0 0 6.716 0 15c0 8.284 6.716 15 15 15z"
            clipRule="evenodd"
            opacity="0.21"
          ></path>
          <g clipPath="url(#clip0_4618_63549)">
            <path
              fill="#1E2432"
              fillOpacity="0.8"
              d="M20.264 10.011l-9.116-.261a.687.687 0 00-.172.035.676.676 0 00-.103.02.714.714 0 00-.23.15h-.004a.72.72 0 00-.217.515v9.987a.724.724 0 00.726.722l6.536.571a.726.726 0 00.727-.723V13.35a.723.723 0 00-.727-.723l-5.046-.265a.582.582 0 010-1.163l6.115.257h.443a.343.343 0 01.343.343v7.971a.726.726 0 001.452 0v-9.033a.727.727 0 00-.727-.726z"
            ></path>
          </g>
          <defs>
            <clipPath id="clip0_4618_63549">
              <path
                fill="#fff"
                d="M0 0H12V12H0z"
                transform="translate(9.75 9.75)"
              ></path>
            </clipPath>
          </defs>
          <defs>
            <clipPath id="clip0_4559_61782">
              <path
                fill="#fff"
                d="M0 0H10.5V10.5H0z"
                transform="translate(9.75 9.75)"
              ></path>
            </clipPath>
          </defs>
        </>
      ) : (
        <>
          {" "}
          <g clipPath="url(#clip0_4618_63549)">
            <path
              fill="#1E2432"
              fillOpacity="0.8"
              d="M20.264 10.011l-9.116-.261a.687.687 0 00-.172.035.676.676 0 00-.103.02.714.714 0 00-.23.15h-.004a.72.72 0 00-.217.515v9.987a.724.724 0 00.726.722l6.536.571a.726.726 0 00.727-.723V13.35a.723.723 0 00-.727-.723l-5.046-.265a.582.582 0 010-1.163l6.115.257h.443a.343.343 0 01.343.343v7.971a.726.726 0 001.452 0v-9.033a.727.727 0 00-.727-.726z"
            ></path>
          </g>
          <defs>
            <clipPath id="clip0_4618_63549">
              <path
                fill="#fff"
                d="M0 0H12V12H0z"
                transform="translate(9.75 9.75)"
              ></path>
            </clipPath>
          </defs>
        </>
      )}
    </svg>
  );
};
