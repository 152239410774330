// #region Global Imports
import styled from "styled-components";


export type ThProps = {
  info?: boolean;
  otherInfo?:boolean
};
export const Th = styled.th<ThProps>(
  ({ info,otherInfo }) => `
  background-color: #f9fafb !important;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #667085;
  border-bottom: 1px solid #eaecf0;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  padding: ${info ? '12px 18px 12px 0 !important' : otherInfo ? "10px" : '12px 18px !important'};
  width: max-content;
  `
);

export const Table = styled.table`
  border: 1px solid #eaecf0;
  border-radius: 8px;
  border-collapse: separate;
  border-spacing: 0;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
`;

export const OtherInfo = styled.div`
background-color:rgb(127, 86, 217);
border-radius:5px;
width:10px; height:10px;
cursor:pointer;
`;

export const Tr = styled.tr`
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  // cursor: pointer;
  :last-child {
    border-color: transparent;
  }
  :first-child td:first-child {
    border-top-left-radius: 8px;
  }
  :last-child td:first-child {
    border-bottom-left-radius: 8px;
  }
  :first-child td:last-child {
    border-top-right-radius: 8px;
  }
  :last-child td:last-child {
    border-bottom-right-radius: 8px;
  }
`;
export const THead = styled.thead``;
export const TBody = styled.tbody`
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
`;
export const TFoot = styled.tfoot``;


export type TdProps = {
  info?: boolean;
  otherInfo?:boolean
};
export const Td = styled.td<TdProps>(
  ({ info,otherInfo }) => `
  vertical-align: middle;
  width: ${otherInfo ? "max-content" : "auto"};
  padding: ${info ? '12px 18px 12px 0 !important' : otherInfo ? "10px" : '12px 18px !important'};
  `
);
export const UserSection = styled.div`
  display: flex;
  flex-direction: row;
`;
export const TdText = styled.span`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #1d2939;
  width: max-content;
`;

export const TdAnchor = styled.a`
  font-family: "Inter";
  text-decoration: none;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #1d2939;
  width: max-content;
  &:hover {
    color: #7f56d9;
    font-weight: 700;
  }
`;

export const TdEmail = styled.span`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #667085;
`;

export const MenuAnchor = styled.a`
  font-family: "Inter";
  font-style: normal;
  text-decoration: none;
  width: max-content;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #344054;
  margin-bottom: 10px;
  background: #fff;
  outline: none;
  border: none;
  &:hover {
    color: #7f56d9;
  }
`;

export const MenuText = styled.button`
  font-family: "Inter";
  font-style: normal;
  width: max-content;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  padding: 0;
  color: #344054;
  margin-bottom: 10px;
  background: #fff;
  outline: none;
  border: none;
  &:hover {
    color: #7f56d9;
  }
`;

export const MenuCon = styled.span`
  cursor: pointer;
  background-color: #fff;
  position: absolute;
  border: 1px solid #d7dfe9;
  box-sizing: border-box;
  border-radius: 4px;
  margin-top: 10px;
  zindex: 99;
  right: 43px;
  padding: 10px;
  z-index: 99;
`;

export type StatusProps = {
  background?: string;
  color: string;
};
export const Status = styled.div<StatusProps>(
  ({ background, color }) => `
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 2px 10px;
    height: 20px;
    cursor:pointer;
    width: max-content;
    background: ${background};
    border-radius: 10px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    color: ${color};
    -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  `
);
