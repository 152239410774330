import React, { useState } from "react";
import { FormInput } from "../FormInput";
import { Field } from "formik";
import { Label } from "../FormInput/styled";

interface PasswordInputProps {
  name: string;
  id: string;
  label?: string;
  value: string;
  onChange: any;
  error: any;
  errorMessage: any;
}

export const PasswordInput: React.FC<PasswordInputProps> = (props) => {
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div style={{ position: "relative" }} className="password-container">
      <>
        <Label>{props.label}</Label>
        <Field
          type={showPassword ? "text" : "password"}
          placeholder="Enter your password"
          id={props.id}
          label={props.label}
          name={props.name}
          onChange={props.onChange}
          value={props.value}
          className={` form-input`}
        />
      </>
      <div
        style={{ color: "red", fontSize: 12, position: "absolute", top: 68 }}
      >
        {props.errorMessage || props.error}
      </div>
      <div
        style={{ position: "absolute", top: 35, right: 20 }}
        className="password-toggle"
        onClick={togglePasswordVisibility}
      >
        {!showPassword ? (
          <img
            width={18}
            height={18}
            alt="icon"
            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAABgUlEQVR4nO3UTUtVURTG8R9aZChBIWmhUEEQac4Swm/QsEIQ7yCivoQTm0Wz6AvUQDEbhaQYBUJB83IQvgwcqIMgiAp0ICob1onNGeS53gsS+Ic9OHuv9TxnrbP24ZgGuYQxvMc6tvAbq3iFh+g4jHAPprGDvQPWTzxGW1XxIfyK5G28xB30hkh64+t4hIXM6BvOVDG4FwmvcbFC/C18jdZ1Va2iXX204LSj5Br6mqjXjxv5xnf8ibFslCvxPX7km5PxYeejn4elBR9CK2n+5UJUkQ6eNGDwLDSSVnf5cDib6adorUP4JJ5n+bfLAeexWLqhC3GhDuImPpdy53CqCDiXiS/Grd2M5x3MoIarMevt2U2exW7EbuBB1uq5ogujmXiqJHEW7yr8h4o1HTnFiBYmA6KUWhaQOIGlCHqBt1jLBJcxFW+5F1XmXMbdf/X1fiSuhFlBYVDQmf0YB9XBl0iqlfbLBmKs095EPQbj0ZrWCgaptW8wogl8wsdmCP0/7APj9XeNzVdGIQAAAABJRU5ErkJggg=="
          />
        ) : (
          <img
            alt="icon"
            width={18}
            height={18}
            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAABH0lEQVR4nO2UvU5CQRCFv4poxELFzkIT8TVAan9ewehDGJFefBsE30ILLbil2lzt4QEwk5xCNzOXxcJQ8CWT3MycM7uZ3buwYtnZBq6AIVAAU0Wh3KU0C7MO9NRsNidMcytPFnvAc0bjWRKvwMG85kfAl2MeAC1gQ9HWiFKdeZtR813gwzFdV2yo6+jfgYYnfgx2btSAe+ATKIG+csbI8T2QcB7MtaV636lZzjgOvKc/FygCUV310qlZztgMvON/XeAsELUrRnSnWifwnqTn4F27oWo1LVI6h1x1OX7R0BVLxV1ieo7+DdiJDM3gRxvpttQVnWDn5j0k46l4+sNT8QLsk8maRjPNaDwBbuRZmC3gQoc2VrOJvgeqmWbFEvMNriXJVcp1zQIAAAAASUVORK5CYII="
          />
        )}
      </div>
    </div>
  );
};
