import * as React from "react";

interface ICrownIconProps {
  color: string;
  style: object;
}

export const CrownIcon: React.FunctionComponent<ICrownIconProps> = (props) => {
  return (
    <svg
      style={props.style}
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="8"
      fill="none"
      viewBox="0 0 12 8"
    >
      <path
        fill="url(#paint0_linear_4019_3990)"
        d="M11.383.879a1.206 1.206 0 00-.578-.047c-.194.031-.372.11-.512.224L9 2.112 6.707.24A1.124 1.124 0 006 0c-.265 0-.52.086-.707.24L3 2.111 1.707 1.056a1.085 1.085 0 00-.512-.223 1.21 1.21 0 00-.578.046.984.984 0 00-.448.3.715.715 0 00-.169.455v4.324c0 .541.264 1.06.733 1.443S1.837 8 2.5 8h7c.663 0 1.298-.216 1.767-.599.469-.383.732-.902.733-1.443V1.634a.715.715 0 00-.168-.454.983.983 0 00-.449-.301z"
      ></path>
      <defs>
        <linearGradient
          id="paint0_linear_4019_3990"
          x1="6"
          x2="6"
          y1="0"
          y2="8"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FDD33E"></stop>
          <stop offset="1" stopColor="#B47A0A"></stop>
        </linearGradient>
      </defs>
    </svg>
  );
};
