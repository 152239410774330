import React from "react";

type Props = {
  style?: object;
  // onClick?: any;
};

export const DotIcon = (props: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
      style={props.style}
      // onClick={() => props.onClick()}
    >
      <path
        stroke="#3F4247"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M12 13a1 1 0 100-2 1 1 0 000 2zM12 6a1 1 0 100-2 1 1 0 000 2zM12 20a1 1 0 100-2 1 1 0 000 2z"
      ></path>
    </svg>
  );
};
