import React from "react";
import { Form } from "react-bootstrap";

interface IntakeMonthsSelectorProps {
  selectedMonths: string[];
  onSave: (selected: string[]) => void;
}

export const IntakeMonthsSelector: React.FC<IntakeMonthsSelectorProps> = ({
  selectedMonths,
  onSave,
}) => {
  const allMonths: string[] = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    const updatedSelection = [...selectedMonths];

    if (updatedSelection.includes(value)) {
      const index = updatedSelection.indexOf(value);
      updatedSelection.splice(index, 1);
    } else {
      updatedSelection.push(value);
    }

    onSave(updatedSelection);
  };

  return (
    <Form>
      <Form.Group controlId="intakeMonths">
        <Form.Label>Select Intake Months</Form.Label>
        <div className="d-flex flex-row flex-wrap">
          {allMonths.map((month) => (
            <Form.Check
              key={month}
              style={{ margin: "0 10px 4px 0" }}
              type="checkbox"
              label={month}
              id={`checkbox-${month}`}
              value={month}
              checked={selectedMonths.includes(month)}
              onChange={handleChange}
            />
          ))}
        </div>
      </Form.Group>
    </Form>
  );
};
