import React from "react";
import { Modal, Tab, Tabs } from "react-bootstrap";
import DocumentCard from "../DocumentPage/DocumentCard";
import { NotAddedText, Pointer } from "../DocumentPage/style";
import moment from "moment";
import { ButtonComponent } from "../ButtonComponent";
import { AddBtn, AddDocument } from "../ApplicationPage/styled";

type Props = {
  show: boolean;
  handleClose: any;
  documents: any;
  selectedStudent: any;
  reloadDocuments: any;
};

export const StudentDocModal = (props: Props) => {
  const NoDocComponent = () => {
    return (
      <div className="d-flex justify-content-center align-items-center flex-column h-100">
        <div
          style={{
            backgroundImage: `url(${"https://storage.googleapis.com/uapply/no-document.png"})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            width: 230,
            height: 205,
            borderRadius: 8,
          }}
        />
        <NotAddedText>Documents aren’t added yet</NotAddedText>
      </div>
    );
  };

  return (
    <Modal
      show={props.show}
      onHide={props.handleClose}
      backdrop="static"
      keyboard={false}
      size="lg"
      className="modal fade"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title className="modal-title">
          {props.selectedStudent.firstName}'s Documents
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="w-100" style={{ marginTop: 10 }}>
          <Tabs defaultActiveKey="education" id="uncontrolled-tab-example">
            <Tab eventKey="education" title="Education">
              {!props.documents.filter(
                (z: any) => z.documentType === "Education"
              ).length ? (
                <div className="d-flex flex-column align-items-center jsutify-content-center">
                  <NoDocComponent />
                  <AddDocument
                    href={`/student/documents/${props.selectedStudent.id}`}
                  >
                    <AddBtn>+</AddBtn> Add documents
                  </AddDocument>
                </div>
              ) : (
                props.documents
                  .filter(
                    (z: any) => z.documentType.toLowerCase() === "education"
                  )
                  .map((a: any, key: any) => {
                    const imageExtensions = [".jpg", ".jpeg", ".png"];
                    const pdfExtension = ".pdf";

                    const imageUrls: any = [];
                    const pdfUrls: any = [];

                    const urls = a.blobInfo.map((blob: any) => {
                      return { url: blob.blobUrl, name: blob.name };
                    });

                    urls.forEach((url: any) => {
                      const cleanedUrl = url.url.split("?")[0]; // Remove query parameters
                      const extension = cleanedUrl
                        .slice(cleanedUrl.lastIndexOf("."))
                        .toLowerCase();

                      if (imageExtensions.includes(extension)) {
                        imageUrls.push({ url: url.url, name: url.name });
                      } else if (extension === pdfExtension) {
                        pdfUrls.push({ url: url.url, name: url.name });
                      }
                    });

                    return (
                      <DocumentCard
                        name={a.tag.qualification}
                        reloadDocuments={props.reloadDocuments}
                        document={a}
                        images={imageUrls}
                        pdf={pdfUrls}
                        data={[
                          {
                            text: a.tag.institution,
                            pointer: <Pointer />,
                            fontWeight: 600,
                          },
                          { text: a.tag.country, fontWeight: 600 },
                        ]}
                        data2={[
                          {
                            text: `${moment(a.tag.startYear).format(
                              "YYYY"
                            )}-${moment(a.tag.endYear).format("YYYY")}`,
                            pointer: <Pointer />,
                            fontWeight: 400,
                          },
                          { text: a.tag.discipline, fontWeight: 400 },
                        ]}
                        attachments="2"
                        score={a.tag.score}
                        key={a.name}
                      />
                    );
                  })
              )}
            </Tab>

            <Tab eventKey="EnglishEntranceTest" title="English Entrance Test">
              {!props.documents.filter(
                (z: any) => z.documentType === "EnglishEntranceTest"
              ).length ? (
                <div className="d-flex flex-column align-items-center jsutify-content-center">
                  <NoDocComponent />
                  <AddDocument
                    href={`/student/documents/${props.selectedStudent.id}`}
                  >
                    <AddBtn>+</AddBtn> Add documents
                  </AddDocument>
                </div>
              ) : (
                props.documents
                  .filter((z: any) => z.documentType === "EnglishEntranceTest")
                  .map((a: any, key: any) => {
                    const imageExtensions = [".jpg", ".jpeg", ".png"];
                    const pdfExtension = ".pdf";

                    const imageUrls: any = [];
                    const pdfUrls: any = [];

                    const urls = a.blobInfo.map((blob: any) => {
                      return { url: blob.blobUrl, name: blob.name };
                    });

                    urls.forEach((url: any) => {
                      const cleanedUrl = url.url.split("?")[0]; // Remove query parameters
                      const extension = cleanedUrl
                        .slice(cleanedUrl.lastIndexOf("."))
                        .toLowerCase();

                      if (imageExtensions.includes(extension)) {
                        imageUrls.push({ url: url.url, name: url.name });
                      } else if (extension === pdfExtension) {
                        pdfUrls.push({ url: url.url, name: url.name });
                      }
                    });
                    return (
                      <DocumentCard
                        name={a.tag.testName}
                        reloadDocuments={props.reloadDocuments}
                        document={a}
                        images={imageUrls}
                        pdf={pdfUrls}
                        data={[
                          {
                            text: `Score: ${a.tag.score}`,
                            pointer: <Pointer />,
                            fontWeight: 400,
                          },
                        ]}
                        data2={[
                          {
                            text: `Issued: ${moment(a.tag.issueDate).format(
                              "DD-MM-YYYY"
                            )}`,
                            pointer: <Pointer />,
                            fontWeight: 400,
                          },
                          // { text: "Valid till: 02 Jun 2024", fontWeight: 400 },
                        ]}
                        attachments="2"
                        score={a.tag.score}
                        key={a.name}
                      />
                    );
                  })
              )}
            </Tab>
            <Tab
              eventKey="ProfessionalExperience"
              title="Professional Experience"
            >
              {!props.documents.filter(
                (z: any) => z.documentType === "ProfessionalExperience"
              ).length ? (
                <div className="d-flex flex-column align-items-center jsutify-content-center">
                  <NoDocComponent />
                  <AddDocument
                    href={`/student/documents/${props.selectedStudent.id}`}
                  >
                    <AddBtn>+</AddBtn> Add documents
                  </AddDocument>
                </div>
              ) : (
                props.documents
                  .filter(
                    (z: any) => z.documentType === "ProfessionalExperience"
                  )
                  .map((a: any, key: any) => {
                    const imageExtensions = [".jpg", ".jpeg", ".png"];
                    const pdfExtension = ".pdf";

                    const imageUrls: any = [];
                    const pdfUrls: any = [];

                    const urls = a.blobInfo.map((blob: any) => {
                      return { url: blob.blobUrl, name: blob.name };
                    });

                    urls.forEach((url: any) => {
                      const cleanedUrl = url.url.split("?")[0]; // Remove query parameters
                      const extension = cleanedUrl
                        .slice(cleanedUrl.lastIndexOf("."))
                        .toLowerCase();

                      if (imageExtensions.includes(extension)) {
                        imageUrls.push({ url: url.url, name: url.name });
                      } else if (extension === pdfExtension) {
                        pdfUrls.push({ url: url.url, name: url.name });
                      }
                    });
                    return (
                      <DocumentCard
                        name={a.tag.companyName}
                        reloadDocuments={props.reloadDocuments}
                        document={a}
                        images={imageUrls}
                        pdf={pdfUrls}
                        data={[
                          {
                            text: a.tag.jobTitle,
                            pointer: <Pointer />,
                            fontWeight: 600,
                          },
                          {
                            text: `${moment(a.tag.startDate).format(
                              "DD-MM-YYYY"
                            )}-${
                              a.tag.currentCompany
                                ? "Present"
                                : moment(a.tag.endDate).format("DD-MM-YYYY")
                            }`,
                            fontWeight: 400,
                          },
                        ]}
                        attachments="2"
                        key={a.name}
                      />
                    );
                  })
              )}
            </Tab>

            <Tab eventKey="Finance" title="Finance">
              {!props.documents.filter((z: any) => z.documentType === "Finance")
                .length ? (
                <div className="d-flex flex-column align-items-center jsutify-content-center">
                  <NoDocComponent />
                  <AddDocument
                    href={`/student/documents/${props.selectedStudent.id}`}
                  >
                    <AddBtn>+</AddBtn> Add documents
                  </AddDocument>
                </div>
              ) : (
                props.documents
                  .filter((z: any) => z.documentType === "Finance")
                  .map((a: any, key: any) => {
                    const imageExtensions = [".jpg", ".jpeg", ".png"];
                    const pdfExtension = ".pdf";

                    const imageUrls: any = [];
                    const pdfUrls: any = [];

                    const urls = a.blobInfo.map((blob: any) => {
                      return { url: blob.blobUrl, name: blob.name };
                    });

                    urls.forEach((url: any) => {
                      const cleanedUrl = url.url.split("?")[0]; // Remove query parameters
                      const extension = cleanedUrl
                        .slice(cleanedUrl.lastIndexOf("."))
                        .toLowerCase();

                      if (imageExtensions.includes(extension)) {
                        imageUrls.push({ url: url.url, name: url.name });
                      } else if (extension === pdfExtension) {
                        pdfUrls.push({ url: url.url, name: url.name });
                      }
                    });

                    return (
                      <DocumentCard
                        name={a.subType}
                        reloadDocuments={props.reloadDocuments}
                        document={a}
                        images={imageUrls}
                        pdf={pdfUrls}
                        attachments="2"
                        key={a.name}
                      />
                    );
                  })
              )}
            </Tab>
            <Tab eventKey="Travel" title="Travel">
              {!props.documents.filter((z: any) => z.documentType === "Travel")
                .length ? (
                <div className="d-flex flex-column align-items-center jsutify-content-center">
                  <NoDocComponent />
                  <AddDocument
                    href={`/student/documents/${props.selectedStudent.id}`}
                  >
                    <AddBtn>+</AddBtn> Add documents
                  </AddDocument>
                </div>
              ) : (
                props.documents
                  .filter((z: any) => z.documentType === "Travel")
                  .map((a: any, key: any) => {
                    const imageExtensions = [".jpg", ".jpeg", ".png"];
                    const pdfExtension = ".pdf";

                    const imageUrls: any = [];
                    const pdfUrls: any = [];

                    const urls = a.blobInfo.map((blob: any) => {
                      return { url: blob.blobUrl, name: blob.name };
                    });

                    urls.forEach((url: any) => {
                      const cleanedUrl = url.url.split("?")[0]; // Remove query parameters
                      const extension = cleanedUrl
                        .slice(cleanedUrl.lastIndexOf("."))
                        .toLowerCase();

                      if (imageExtensions.includes(extension)) {
                        imageUrls.push({ url: url.url, name: url.name });
                      } else if (extension === pdfExtension) {
                        pdfUrls.push({ url: url.url, name: url.name });
                      }
                    });

                    return (
                      <DocumentCard
                        name={a.subType}
                        reloadDocuments={props.reloadDocuments}
                        document={a}
                        images={imageUrls}
                        pdf={pdfUrls}
                        attachments="2"
                        key={a.name}
                      />
                    );
                  })
              )}
            </Tab>
            <Tab eventKey="Other" title="Other">
              {!props.documents.filter((z: any) => z.documentType === "Other")
                .length ? (
                <NoDocComponent />
              ) : (
                <>
                  {" "}
                  {props.documents
                    .filter((z: any) => z.documentType === "Other")
                    .map((a: any, key: any) => {
                      const imageExtensions = [".jpg", ".jpeg", ".png"];
                      const pdfExtension = ".pdf";

                      const imageUrls: any = [];
                      const pdfUrls: any = [];

                      const urls = a.blobInfo.map((blob: any) => {
                        return { url: blob.blobUrl, name: blob.name };
                      });

                      urls.forEach((url: any) => {
                        const cleanedUrl = url.url.split("?")[0]; // Remove query parameters
                        const extension = cleanedUrl
                          .slice(cleanedUrl.lastIndexOf("."))
                          .toLowerCase();

                        if (imageExtensions.includes(extension)) {
                          imageUrls.push({ url: url.url, name: url.name });
                        } else if (extension === pdfExtension) {
                          pdfUrls.push({ url: url.url, name: url.name });
                        }
                      });

                      return (
                        <div key={key}>
                          <DocumentCard
                            name={a.subType}
                            data={[
                              {
                                text: `${a.tag.name}`,
                                fontWeight: 400,
                              },
                            ]}
                            // uploading={uploading}
                            reloadDocuments={props.reloadDocuments}
                            document={a}
                            images={imageUrls}
                            pdf={pdfUrls}
                            attachments="2"
                            key={a.name}
                          />
                        </div>
                      );
                    })}
                </>
              )}
            </Tab>
          </Tabs>
        </div>
      </Modal.Body>
      <Modal.Footer>
        {/* <ButtonComponent
            onClick={() => {
           
              props.handleClose();
            }}
            btnType="light"
            loading={false}
            label="Close"
          /> */}
        {/* <ButtonComponent
            onClick={() => {
              props.applyFilter();
              props.handleClose();
            }}
            btnType="dark"
            loading={false}
            label="Apply"
          /> */}
      </Modal.Footer>
    </Modal>
  );
};
