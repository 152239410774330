/* eslint-disable @typescript-eslint/no-unused-vars */
import * as React from "react";
import { Accordion, Dropdown, Tab, Tabs } from "react-bootstrap";
import { ApplicatinCard } from "../ApplicationPage/ApplicationCard";
import { ButtonComponent } from "../ButtonComponent";
import {
  AddDocBtn,
  DetailCard,
  DetailSubtext,
  DetailText,
  DocSection,
  MissingDocText,
  Subtext,
  AnchorTag,
  AttachmentCon,
  DocumentSubtext,
} from "./styled";
import { useParams } from "react-router-dom";
import {
  deleteStudentDocument,
  getApplicationById,
  getStudentDocById,
  truncateString,
  updateApplication,
  updateDocument,
  updateStudent,
} from "../../Services/Function";
import { ReadyToApply } from "../ReadyToApply";
import { ViewerModal } from "../ViewerModal";
import { Loader } from "../Loader/Loader";
import { UploadDocument } from "../UploadDocument";
import { DeleteIcon } from "../../Assets";
import { useSelector } from "react-redux";
import { UploadLoader } from "../Loader/UploadLoader";

interface IApplicationDetailsProps {
  student: any;
  users: any;
  reloadStudents: any;
}

export const ApplicationDetails: React.FunctionComponent<
  IApplicationDetailsProps
> = (props) => {
  const { id, applicationId } = useParams<{
    id: string;
    applicationId: string;
  }>();
  const [application, setapplication] = React.useState<any>("");

  const [documents, setdocuments] = React.useState<any>([]);
  const [modalShow, setmodalShow] = React.useState<any>(null);
  const [viewerContent, setViewerContent] =
    React.useState<React.ReactNode | null>(null);
  const [unconditionalOffer, setunconditionalOffer] = React.useState(false);
  const [images, setimages] = React.useState("");
  const [pdfs, setpdfs] = React.useState("");
  const [loading, setloading] = React.useState(false);
  const [uploadModal, setuploadModal] = React.useState(false);
  const [selectedDocumennt, setselectedDocumennt] = React.useState({});
  const [uploading, setuploading] = React.useState<any>(null);

  const auth = useSelector((state: any) => state.auth);
  const data = useSelector((state: any) => state.data);

  React.useEffect(() => {
    async function fetchData() {
      setloading(true);
      const res = await getApplicationById(applicationId);
      const resp = await getStudentDocById(id);
      setdocuments(resp.data);
      setapplication(res.data);
      setloading(false);
    }
    fetchData();
  }, [applicationId, id]);

  const reloadApplication = async () => {
    setloading(true);
    const res = await getApplicationById(applicationId);
    const resp = await getStudentDocById(id);
    setdocuments(resp.data);
    setapplication(res.data);
    setloading(false);
  };

  const reloadDocs = async () => {
    const resp = await getStudentDocById(id);
    setdocuments(resp.data);
  };

  const requireDocuments: any = {
    Undergraduate: ["Higher Secondary School", "Finance", "Others"],
    Postgraduate: ["Higher Secondary School", "Undergraduate", "Finance"],
    Doctorate: [
      "Higher Secondary School",
      "Postgraduate",
      "Undergraduate",
      "Finance",
      "Others",
    ],
  };

  let required: any = [];

  if (requireDocuments.hasOwnProperty(application.courseLevel)) {
    required = requireDocuments[application.courseLevel];
  }

  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        <div>
          <div
            className="d-flex justify-content-between"
            style={{ margin: "16px 0" }}
          >
            <Subtext
              style={{ fontSize: 20, fontStyle: "normal", fontWeight: 600 }}
              color="#5E6C84"
              fontWeight={400}
            >
              {application.applicationId}
            </Subtext>
            <div className="d-flex flex-row">
              {application.portalApplicationStatus?.applicationPhases.filter(
                (z: any) => z.isCurrent
              )[0]?.status === "Conditional offer" ? (
                <Dropdown>
                  <Dropdown.Toggle
                    disabled={
                      (props.student.onHold && !application.finalChoice) ||
                      application.rejected
                    }
                    className="dd-btn"
                    id="dropdown-custom-components"
                  >
                    Select offer
                  </Dropdown.Toggle>

                  <Dropdown.Menu className="dd-custom-menu">
                    <Dropdown.Item
                      className="dd-custom-items"
                      onClick={async () => {
                        setmodalShow("readytoApply");
                      }}
                    >
                      Conditional offer
                    </Dropdown.Item>
                    <Dropdown.Item
                      className="dd-custom-items"
                      onClick={async () => {
                        setunconditionalOffer(true);
                        setmodalShow("readytoApply");
                      }}
                    >
                      Unconditional offer
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              ) : (
                <ButtonComponent
                  style={{ borderRadius: 20, width: "max-content" }}
                  btnType="dark"
                  loading={loading}
                  onClick={async () => {
                    setloading(true);
                    if (
                      application.portalApplicationStatus?.applicationPhases.filter(
                        (z: any) => z.isCurrent
                      )[0].status === "Done"
                    ) {
                      let stages =
                        application?.portalApplicationStatus?.applicationPhases;
                      const currentIndex = stages.findIndex(
                        (stage: { phaseState: string }) =>
                          stage.phaseState === "AwaitingResponseStudent"
                      );

                      if (currentIndex !== -1) {
                        // Update 'AwaitingResponseStudent' to 'Completed' and set isCurrent to false
                        stages[currentIndex].phaseState = "Completed";
                        stages[currentIndex].isCurrent = false;
                        stages[currentIndex].isPrevious = true;

                        // Check if the next stage exists and update its phaseState to 'AwaitingResponseStudent' with isCurrent set to true
                        if (currentIndex < stages.length - 1) {
                          stages[currentIndex + 1].phaseState =
                            "AwaitingResponseStudent";
                          stages[currentIndex + 1].isCurrent = true;
                        }
                        if (currentIndex >= 0) {
                          stages[currentIndex - 1].isPrevious = false;
                        }
                      }

                      await updateApplication(application._id, {
                        portalApplicationStatus: { applicationPhases: stages },
                        phaseChanged: true,
                        editor: auth.userDetails,
                      });
                      await updateStudent(application.studentId, {
                        stage: "Enrolled",
                      });
                      reloadApplication();
                    } else {
                      setmodalShow("readytoApply");
                    }

                    setloading(false);
                  }}
                  disabled={
                    application?.portalApplicationStatus?.applicationPhases.filter(
                      (stage: { phaseState: string; isPrevious: boolean }) =>
                        stage.phaseState === "Completed" && stage.isPrevious
                    )[0].status === "Done" ||
                    (props.student.onHold && !application.finalChoice)
                  }
                  label={
                    application.portalApplicationStatus?.applicationPhases.filter(
                      (z: any) => z.isCurrent
                    )[0]?.status === "Submitted"
                      ? "Ready to apply"
                      : application?.portalApplicationStatus?.applicationPhases.filter(
                            (stage: {
                              phaseState: string;
                              isPrevious: boolean;
                            }) =>
                              stage.phaseState === "Completed" &&
                              stage.isPrevious
                          )[0].status === "Done"
                        ? "Enrolled"
                        : application.portalApplicationStatus?.applicationPhases.filter(
                            (z: any) => z.isCurrent
                          )[0]?.status
                  }
                />
              )}
            </div>
          </div>
          {application ? (
            <ApplicatinCard
              applicationDetail
              key={""}
              reloadApplication={reloadApplication}
              applications={application}
              student={props.student}
              docUploaded={
                required.filter(
                  (a: any) =>
                    !documents.find((z: any) =>
                      z.documentType !== "Issued Document"
                        ? z?.tag.qualification === a &&
                          z.docState !== "Not Upoaded"
                        : []
                    )
                ).length
              }
              reloadStudents={props.reloadStudents}
            />
          ) : null}

          {/* {[application].map((a: any, key: any) => (
        <ApplicatinCard key={key} applications={a} />
      ))} */}
          <DetailCard className="row">
            <div className="-dlex flex-column col-md-3">
              <DetailText>Filled by:</DetailText>
              <DetailSubtext>
                {application?.managedBy
                  ? props.users.filter(
                      (z: any) => application?.managedBy === z.id
                    )[0]?.name
                  : "N/A"}
              </DetailSubtext>
            </div>

            <div className="-dlex flex-column col-md-3">
              <DetailText>Intake:</DetailText>
              <DetailSubtext>
                {application.intakeMonth} {application.intakeYear}
              </DetailSubtext>
            </div>
            <div className="-dlex flex-column col-md-3">
              <DetailText>Campus:</DetailText>
              <DetailSubtext>
                {application?.campus?.name || "N/A"}
              </DetailSubtext>
            </div>
            <div className="-dlex flex-column col-md-3">
              <DetailText>Sponsor:</DetailText>
              <DetailSubtext>
                {props.student?.preference?.fundingSource || "N/A"}
              </DetailSubtext>
            </div>
          </DetailCard>

          <Tabs defaultActiveKey="requireDocs" id="uncontrolled-tab-example">
            {uploading ? `Uploading ${uploading?.documentType}` : null}
            <Tab eventKey="requireDocs" title="Required Documents">
              {/* {documents
            .filter((z: any) => !z.blobInfo.length)
            .map((a: any) => (
              <DocSection className="d-flex justify-content-between">
                <Subtext color="#5E6C84" fontWeight={600}>
                  {a.documentType}
                </Subtext>
                <DocSection>+ Add Docs.</AddDocBtn>
              </DocSection>
            ))} */}
              <div style={{ position: "relative" }}>
                {/* {required.filter(
              (a: any) =>
                !documents.find((z: any) =>
                  z.documentType !== "Issued Document"
                    ? z?.tag.qualification === a && z.docState !== "Not Upoaded"
                    : []
                )
            ).length ? (
              <MissingDocs color="#FF576A">
                Missing Documents (
                {
                  required.filter(
                    (a: any) =>
                      !documents.find((z: any) => {
                        if (z.documentType === "Education") {
                          return (
                            z?.tag.qualification === a &&
                            z.docState !== "Not Upoaded"
                          );
                        } else {
                          return (
                            z.documentType === a && z.docState !== "Not Upoaded"
                          );
                        }
                      })
                  ).length
                }
                )
              </MissingDocs>
            ) : (
              <MissingDocs color="#00875A">No Docs Pending</MissingDocs>
            )} */}
                {required.map((a: any, key: string) => (
                  <DocSection
                    key={key}
                    className="d-flex justify-content-between"
                  >
                    <Subtext color="#5E6C84" fontWeight={600}>
                      {a}
                    </Subtext>
                    <div className="d-flex flex-row">
                      {!documents.find((z: any) => {
                        if (z.documentType === "Education") {
                          return (
                            z?.tag.qualification === a &&
                            z.docState !== "Not Upoaded"
                          );
                        } else {
                          return (
                            z.documentType === a && z.docState !== "Not Upoaded"
                          );
                        }
                      }) ? (
                        <div className="d-flex align-items-center">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="17"
                            viewBox="0 0 16 17"
                            fill="none"
                            style={{ marginRight: 4 }}
                          >
                            <g clip-path="url(#clip0_974_39813)">
                              <path
                                d="M8 0.75C3.72501 0.75 0.25 4.22501 0.25 8.5C0.25 12.775 3.72501 16.25 8 16.25C12.275 16.25 15.75 12.775 15.75 8.5C15.75 4.22501 12.275 0.75 8 0.75ZM7.25 12.25V11.705C7.25 11.29 7.58499 10.955 8 10.955C8.41501 10.955 8.75 11.29 8.75 11.705V12.25C8.75 12.665 8.41501 13 8 13C7.58499 13 7.25 12.665 7.25 12.25ZM8.75 9.51999C8.75 9.92999 8.41501 10.27 8 10.27C7.58499 10.27 7.25 9.92999 7.25 9.51999V4.75C7.25 4.33499 7.58499 4 8 4C8.41501 4 8.75 4.33499 8.75 4.75V9.51999Z"
                                fill="#A5ADBA"
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0_974_39813">
                                <rect
                                  width="16"
                                  height="16"
                                  fill="white"
                                  transform="translate(0 0.5)"
                                />
                              </clipPath>
                            </defs>
                          </svg>
                          <MissingDocText>Documents Missing</MissingDocText>
                        </div>
                      ) : null}
                      <AddDocBtn
                        href={`/student/documents/${props.student.id}?addDoc=true`}
                      >
                        {required.filter(
                          (a: any) =>
                            !documents.find((z: any) => {
                              if (z.documentType === "Education") {
                                return (
                                  z?.tag.qualification === a &&
                                  z.docState !== "Not Upoaded"
                                );
                              } else {
                                return (
                                  z.documentType === a &&
                                  z.docState !== "Not Upoaded"
                                );
                              }
                            })
                        ).length
                          ? "+ Add Docs."
                          : "Uploaded"}{" "}
                      </AddDocBtn>
                    </div>
                  </DocSection>
                ))}
              </div>
            </Tab>
            <Tab eventKey="issuedDocs" title="Issued Documents">
              {documents
                .filter(
                  (a: any) =>
                    a.documentType === "Issued Document" &&
                    a.applicationId === application._id
                )
                .map((z: any) => {
                  const imageExtensions = [".jpg", ".jpeg", ".png"];
                  const pdfExtension = ".pdf";

                  const imageUrls: any = [];
                  const pdfUrls: any = [];

                  const urls = z.blobInfo.map((blob: any) => {
                    return { url: blob.blobUrl, name: blob.name };
                  });

                  urls.forEach((url: any) => {
                    const cleanedUrl = url.url.split("?")[0]; // Remove query parameters
                    const extension = cleanedUrl
                      .slice(cleanedUrl.lastIndexOf("."))
                      .toLowerCase();

                    if (imageExtensions.includes(extension)) {
                      imageUrls.push({ url: url.url, name: url.name });
                    } else if (extension === pdfExtension) {
                      pdfUrls.push({ url: url.url, name: url.name });
                    }
                  });
                  return (
                    <>
                      <DocSection style={{ border: "none" }}>
                        <div className="d-flex w-100 justify-content-between">
                          <Subtext color="#5E6C84" fontWeight={600}>
                            {z.subType}
                          </Subtext>
                          <AddDocBtn
                            onClick={() => {
                              setselectedDocumennt(z);
                              setuploadModal(true);
                            }}
                          >
                            + Add docs
                          </AddDocBtn>
                        </div>
                        <AttachmentCon>
                          <Accordion defaultActiveKey="" flush>
                            <Accordion.Item eventKey="0">
                              <Accordion.Header>
                                {data.uploading === z.id ? (
                                  <UploadLoader height="50px" width="50px" />
                                ) : (
                                  <DocumentSubtext fontWeight={600}>
                                    {`Attachments (${
                                      imageUrls.length + pdfUrls.length
                                    })`}
                                  </DocumentSubtext>
                                )}
                              </Accordion.Header>
                              <Accordion.Body>
                                {![...imageUrls, ...pdfUrls].length ? (
                                  <p>No Document uploded.</p>
                                ) : (
                                  <ul>
                                    {[...imageUrls].map((b: any, key: any) => (
                                      <li
                                        className="d-flex justify-content-between"
                                        key={key}
                                      >
                                        <AnchorTag
                                          onClick={() => {
                                            setimages(b.url);
                                            setpdfs("");
                                            setmodalShow("docViewer");
                                          }}
                                        >
                                          {truncateString(b.name)}
                                        </AnchorTag>
                                        <div
                                          onClick={async () => {
                                            await updateDocument(z.id, {
                                              blobInfo: z.blobInfo.filter(
                                                (t: any) => t.blobUrl !== b.url
                                              ),
                                            });
                                            reloadDocs();
                                          }}
                                          style={{ cursor: "pointer" }}
                                        >
                                          <DeleteIcon />
                                        </div>
                                      </li>
                                    ))}
                                    {[...pdfUrls].map((b: any, key: any) => (
                                      <li
                                        className="d-flex justify-content-between"
                                        key={key}
                                      >
                                        <AnchorTag
                                          onClick={() => {
                                            setpdfs(b.url);
                                            setimages("");
                                            setmodalShow("docViewer");
                                          }}
                                        >
                                          {b.name}
                                        </AnchorTag>
                                        <div
                                          onClick={async () => {
                                            await updateDocument(z.id, {
                                              blobInfo: z.blobInfo.filter(
                                                (t: any) => t.blobUrl === b.url
                                              ),
                                            });
                                            reloadDocs();
                                          }}
                                          style={{ cursor: "pointer" }}
                                        >
                                          <DeleteIcon />
                                        </div>
                                      </li>
                                    ))}
                                  </ul>
                                )}
                              </Accordion.Body>
                            </Accordion.Item>
                          </Accordion>
                        </AttachmentCon>
                      </DocSection>
                    </>
                  );
                })}
            </Tab>
            {/* <Tab eventKey="summary" title="Summary"></Tab>
            <Tab eventKey="statusLog" title="Status Log"></Tab> */}
          </Tabs>
        </div>
      )}

      {modalShow === "readytoApply" ? (
        <ReadyToApply
          show={modalShow === "readytoApply"}
          reloadApplication={reloadApplication}
          documents={documents.filter(
            (z: any) =>
              z.documentType === "Issued Document" &&
              z.applicationId === applicationId
          )}
          application={application}
          handleClose={() => {
            setmodalShow(null);
            setunconditionalOffer(false);
          }}
          reloadDocuments={reloadDocs}
          unconditionalOffer={unconditionalOffer}
          studentId={id}
          reloadStudents={props.reloadStudents}
        />
      ) : null}
      {modalShow === "docViewer" ? (
        <ViewerModal
          pdf={pdfs}
          show={modalShow === "docViewer"}
          images={images}
          handleClose={() => setmodalShow(null)}
          openViewer={setViewerContent}
        />
      ) : null}
      {uploadModal ? (
        <UploadDocument
          show={uploadModal}
          handleClose={() => setuploadModal(false)}
          document={selectedDocumennt}
          reloadDocuments={reloadDocs}
        />
      ) : null}
    </div>
  );
};
