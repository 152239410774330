import React from "react";
import styled from "styled-components";
import { Button } from "react-bootstrap";

const CardWrapper = styled.div`
  padding: 1rem;
  border: 1px solid #eaeaea;
  border-radius: 8px;
  margin-bottom: 1rem;
  background: #fff;
`;

const Status = styled.div<{ status: string }>`
  color: ${({ status }) => {
    switch (status) {
      case "submitted":
        return "orange";
      case "conditional":
        return "blue";
      case "done":
        return "green";
      default:
        return "black";
    }
  }};
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

type CardProps = {
  applicationId: string;
  date: string;
  status: "submitted" | "conditional" | "done";
};

export const Card: React.FC<CardProps> = ({ applicationId, date, status }) => {
  return (
    <CardWrapper>
      <Header>
        <div>{applicationId}</div>
        <Status status={status}>{date}</Status>
      </Header>
      <Button variant="light" size="sm">
        Details
      </Button>
    </CardWrapper>
  );
};
