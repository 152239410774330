/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useRef, useState } from "react";
import ReactQuill from "react-quill";
import { Formik, Form, FormikHelpers } from "formik";
import { ButtonComponent, FormInput } from "../Components";
import {
  createNews,
  dataURLtoFile,
  getNews,
  optimizeImage,
  optimizePDF,
} from "../Services/Function";
import { uploadImage } from "../firebase/firebase";
import {
  UploadInput,
  UploadSubtext,
  UploadText,
} from "../Components/DocumentPage/style";
import Quill from "quill"; // Import Quill from 'quill' package

type Props = {};

export const News = (props: Props) => {
  const [loading, setloading] = useState(false);
  const [blogBody, setblogBody] = useState("");
  const [imageAsFile, setimageAsFile] = React.useState<
    (string | ArrayBuffer)[]
  >([]);
  const [filePreviewUrls, setFilePreviewUrls] = React.useState<
    (string | ArrayBuffer)[]
  >([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isImage, setIsImage] = React.useState<boolean[]>([]);
  const [isPdf, setIsPdf] = React.useState<boolean[]>([]);
  const [addNews, setaddNews] = useState(false);
  const [news, setnews] = useState([]);
  const quillRef = useRef<ReactQuill>(null);

  useEffect(() => {
    async function fetchData() {
      const res = await getNews();
      setnews(res);
      const ImageResize = require("quill-image-resize-module");
      Quill.register("modules/imageResize", ImageResize);
    }
    fetchData();
  }, []);

  async function handleImageAsFile(event: React.ChangeEvent<HTMLInputElement>) {
    const selectedFiles = event.target.files;
    if (selectedFiles) {
      for (let i = 0; i < selectedFiles.length; i++) {
        const selectedFile = selectedFiles[i];

        const reader = new FileReader();
        reader.onload = async () => {
          setFilePreviewUrls((prevFilePreviewUrls: any) => [
            ...prevFilePreviewUrls,
            reader.result,
          ]);
          setIsImage((prevIsImage: any) => [
            ...prevIsImage,
            selectedFile.type.includes("image"),
          ]);
          setIsPdf((prevIsPdf: any) => [
            ...prevIsPdf,
            selectedFile.type.includes("pdf"),
          ]);

          if (selectedFile.type.includes("pdf")) {
            try {
              const optimizedPDFBlob = await optimizePDF(
                selectedFile,
                selectedFile?.name
              );
              setimageAsFile((prev: any) => [...prev, optimizedPDFBlob]);
            } catch (uploadError) {
              console.error("Error uploading PDF:", uploadError);
            }
          } else if (selectedFile.type.includes("image")) {
            try {
              const optimized = await optimizeImage(selectedFile);
              const optimizedFile = await dataURLtoFile(
                optimized,
                selectedFile?.name
              );
              setimageAsFile((prev: any) => [...prev, optimizedFile]);
            } catch (uploadError) {
              console.error("Error uploading PDF:", uploadError);
            }
          }
        };
        reader.readAsDataURL(selectedFile);
      }
    }
  }
  const handleFireBaseUpload = async () => {
    if (imageAsFile) {
      const downloadURL = await uploadImage(imageAsFile);
      return downloadURL;
    }
  };

  const handleImageByUrl = () => {
    const imageURL = prompt("Enter the image URL:");
    if (imageURL && quillRef.current) {
      const editor = quillRef.current.getEditor();
      const index = editor.getSelection()?.index ?? 0;
      editor.insertEmbed(index, "image", imageURL, "user");
    }
  };

  const modules = {
    toolbar: [
      [{ header: "1" }, { header: "2" }, { font: [] }],
      [{ size: [] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],

      ["clean"],
      ["link", "image", "imageByUrl"],
    ],

    clipboard: {
      // toggle to add extra line breaks when pasting HTML:
      matchVisual: false,
    },
  };
  const formats = [
    "header",
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
    "video",
  ];

  return (
    <div className="container">
      <ButtonComponent
        onClick={() => setaddNews(!addNews)}
        label={!addNews ? "Create News" : "Show News"}
        loading={false}
        btnType={"dark"}
        style={{ marginBottom: 20 }}
      />
      {addNews ? (
        <Formik
          initialValues={{
            password: "",
            email: "",
          }}
          onSubmit={async (
            values: any,
            { setSubmitting }: FormikHelpers<any>
          ) => {
            const img: any = await handleFireBaseUpload();
            setloading(true);
            await createNews({
              title: values.title,
              body: blogBody,
              thumbnail: img[0],
              subtitle: values.subtitle,
            });
            setloading(false);
          }}
        >
          <Form style={{ width: "70%" }} className="d-flex flex-column">
            <FormInput
              id="title"
              name="title"
              placeholder=""
              type="text"
              label="Title"
            />
            <FormInput
              id="subtitle"
              name="subtitle"
              placeholder=""
              type="text"
              label="Description"
            />

            <UploadInput style={{ marginBottom: 20 }}>
              <input
                style={{
                  height: "100%",
                  opacity: 0,
                  width: "100%",
                  position: "absolute",
                  cursor: "pointer",
                }}
                type="file"
                accept=".jpg,.jpeg,.png"
                onChange={(e: any) => handleImageAsFile(e)}
              />
              <UploadText>Upload Thumbnail</UploadText>
              <UploadSubtext>
                Drag and drop your file here or click to upload a local file
              </UploadSubtext>
            </UploadInput>
            <div style={{ marginBottom: 20 }}>
              {filePreviewUrls.map((filePreviewUrl: any, i: any) => {
                return (
                  <div style={{ marginRight: 5 }} key={i}>
                    <div
                      onClick={() => {
                        const newData = [...filePreviewUrls];
                        newData.splice(i, 1);
                        setFilePreviewUrls(newData);
                      }}
                      style={{
                        position: "absolute",
                        background: "#fff",
                        width: 20,
                        height: 20,
                        borderRadius: "50%",
                        // top: -10,
                        // left: -10,
                      }}
                      className="d-flex justify-content-center align-items-center"
                    >
                      X
                    </div>
                    <img
                      src={filePreviewUrl as string}
                      alt="Preview"
                      style={{
                        width: 50,
                        height: 70,
                        borderRadius: 8,
                      }}
                    />
                  </div>
                );
              })}
            </div>

            <ReactQuill
              theme="snow"
              ref={quillRef}
              value={blogBody}
              onChange={setblogBody}
              modules={modules}
              formats={formats}
              placeholder="Write your blog post content here..."
            />
            <button onClick={handleImageByUrl}>Insert Image from URL</button>

            <ButtonComponent
              style={{ marginTop: 20, marginBottom: 100 }}
              btnType="dark"
              loading={loading}
              label="Save"
            />
          </Form>
        </Formik>
      ) : (
        <div>
          {/* {news.map((a: any) => (
            <div>
              <h3>{a.title}</h3>
            </div>
          ))} */}
        </div>
      )}
    </div>
  );
};
