import React, { useState } from "react";
import {
  Form,
  Button,
  Dropdown,
  DropdownButton,
  Row,
  Col,
} from "react-bootstrap";
import { FilterIcon } from "../Assets";
import { FilterBtn } from "../pages/styled/students";
import styled from "styled-components";
import { Label } from "./FormInput/styled";
import { ButtonComponent } from "./ButtonComponent";
import { getApplications } from "../Services/Function";

interface FilterProps {
  phases: string[];
  statuses: string[];
  onFilterChange: any;
  selectedAppStatus: any;
  intakeYear: any;
  intakeMonth: any;
  confrimStatus: any;
  setIntakeYear: any;
  setIntakeMonth: any;
  setselectedAppStatus: any;
  setconfrimStatus: any;
  setApplications: any;
  reloadStudents: any;
}

const StyledDropdownMenu = styled.div`
  position: absolute;
  background-color: white;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  padding: 15px;
  border-radius: 4px;
  width: 300px;
`;

export const ApplicationFilter: React.FC<FilterProps> = ({
  phases,
  statuses,
  onFilterChange,
  selectedAppStatus,
  intakeYear,
  intakeMonth,
  confrimStatus,
  setIntakeYear,
  setIntakeMonth,
  setselectedAppStatus,
  setconfrimStatus,
  setApplications,
  reloadStudents,
}) => {
  const [filterApplied, setfilterApplied] = useState(false);
  const [showMenu, setshowMenu] = useState(false);

  const handlePhaseChange = (phase: string) => {
    setselectedAppStatus((prevPhases: any) =>
      prevPhases.includes(phase)
        ? prevPhases.filter((p: any) => p !== phase)
        : [...prevPhases, phase]
    );
  };

  const handleYearChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedYear = Number(e.target.value);
    setIntakeYear(selectedYear > 0 ? selectedYear : undefined);
  };

  const handleMonthChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setIntakeMonth(e.target.value);
  };

  const handleStatusChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setconfrimStatus(e.target.value);
  };

  console.log("first", selectedAppStatus);
  return (
    <div className="">
      <FilterBtn
        onClick={() => {
          setshowMenu(!showMenu);
        }}
        filterApplied={showMenu}
      >
        <FilterIcon
          color={filterApplied ? "#7F56D9" : "#98A2B3"}
          style={{ marginRight: 5 }}
        />
        {filterApplied ? "Remove Filter" : "Filter Applications"}
      </FilterBtn>

      {showMenu ? (
        <StyledDropdownMenu>
          <Row className="mb-3">
            <Col>
              <Label>Select Phases:</Label>
              <DropdownButton
                id="phases-dropdown"
                title={
                  selectedAppStatus.length > 0
                    ? selectedAppStatus.join(", ")
                    : "Select Phases"
                }
                variant="secondary"
                onSelect={(eventKey: string | null) =>
                  eventKey && handlePhaseChange(eventKey)
                }
              >
                {phases.map((phase) => (
                  <Dropdown.Item
                    key={phase}
                    eventKey={phase}
                    active={selectedAppStatus.includes(phase)}
                  >
                    {phase}
                  </Dropdown.Item>
                ))}
              </DropdownButton>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col>
              <Label>Select Intake Year:</Label>
              <Form.Control
                as="select"
                value={intakeYear || ""}
                onChange={(e: any) => handleYearChange(e)}
              >
                <option value="">Select</option>
                {[...Array(10)].map((_, i) => {
                  const year = new Date().getFullYear() + i;
                  return (
                    <option key={year} value={year}>
                      {year}
                    </option>
                  );
                })}
              </Form.Control>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col>
              <Label>Select Intake Month:</Label>
              <Form.Control
                as="select"
                value={intakeMonth || ""}
                onChange={(e: any) => handleMonthChange(e)}
              >
                <option value="">Select</option>
                {[...Array(12)].map((_, i) => {
                  const month = new Date(0, i).toLocaleString("default", {
                    month: "long",
                  });
                  return (
                    <option key={month} value={month}>
                      {month}
                    </option>
                  );
                })}
              </Form.Control>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col>
              <Label>Select Status:</Label>
              <Form.Control
                as="select"
                value={confrimStatus || ""}
                onChange={(e: any) => handleStatusChange(e)}
              >
                <option value="">Select</option>
                {statuses.map((status) => (
                  <option key={status} value={status}>
                    {status}
                  </option>
                ))}
              </Form.Control>
            </Col>
          </Row>

          <ButtonComponent
            onClick={async () => {
              onFilterChange();
              if (
                selectedAppStatus.length > 0 ||
                intakeYear ||
                intakeMonth ||
                confrimStatus
              ) {
                setfilterApplied(true);
              } else {
                setfilterApplied(false);
              }
              const res = await getApplications({
                status:
                  selectedAppStatus.length > 0 ? selectedAppStatus : undefined,
                intakeYear,
                intakeMonth,
                confrimStatus:
                  confrimStatus.length > 0 ? confrimStatus : undefined,
              });
              //   reloadStudents();
              setshowMenu(false);
              setApplications(res);
            }}
            label={"Apply Filters"}
            loading={false}
            btnType={"dark"}
          />
        </StyledDropdownMenu>
      ) : null}
    </div>
  );
};
