/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { createComment, getUsers } from "../../Services/Function";
import { Form, Formik, FormikHelpers } from "formik";
import { ButtonComponent } from "../ButtonComponent";
import { useSelector } from "react-redux";
import moment from "moment";
import { MentionsInput, Mention } from "react-mentions";

type Props = {
  show?: boolean;
  handleClose?: any;
  comments: Array<any>;
  selectedStudent: any;
  getComments: any;
};

export const CommentsModal = (props: Props) => {
  const [users, setusers] = useState([]);
  const [loading, setloading] = useState(false);
  const [commentText, setcommentText] = useState("");
  const [mentions, setMentions] = useState<
    { id: any; display: any; memberId: any }[]
  >([]);

  const auth = useSelector((state: any) => state.auth);

  useEffect(() => {
    async function fetchData() {
      const res = await getUsers();
      setusers(res.results);
    }
    fetchData();
  }, []);

  const usersForMentions = users.map((user: any) => ({
    id: user.id,
    display: user.name,
    memberId: user.slackMemberId,
  }));

  const HighlightedString = (text: any) => {
    const highlightedText = text.text.replace(
      /@(\w+)/g,
      '<span style="color: rgb(127, 86, 217);font-size:14px;background-color: rgb(74 0 237 / 23%);padding: 2px; border-radius: 4px;">@$1</span>'
    );

    return (
      <div
        style={{ fontSize: 14 }}
        dangerouslySetInnerHTML={{ __html: highlightedText }}
      />
    );
  };

  console.log(props.comments, props.selectedStudent);

  return (
    <Modal
      show={props.show}
      onHide={props.handleClose}
      backdrop="static"
      keyboard={false}
      id="modal-right"
      className="modal fade custom-modal"
    >
      <Modal.Header closeButton>
        <Modal.Title className="modal-title">Comments</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ overflow: "scroll", backgroundColor: "#f7f7f7" }}>
        {/* {props.comments?.map((a: any) => (
          <CommentBox key={a.id}>
            <CommentText>{a.content}</CommentText>
          </CommentBox>
        ))} */}
        <ul className="comment-list">
          {props.comments
            .slice()
            .reverse()
            .map((comment) => (
              <li key={comment.id} className="comment-item">
                <div className="comment-header">
                  <div className="user-info">
                    <span className="user-name">{comment.createdBy}</span>
                    <span className="comment-date">
                      {moment(comment.dateTime).format("DD/MM/YYYY-hh:mm:a")}
                    </span>
                  </div>
                </div>

                <HighlightedString text={comment.content} />
              </li>
            ))}
        </ul>
      </Modal.Body>
      <Modal.Footer>
        <Formik
          initialValues={{ comment: "" }}
          onSubmit={async (
            values: any,
            { setSubmitting }: FormikHelpers<any>
          ) => {
            try {
              setloading(true);

              await createComment({
                studentId: props.selectedStudent.id,
                content: commentText.replace(/@\[([^)]+)\]\([^)]+\)/g, "@$1"),
                dateTime: new Date(),
                createdBy: auth.userDetails.name,
                userId: auth.userDetails.id,
                mentions: mentions,
              });
              console.log(props.selectedStudent.id);
              props.getComments(props.selectedStudent.id);
              setcommentText("");
              setMentions([]);
              setloading(false);
            } catch (error) {
              setloading(false);
            }
          }}
        >
          <Form style={{ width: "70%" }} className="d-flex w-100 flex-column">
            <MentionsInput
              id={"commentText"}
              className="textarea-input w-100"
              name={"commentText"}
              forceSuggestionsAboveCursor={true}
              value={commentText}
              onChange={(
                e: any,
                _newValue,
                _newPlainTextValue,
                mentions: any
              ) => {
                setcommentText(e.target.value);
                // setMentions(mentions);
              }} // Handle the onChange event as needed
            >
              <Mention
                trigger="@"
                data={usersForMentions}
                appendSpaceOnAdd={true}
                renderSuggestion={(
                  suggestion: any,
                  search,
                  highlightedDisplay
                ) => <div className="user-mention">{highlightedDisplay}</div>}
                onAdd={(id: string | number, display: string) => {
                  // Find the user with the matching id in usersForMentions
                  const matchedUser = usersForMentions.find(
                    (user) => user.id === id
                  );

                  // Check if a user is found
                  if (matchedUser) {
                    // Add the new mention to the array
                    setMentions((prevMentions) => [
                      ...prevMentions.filter(
                        (mention) => mention.display !== matchedUser.display
                      ), // Remove previous mentions with the same display
                      {
                        display: matchedUser.display,
                        id: matchedUser.id,
                        memberId: matchedUser.memberId,
                      },
                    ]);
                  }
                }}
                displayTransform={(_id: any, display: any) => `@${display}`}
              />
            </MentionsInput>

            <ButtonComponent
              btnType="dark"
              loading={loading}
              disabled={loading || !commentText}
              label="Send"
            />
          </Form>
        </Formik>
      </Modal.Footer>
    </Modal>
  );
};
