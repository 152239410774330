import * as React from "react";

interface IFirstBadgeProps {
  color: string;
  style: object;
}

export const FirstBadge: React.FunctionComponent<IFirstBadgeProps> = (
  props
) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      fill="none"
      viewBox="0 0 30 30"
      style={props.style}
    >
      <path
        fill="#176CC7"
        d="M16.195.994c-.254.225-2.222 4.132-2.222 4.132l-1.465 5.909 5.7-.523s4.741-8.817 4.837-9.021c.171-.37.263-.523-.391-.523C22 .966 16.43.783 16.194.994z"
      ></path>
      <path
        fill="#FCC417"
        d="M19.142 10.146c-.284-.153-8.637-.284-8.833 0-.179.257-.153 1.436-.066 1.589.087.152 2.894 1.457 2.894 1.457l-.002.476s.155.373 1.72.373c1.566 0 1.728-.316 1.728-.316l.014-.481s2.458-1.228 2.587-1.336c.131-.11.242-1.61-.042-1.762zm-2.573 1.952s-.007-.328-.17-.41c-.16-.082-2.765-.068-2.985-.056-.22.011-.22.405-.22.405l-1.782-.867v-.174l6.633.047.012.197-1.488.858z"
      ></path>
      <path
        fill="#FDFFFF"
        d="M13.89 11.992c-.22 0-.347.23-.347.626 0 .37.127.682.405.659.23-.02.31-.37.289-.682-.021-.37-.068-.603-.347-.603z"
      ></path>
      <path
        fill="#FCC417"
        d="M6.793 21.262c0 5.616 5.077 8.117 8.451 7.997 3.722-.133 8.18-3.035 7.91-8.393-.255-5.076-4.525-7.483-8.223-7.458-4.299.03-8.138 2.949-8.138 7.854z"
      ></path>
      <path
        fill="#FA912C"
        d="M15.124 28.282c-.058 0-.12 0-.178-.003a7.283 7.283 0 01-4.765-2.03 6.87 6.87 0 01-2.095-5.013c.021-4.675 4.109-6.843 6.881-6.843h.024c3.757.016 6.841 2.936 6.928 6.895.037 1.762-.684 3.612-2.1 5.004-1.322 1.296-3.075 1.99-4.695 1.99zm-.161-13.111c-2.487 0-6.164 2.034-6.174 6.065-.007 3.014 2.328 6.113 6.216 6.244 1.481.047 3.007-.52 4.24-1.732 1.28-1.259 1.999-2.88 1.973-4.45-.056-3.406-2.84-6.116-6.22-6.13l-.035.003z"
      ></path>
      <path
        fill="#FEFFFA"
        d="M13.553 14.205c-.162-.223-1.995-.18-3.727 1.512-1.671 1.633-1.852 3.173-1.53 3.262.363.101.806-1.53 2.336-2.901 1.406-1.256 3.244-1.43 2.92-1.873zm7.09 6.063c-.566.08.02 1.772-1.29 3.666-1.136 1.643-2.426 2.238-2.276 2.6.202.482 2.267-.72 3.223-2.68.867-1.774.764-3.647.342-3.586z"
      ></path>
      <path
        fill="#FA912C"
        d="M13.09 18.052c-.122.18-.012 1.762.06 1.832.141.14 1.21-.363 1.21-.363l-.04 4.23s-.785-.009-.867.022c-.162.06-.14 1.692-.021 1.771.12.08 3.323.122 3.445-.04.122-.161.092-1.588.035-1.654-.1-.122-.867-.073-.867-.073s.066-6.23.045-6.429c-.021-.199-.242-.323-.504-.262-.263.06-2.419.848-2.496.966z"
      ></path>
      <path
        fill="#2E9DF4"
        d="M5.98.872c-.148.136 5.498 10.191 5.498 10.191s.947.122 3.06.14c2.115.02 3.163-.121 3.163-.121S13.31.973 13.048.872C12.92.821 11.18.802 9.481.783 7.779.763 6.12.741 5.979.873z"
      ></path>
    </svg>
  );
};
