import firebase from "firebase/compat/app";
import "firebase/compat/storage";
import imageCompression from "browser-image-compression";
import { PDFDocument } from "pdf-lib";

var firebaseConfig = {
  apiKey: "AIzaSyDQkk9p4h9pfXSvuPmU95Od3MCvEgigBUg",
  authDomain: "uapply-fd1d1.firebaseapp.com",
  projectId: "uapply-fd1d1",
  storageBucket: "uapply-fd1d1.appspot.com",
  messagingSenderId: "1035612220208",
  appId: "1:1035612220208:web:5e82ce1606aebdf3def6f5",
  measurementId: "G-8JXMH47EW3",
};

firebase.initializeApp(firebaseConfig);

const MAX_FILE_SIZE_MB = 8;

const validateFile = (file: any) => {
  if (file.size > MAX_FILE_SIZE_MB * 1024 * 1024) {
    throw new Error("File size is too large. Max size is 8 MB.");
  }

  // Additional file type validation if needed
  const allowedFileTypes = [
    "image/jpeg",
    "image/jpg",
    "image/png",
    "application/pdf",
  ];
  if (!allowedFileTypes.includes(file.type)) {
    throw new Error("Invalid file type. Allowed types: JPEG,JPG, PNG, PDF");
  }
};

const compressImage = async (file: any) => {
  try {
    const compressedFile = await imageCompression(file, {
      maxSizeMB: 1, // Adjust this value to control the maximum file size
      maxWidthOrHeight: 800, // Maximum width or height
      useWebWorker: true, // Use web workers for faster compression
      maxIteration: 10, // Adjust the number of iterations to control the compression quality
      initialQuality: 0.85, // Initial quality of the image (0 to 1, where 1 is the highest quality)
    });
    return compressedFile;
  } catch (error: any) {
    console.error("Image compression error:", error.message);
    throw new Error("Failed to compress image.");
  }
};

const optimizePDF = async (file: any) => {
  try {
    const pdfBytes = await file.arrayBuffer();
    const pdfDoc = await PDFDocument.load(pdfBytes, { ignoreEncryption: true });
    const pages = pdfDoc.getPages();

    // Compress each page individually
    for (let i = 0; i < pages.length; i++) {
      const page = pages[i];
      page.scale(0.8, 0.8); // Uniformly scale down each page
    }

    const optimizedPdfBytes = await pdfDoc.save();
    const optimizedPdfFile = new Blob([optimizedPdfBytes], {
      type: "application/pdf",
    });

    return optimizedPdfFile;
  } catch (error: any) {
    console.error("PDF optimization error:", error.message);
    throw new Error("Failed to optimize PDF.");
  }
};

const uploadImage = async (files: any) => {
  const storageRef = firebase.storage().ref();
  const urls = [];

  try {
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      validateFile(file);
      let processedFile = file;
      if (file.type === "application/pdf") {
        processedFile = await optimizePDF(file);
      } else if (file.type.startsWith("image/")) {
        // processedFile = await compressImage(file);
      }

      const timestamp = Date.now();
      const filename = `${timestamp}_${file.name}`;

      const snapshot = await storageRef
        .child(`files/${filename}`)
        .put(processedFile);

      const url = await snapshot.ref.getDownloadURL();
      urls.push({ url, name: filename });
    }

    return urls;
  } catch (error: any) {
    console.error("Upload error:", error.message);
    throw error;
  }
};

const uploadImageProgress = async (
  files: string | any[],
  progressCallback: (arg0: number) => void
) => {
  const storageRef = firebase.storage().ref();
  const urls = [];

  try {
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      validateFile(file);

      let processedFile = file;
      if (file.type === "application/pdf") {
        processedFile = await optimizePDF(file);
      } else if (file.type.startsWith("image/")) {
        // processedFile = await compressImage(file);
      }

      const timestamp = Date.now();
      const filename = `${timestamp}_${file.name}`;

      const snapshot = await storageRef
        .child(`files/${filename}`)
        .put(processedFile);

      const url = await snapshot.ref.getDownloadURL();
      urls.push({ url, name: filename });

      // Calculate progress and update the callback
      const progress = Math.round(
        (snapshot.bytesTransferred / snapshot.totalBytes) * 100
      );
      progressCallback(progress);
    }

    return urls;
  } catch (error: any) {
    console.error("Upload error:", error.message);
    throw error;
  }
};
export const storage = firebase.storage();

export { uploadImage, uploadImageProgress };
