// #region Global Imports
import styled from "styled-components";
// #endregion Global Imports

export const Card = styled.div`
  border-radius: 8px;
  border: 1px solid #ebecf0;
  background: #fff;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25);
  padding: 24px;
  position: relative;
`;

export const GraphHeader = styled.div`
  color: var(--Light-Grayscale-100, #1c1c1c);
  font-feature-settings: "clig" off, "liga" off;
  font-family: Roboto;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

  @media (max-width: 992px) {
    font-size: 18px;
  }
`;

export const FilterCon = styled.div`
  position: absolute;
  right: 0px;
  top: 24px;
  @media (max-width: 992px) {
  }
`;

export const AnimationCon = styled.div`
  position: absolute;
  right: 0;
  top: -70px;
  @media (max-width: 992px) {
    top: -88px;
  }
`;

export const GraphCard = styled.div`
  border-radius: 8px;
  border: 9px solid var(--Neutral-30, #ebecf0);
  padding: 25px;
  background: #fff;
`;

export const QuoteCon = styled.div`
  border-radius: 8px;
  border: 1px solid var(--neutral-30, #ebecf0);
  background: #fff;
  padding: 24px;
  margin-bottom: 30px;
`;

export const QuoteText = styled.div`
  color: #000;
  font-family: Roboto;
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  @media (max-width: 992px) {
    line-height: 40px;
  }
`;

export const CardHeader = styled.div`
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 26px;
  color: #1c1c1c;
`;
export const InnerCardHeader = styled.div`
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 150%;
  letter-spacing: -0.02em;
  color: #1e2432;
  flex: none;
  order: 0;
  flex-grow: 0;
`;
export const InnerCardText = styled.div`
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  letter-spacing: -0.02em;
  color: rgba(30, 36, 50, 0.8);
  flex: none;
  order: 2;
  flex-grow: 0;
`;
export const InnerCard = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 12px 24px;

  width: 100%;
  height: 56px;
  background: rgba(190, 161, 255, 0.1);
  border-radius: 12px;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
`;

export const CardText = styled.div`
  color: #42526e;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  text-transform: capitalize;
`;
export const CardPercentageText = styled.div`
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 26px;
  text-align: right;
  color: #344563;
`;
export const CardNumber = styled.div`
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 34px;
  line-height: 40px;
  text-align: right;
  color: #344563;
`;
