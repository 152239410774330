import axios from "axios";
import { FormikValues, FormikHelpers, Formik, Form } from "formik";
import QueryString from "qs";
import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { ButtonComponent } from "../ButtonComponent";
import { TextArea } from "../TextArea";

type Props = {
  handleClose?: any;
  waKey?: String;
  show?: boolean;
  selectedStudentArr?: Array<any>;
  setselectedStudentArr: Function;
};

export const BulkMessageModal = (props: Props) => {
  const { selectedStudentArr, setselectedStudentArr, waKey } = props;
  const [loading, setloading] = useState(false);

  return (
    <div>
      <Modal
        show={props.show}
        onHide={props.handleClose}
        backdrop="static"
        keyboard={false}
        id="modal-right"
        className="modal fade custom-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title className="modal-title">Send Bulk Message</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formik
            initialValues={{ message: "" }}
            onSubmit={async function (
              values: FormikValues,
              formikHelpers: FormikHelpers<FormikValues>
            ) {
              try {
                setloading(true);
                const phoneNos: Array<{ phoneNo: String; fullname: String }> =
                  selectedStudentArr?.map((a) => {
                    return {
                      fullname: a.fullname,
                      phoneNo: a.phoneNo.replace(/[^A-Z0-9]/gi, ""),
                    };
                  }) || [];
                let res;
                for (let index = 0; index < phoneNos.length; index++) {
                  const element = phoneNos[index];
                  var data = QueryString.stringify({
                    id: element.phoneNo,
                    message: `Hey ${element.fullname}\n${values.message}`,
                  });
                  var config: any = {
                    method: "post",
                    url: `https://wa-api-node.onrender.com/message/text?key=${waKey}`,
                    headers: {},
                    data: data,
                  };
                  res = await axios(config);
                  setselectedStudentArr([]);
                }
                if (res?.statusText === "Created") {
                  alert(
                    `Message sent successfully to ${selectedStudentArr?.length} students.`
                  );
                } else {
                  alert("Failed!!");
                }
                props.handleClose();
                values.phoneNos = [];
                values.message = "";
                setloading(false);
              } catch (error) {
                console.log(error);
                setloading(false);
              }
            }}
          >
            {({ values }) => (
              <Form className="d-flex flex-column" style={{ marginTop: 20 }}>
                <TextArea
                  id={""}
                  name={"message"}
                  placeholder={"Message"}
                  type={"text"}
                />
                <ButtonComponent
                  label={"Send Message"}
                  loading={loading}
                  btnType={"dark"}
                />
              </Form>
            )}
          </Formik>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    </div>
  );
};
