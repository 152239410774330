/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
import { useSelector } from "react-redux";
import { getQuotes, getUsers } from "../Services/Function";
import {
  AddUserModal,
  ButtonComponent,
  PaginationComponent,
  QuotesTable,
  UsersView,
} from "../Components";
import { Navigate } from "react-router-dom";
import { Container } from "./styled/students";
import { Col, Row, Tab, Tabs } from "react-bootstrap";

interface IUsersProps {}

export const Users: React.FunctionComponent<IUsersProps> = (props) => {
  const auth = useSelector((state: any) => state.auth);
  var authToken = localStorage.getItem("authToken");

  const [users, setusers] = React.useState([]);
  const [modalShow, setmodalShow] = React.useState("");
  const [quotes, setquotes] = React.useState([]);
  const [addQuote, setaddQuote] = React.useState(false);
  const [page, setpage] = React.useState<any>(1);
  const [totalPage, settotalPage] = React.useState(1);

  React.useEffect(() => {
    async function fetchData() {
      const res = await getUsers();
      setusers(res.results);
      const resp = await getQuotes({
        limit: 25,
        page: 1,
        sortBy: "createdAt:desc",
      });
      setquotes(resp.results);
      settotalPage(resp.totalPages);
    }

    fetchData();
  }, []);

  const reloadUsers = async () => {
    const res = await getUsers();
    setusers(res.results);
  };

  if (!auth.isLoggedIn && !authToken) {
    return <Navigate to="/" />;
  }
  return (
    <Container>
      <div className="d-flex justify-content-center">
        <Col md={6}>
          <Tabs
            id="controlled-tab-example"
            // activeKey={feeType}
            // onSelect={async (k: any) => {
            //   await reloadData(k);
            //   setfeeType(k);
            // }} // Update active tab on select
          >
            <Tab eventKey="quotes" title="Quotes">
              <QuotesTable
                reload={async () => {
                  const resp = await getQuotes({
                    limit: 25,
                    page: page,
                    sortBy: "createdAt:desc",
                  });
                  setquotes(resp.results);
                }}
                quotes={quotes}
              />
              {quotes?.length ? (
                <PaginationComponent
                  page={parseInt(page)}
                  totalPage={totalPage}
                  onPageClick={async (x: any) => {
                    setpage(parseInt(x));

                    // setsearchText("");
                    const resp = await getQuotes({
                      limit: 25,
                      page: x,
                      sortBy: "createdAt:desc",
                    });
                    setquotes(resp.results);

                    settotalPage(resp.totalPages);
                  }}
                  onChange={async () => {
                    setpage(parseInt(page) + 1);
                    if (quotes?.length === 25) {
                      const resp = await getQuotes({
                        limit: 25,
                        page: page + 1,
                        sortBy: "createdAt:desc",
                      });
                      setquotes(resp.results);

                      settotalPage(resp.totalPages);
                      settotalPage(resp.totalPages);
                    }
                  }}
                  onChangePrev={async () => {
                    setpage(parseInt(page) > 1 ? parseInt(page) - 1 : 1);
                    const resp = await getQuotes({
                      limit: 25,
                      page: page - 1,
                      sortBy: "createdAt:desc",
                    });
                    setquotes(resp.results);

                    settotalPage(resp.totalPages);
                    settotalPage(resp.totalPages);
                  }}
                />
              ) : null}
            </Tab>
            <Tab eventKey="users" title="Users">
              <ButtonComponent
                onClick={() => setmodalShow("add")}
                label={"Add User"}
                loading={false}
                btnType={"dark"}
                style={{ marginBottom: 20 }}
              />
              <UsersView
                reloadUsers={() => reloadUsers()}
                users={users}
                page={1}
              />
            </Tab>
          </Tabs>
        </Col>
      </div>
      {modalShow === "add" ? (
        <AddUserModal
          reloadUsers={() => reloadUsers()}
          show={modalShow === "add"}
          handleClose={() => setmodalShow("")}
        />
      ) : null}
    </Container>
  );
};
