import React from "react";
import { Dropdown } from "react-bootstrap";

type Props = {
  title?: string;
  data: Array<any>;
  onClick: Function;
  selected: string;
  element?: any;
  ifSelected?: boolean;
  subjectCount?: any;
  dob?: boolean;
  month?: any;
  style?: object;
  ddClass?: string;
};

export const UniversityDDComponent = (props: Props) => {
  return (
    <div>
      <Dropdown className={`${props.ddClass} custom-dd`}>
        <Dropdown.Toggle
          style={{
            background: props.ifSelected ? "#323A46" : "#fff",
            color: props.ifSelected ? "#fff" : "#191D23",
            ...props.style,
          }}
          variant="primary"
          id="dropdown-basic"
        >
          {props.title}{" "}
          {props.subjectCount > 0 ? (
            <div
              className="d-flex justify-content-center align-items-center"
              style={{
                width: 20,
                height: 20,
                borderRadius: 10,
                background: "#fff",
                color: "#191D23",
                fontSize: 10,
                marginLeft: 8,
                fontWeight: 700,
              }}
            >
              {props.subjectCount}
            </div>
          ) : null}
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <div style={{ padding: "0 15px" }}>{props.element}</div>
          {props.data.map((a: any, key) => (
            <Dropdown.Item
              style={{
                background: props.selected === a.name ? "#323A46" : "#fff",
                color: props.selected === a.name ? "#fff" : "#191D23",
              }}
              key={key}
              onClick={() => {
                props.onClick(props.dob ? a : a.name, key + 1);
              }}
            >
              {props.dob ? a : a.name}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};
